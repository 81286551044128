import React, { useContext, useMemo, useState } from 'react';

import _ from 'lodash';
import { useTable, useFilters, useSortBy } from 'react-table';

import {DateTime} from 'luxon';
import {matchSorter} from 'match-sorter';
import { DefaultColumnFilter, SelectColumnFilter, DateColumnFilter, dateMinFilter, dateMaxFilter } from '../../utils/FilteringTable';


import AuthContext from '../../store/auth-context';
import { deleteIcon, downloadIcon, editIcon, openIcon } from '../../assets/icons/Icons';

import './ProjectView.css';
import DataContext from '../../store/data-context';
import { buttonTexts } from '../../utils/translate';
import ExportToExcel from './ExportToExcel';


const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

const ScenariosTable = (props) => {

    const {allScenarios, handleEditModalOpen, handleOpen, handleDownload, handleDeleteModalOpen} = props;

    const authCtx = useContext(AuthContext);
    const dataCtx = useContext(DataContext);

    const customVisibilityCell = ({value}) => {
        return <span style={value === 'public' ? {backgroundColor: '#B6D7A8', padding: '8px', borderRadius: '4px'} : {backgroundColor: '#f4f4f4', padding: '8px', borderRadius: '4px'} }>{value}</span>
    }

    const columns = useMemo(
        () => [
          { Header: 'Scenario name', accessor: 'scenario_name', overflowWrap: 'anywhere', position: 'relative', bottom: '50px'},
          { Header: 'Author', accessor: 'owner_name', Filter: SelectColumnFilter, maxWidth: '80px'},
          { Header: 'Last editted', accessor: d => {return DateTime.fromSeconds(d.updated_at.seconds).setLocale('en-GB').toFormat('dd.MM.yyyy')}, disableSortBy: true, disableFilters: true},
          { Header: 'Status', accessor: 'visibility', Filter: SelectColumnFilter, Cell: customVisibilityCell, maxWidth: '80px'},
        ],
        []
    )

    let scenariosDisplay;
    let sortedScenarios;
    if (allScenarios) {
        const allButRootScenarios = allScenarios.filter(scenario => scenario.id !== 'root')
        sortedScenarios = _.sortBy(allButRootScenarios, scenario => scenario.scenario_name)
        scenariosDisplay = sortedScenarios.map(scenario => {
            const timestampSec =  scenario.updated_at.seconds;
            const date = new Date(timestampSec*1000);
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() < 9 ? `0${date.getMonth()+1}` : date.getMonth()+1;

            return (
                <tr key={scenario.id} style={{backgroundColor: 'white'}}>
                    {/* <input type='checkbox' style={{position: 'relative'}}/> */}
                    <td style={{padding: '10px 20px'}}>{scenario.scenario_name}</td>
                    <td style={{padding: '10px 20px'}}>{scenario.owner_name}</td>
                    <td style={{padding: '10px 20px'}}>{`${day}.${month}.${date.getFullYear()}`}</td>
                    <td style={{padding: '10px 20px'}}>{scenario.visibility}</td>
                    <td style={{padding: '10px 20px', minWidth: '200px', display: 'flex'}}>
                        <button className='list-btn square-button' onClick={(e) => handleEditModalOpen(e, scenario.id, scenario.scenario_name, scenario.visibility)} style={scenario.id === 'root' ? {visibility: 'hidden'} : {visibility: 'inherit'}} disabled={authCtx.currentUserProfile.is_admin ? false : !authCtx.is_admin && (authCtx.currentUserId !== scenario.owner_id || scenario.id === 'root') ? true : false} title="Edytuj">{editIcon}</button>
                        <button className='list-btn rectangle-button accent-color-button' onClick={(e) => handleOpen(e, scenario)} style={{color: '#fff' }} title='Otwórz'>{openIcon}<div>Otwórz</div> </button>
                        <button className='list-btn square-button adj-display' title='Download' disabled={true}  onClick={(e) => handleDownload(e, scenario)}>{downloadIcon}</button>
                        <button className='list-btn square-button' onClick={(e) => handleDeleteModalOpen(e, scenario.id, scenario.scenario_name)} style={scenario.id === 'root' ? {visibility: 'hidden'} : {visibility: 'inherit'}} disabled={authCtx.currentUserProfile.is_admin ? false : !authCtx.is_admin && (authCtx.currentUserId !== scenario.owner_id || scenario.id === 'root') ? true : false} title='Delete'>{deleteIcon}</button>
                        {/* <ExportToExcel data={scenario} modelMetadata={modelMetadata} fileName={scenario.scenario_name}/> */}
                    </td>
                </tr>
            
            );
        } )
    }
    let rootScenario;
    if (allScenarios) {
        rootScenario = allScenarios.find(scenario => scenario.id === 'root')
    }

    const filterTypes = React.useMemo(
        () => ({
          // Add a new fuzzyTextFilterFn filter type.
          fuzzyText: fuzzyTextFilterFn,
          // Or, override the default text filter to use
          // "startWith"
        //   dateBetween: dateBetweenFilterFn,
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
      )

      const defaultColumn = useMemo(
        () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
        }),
        []
      )

      const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        allColumns,
        // getToggleHideAllColumnsProps,
        state,
        // setHiddenColumns

      } = useTable({
        columns,
        data: props.sortedScenarios,
        filterTypes,
        defaultColumn,
        autoResetHiddenColumns: false,
        autoResetFilters: false,
        // initialState: {
        //   hiddenColumns: !localStorage.getItem('hiddenColumns') ? [] :  localStorage.getItem('hiddenColumns').split(',')
        // }
    
      }, useFilters, useSortBy);

    return (
        <>
        <div className='table-wrapper'>
            <table {...getTableProps()} className='custom-table' style={{borderSpacing: '0 10px', margin: '0 auto', borderCollapse: 'separate', backgroundColor: '#f5f5f5'}}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                
                                <th {...column.getHeaderProps()} >{column.render("Header")}
                                
                                <div style={{maxWidth: '100%', height: '30px', width: '100%', margin: '5px'}}>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>

                            ))}
                            <th role='columnheader' style={{minWidth: '200px'}} />
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} style={{backgroundColor: '#fff'}}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()} style={{padding: '10px 20px'}}>{cell.render('Cell')}</td>
                                    )
                                })}
                                <td style={{padding: '10px 20px', minWidth: '200px', display: 'flex'}}>
                                    <button className='list-btn square-button light icon-button' onClick={(e) => handleEditModalOpen(e, row.original.id, row.original.scenario_name, row.original.visibility, row.original.owner_id)} style={row.original.id === 'root' ? {visibility: 'hidden'} : {visibility: 'inherit'}} disabled={authCtx.currentUserProfile.is_admin ? false : !authCtx.is_admin && (authCtx.currentUserId !== row.original.owner_id || row.original.id === 'root') ? true : false} title={buttonTexts.edit[dataCtx.lang]}>{editIcon}</button>
                                    <button className='list-btn rectangle-button accent-color-button' onClick={(e) => handleOpen(e, row.original)} style={{color: '#fff' }} title={buttonTexts.open[dataCtx.lang]}>{openIcon}<div>{buttonTexts.open[dataCtx.lang]}</div> </button>
                                    <ExportToExcel data={row.original} modelMetadata={props.modelMetadata} />
                                    {/* <button className='list-btn square-button adj-display' title='Download' disabled={true}  onClick={(e) => handleDownload(e, row.original)}>{downloadIcon}</button> */}
                                    <button className='list-btn square-button light icon-button' onClick={(e) => handleDeleteModalOpen(e, row.original.id, row.original.scenario_name)} style={row.original.id === 'root' ? {visibility: 'hidden'} : {visibility: 'inherit'}} disabled={authCtx.currentUserProfile.is_admin ? false : !authCtx.is_admin && (authCtx.currentUserId !== row.original.owner_id || row.original.id === 'root') ? true : false} title={buttonTexts.delete[dataCtx.lang]}>{deleteIcon}</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
        </>
    )
}


export default ScenariosTable;

