import React from "react";

import classes from "./Button.module.css";

export const ActionButton = ({ styling, type, clicked, icon, text, notActive, tooltipText }) => {

    return (
        <button 
            className={`${classes['action-button']} `} 
            type={type} 
            onClick={clicked}
            disabled={notActive}
            title={text}
            style={{textTransform: 'uppercase'}}
        >
            <span>{icon}</span>
            <div>{text}</div>
            
        </button>
  );
};
