import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { logoutIcon, settingsIcon } from '../../assets/icons/Icons';
import AuthContext from '../../store/auth-context';

// import classes from './Header.module.css'
import './HeaderUsersProfile.css';



const HeaderUserProfile = (props) => {

    const authCtx = useContext(AuthContext);
    const history = useHistory();
    let initials;
    if (authCtx && authCtx.currentUserProfile.first_name && authCtx.currentUserProfile.last_name ) {
        initials = `${authCtx.currentUserProfile.first_name[0]}${authCtx.currentUserProfile.last_name[0]}`
    }

    const [menuOpen, setMenuOpen] = useState();

    const handleActionsMenu = () => {
        if (menuOpen === undefined) {
            setMenuOpen(true)
        } else if (menuOpen === true) {
            setMenuOpen(false)
        } else if (menuOpen === false) {
            setMenuOpen(true)
        }
    }

    return (
        <>
            <div onMouseLeave={() => setMenuOpen(false)} className={menuOpen === undefined ? 'actions-menu' : menuOpen === false ? 'actions-menu actions-menu-animation-closing' : 'actions-menu actions-menu-animation-opening'}>
                {/* <div className='actions-menu-item' onClick={() => history.push('/reset-password', {email: authCtx.currentUserProfile.email})}><div className='logout-icon-container'>{settingsIcon}</div>Reset password</div> */}
                <div className='actions-menu-item' onClick={() => props.handleRedirection('/reset-password', {email: authCtx.currentUserProfile.email})}><div className='logout-icon-container'>{settingsIcon}</div>Reset password</div>
                <div className='actions-menu-item' onClick={props.onLogout}><div className='logout-icon-container'>{logoutIcon}</div>Logout</div>
            </div>
            <div className='profile-container' onClick={handleActionsMenu}>
                {authCtx.currentUserProfile.name}
                <div className='initials-circle'>
                    <div className='initials' >{initials}</div>
                </div>
                <div className='arrow' style={menuOpen ? {transform: 'rotate(-45deg)', position: 'relative', bottom: '0px'} : {transform: 'rotate(135deg)',position: 'relative', bottom: '6px'}} ></div>
            </div>
        </>
    )
}

export default HeaderUserProfile;