import React from "react";

import classes from "./Button.module.css";

export const Button = ({ styling, type, clicked, text, notActive, tooltipText }) => {

    return (
        <button 
            className={`${classes['custom']} ${classes[styling]}`} 
            type={type} 
            onClick={clicked}
            disabled={notActive}
            title={text}
            style={{textTransform: 'uppercase'}}
        >
            {text}
        </button>
  );
};
