import { useEffect, useState } from 'react';
import { doc, getDoc } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';

export const useGetUsersProfile = () => {
    const [usersProfile, setUsersProfile] = useState({});

    const user = authentication.currentUser;
    let user_id;
    if (user !== null) {
        user_id = user.uid;
    }

    useEffect (() => { 

        const fetchData = async () => {
            if (user_id) {
                const docRef = doc(db, "users", user_id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUsersProfile(docSnap.data())
                } else {
                    console.log('no such doc');
                }
            }
            
    }
        
          
    fetchData()

         
    }, [user_id])

return usersProfile;
}; 

