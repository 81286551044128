import React from 'react';
import { Button } from '../../UI/Button';

const CreateModelAddFile = (props) => {

    const {progress, handleFileUpload, setStateFn, setProgressFn} = props;
     
    return (
        <>
            <form onSubmit={(e) => handleFileUpload(e, setStateFn, setProgressFn)}>
                <input type='file' />
                <Button type="submit" text='Upload file' styling='light'>Upload file</Button>
            </form>
            <h3>Uploaded {progress}%</h3>
        </>
    );
}

export default CreateModelAddFile;