import { Button } from '../UI/Button';
import React, { useState, useRef, forwardRef, createRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { doc, deleteDoc, updateDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import Card from '../UI/Card';



const ManageModel = (props) => {
    const history = useHistory();
    const location = useLocation();
    const projectId = props.match.params.projectId;
    const modelId = props.match.params.modelId;
    
    let currentModels;
    let projectName;
    
    if (location && location.state) {
        currentModels = location.state.currentModels;
        projectName = location.state.projectName;
    }
    
    let currentModel;
    if (currentModels && currentModels.length > 0) {
        currentModel = currentModels.find(model => model.id === modelId)
    }
    
    const [published, setPublished] = useState(currentModel.is_published)

    const modelNameRef = createRef();
    const modelCurrencyRef = createRef();
    const modelNotesRef = createRef();
    const publishedCheckboxRef = createRef();

    const handleCheckbox = () => {
        if (published === undefined || published === false) {
            setPublished(true)
        } else {
            setPublished(false)
        }
    }

    const handleCollectData = () => {
        const name = modelNameRef.current.value;
        const currency = modelCurrencyRef.current.value;
        const notes = modelNotesRef.current.value;

        handleModelChange(name, currency, notes)
   }

    const handleModelChange = async (name, currency, notes) => {
        const modelQuery = query(collection(db, 'projects', projectId, 'models'), where('model_name', '==', name));
        const querySnapshot = await getDocs(modelQuery)
        const modelRef = doc(db, 'projects', projectId, 'models', modelId);
        const models = [];
        querySnapshot.forEach((doc) => {
            models.push({...doc.data()});
        }
        );

        if (models.length > 0 && models[0].model_id !== modelId) {
                    alert('Model with this name already exists, please introduce different name')
                } else {
                    await updateDoc(modelRef, {model_name: name, model_currency: currency, admin_notes: notes, is_published: published})
                    .then(() => history.push(`/project/${projectId}`, {projectId: projectId, modelName: name, modelId: modelId, projectName: projectName  }))
                }


    }



    // const handleEditScenario = async (e, scenarioId, scenarioName, visibility) => {
    //     // alert(`To edit: ${scenarioId}`)
    //     const modelQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioName));
    //     const querySnapshot = await getDocs(modelQuery)
    //     const scenarioRef = doc(db, 'projects', currentProject, 'models', selectedModel, 'scenarios', scenarioId);
    //     const scenarios = [];
    //     querySnapshot.forEach((doc) => {
    //         scenarios.push({...doc.data()});
    //     }
    //     );
        
    //     if (scenarios.length > 0 && scenarios[0].id !== scenarioId) {
    //         alert('Scenario with this name already exists, please introduce different name')
    //     } else {
    //         await updateDoc(scenarioRef, {visibility: visibility, scenario_name: scenarioName})
    //         .then(() => setEditModalOpen(false))
    //     }
        
       
    // }

    return (
        // <nav style={{paddingTop: '50px'}}>


        //     <div onClick={() => history.push(`${projectId}/create-new-model`, {projectId: projectId})} style={{textDecoration: 'underline', cursor: 'pointer'}}>Create new model</div>

        //     <hr />
        //     <Link to={`/project/${projectId}`}>All scenarios</Link>
        // </nav>
        <div className='app-main'>
            <Card>
                <div style={{textAlign: 'left'}}>
                     <h4>Current project: {projectName}</h4>
                    <h4>Manage Model ID: {modelId}</h4>
                </div>

            </Card>
            <div style={{marginTop: '30px', textAlign: 'left', width: '80%'}}>
                <div>
                    <label style={{minWidth: '40%'}} htmlFor='model-name'>Model name: </label>
                    <input style={{width: '50%'}} placeholder={currentModel.model_name} defaultValue={currentModel.model_name} ref={modelNameRef}></input>
                </div>
                <div>
                    <label htmlFor='model-currency'>Currency for the model: </label>
                    <select type='text' id='model-currency' required ref={modelCurrencyRef} >
                        <option selected={currentModel.model_currency === 'PLN'} value="PLN">PLN</option> 
                        <option selected={currentModel.model_currency === 'EUR'} value="EUR">EUR</option>
                        <option selected={currentModel.model_currency === 'GBP'} value="GBP">GBP</option>
                        <option selected={currentModel.model_currency === 'USD'} value="USD">USD</option>
                    </select>
                </div>

                <div style={{marginTop: '30px'}}>
                    <label style={{minWidth: '40%'}} htmlFor='model-notes'>Notes (visible by admins only): </label>
                    <textarea style={{width: '50%'}} cols='50' rows='15' ref={modelNotesRef} defaultValue={currentModel.admin_notes}></textarea>
                </div>
                <div style={{marginTop: '30px'}}>
                    <input id='publish-checkbox' type='checkbox' disabled={!currentModel.columns_metadata} checked={published} ref={publishedCheckboxRef} onChange={handleCheckbox}/> 
                    <label  style={!currentModel.columns_metadata ? {color: 'gray'} : {color: 'inherit'}} htmlFor='publish-checkbox'>Published</label>
                    {!currentModel.columns_metadata && <div style={{color: 'red'}}>The model does not have any root scenario. Create one in order to activate the 'publish checkbox'.</div>}
                    {published && <div style={{color: 'red'}}>The model will be automatically seen by all the users. TEST IT FIRST!!!</div>}
                </div>
            </div>

            {/* <div>
                <label htmlFor='model-published'>Published: </label>
                <input type='checkbox' defaultChecked={currentModel.is_published} ref={modelSatusRef}/>
            </div> */}
            {/* <div>Visibility: {currentModel.visibility}</div> */}
            <div style={{display: 'flex', justifyContent: 'center', gap: '30px', marginBottom: '30px'}}>
                {<Button type='button' clicked={() => history.push(`/project/${projectId}`, {projectId: projectId, projectName: projectName, modelName: currentModel.model_name, modelId: modelId  })} text='Cancel' styling='light' />}
                {!currentModel.columns_metadata && <Button type='button' clicked={() => history.push(`/manage-project/${projectId}/create-model`, {projectId: projectId, modelName: currentModel.model_name, modelId: modelId  })} text='Create root scenario' styling='light' />}
                <Button type='button' clicked={(e) => handleCollectData(e)} text='Update model' styling='primary' />
            </div>
        </div>
    )
}

export default ManageModel;