import { Switch, Box, Modal, TextField, MenuItem } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { modelIcon, scenarioIcon, visibilityIcon } from '../../assets/icons/Icons';
import DataContext from '../../store/data-context';
import { appColors } from '../../utils/colors';
import { modalTexts, buttonTexts } from '../../utils/translate'; 

import { Button } from '../UI/Button';
import CloseModal from './CloseModal';

import classes from './Modals.module.css';

const modalWidth = 450;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiInputBase-input': {
        padding: '6px 0 6px 14px'
    }
  };


const SaveAsModal = (props) => {

    const {handleClose, open, modelName} = props;
    const dataCtx = useContext(DataContext);

    const scenarioNameRef = useRef();
    const visibilitySelectRef = useRef();

    const styles = {
        '& .MuiSwitch-switchBase.Mui-checked': {color: appColors.mainAccentColor},  
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {backgroundColor: appColors.mainAccentColor}
      } 

    const [scenarioVisibility, setScenarioVisibility] = useState('private');
   
    const handleStatusChange = () => {
        if (scenarioVisibility === 'private') {
            setScenarioVisibility('public')
        } else if (scenarioVisibility === 'public') {
            setScenarioVisibility('private');
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        const scenarioName = scenarioNameRef.current.value;
        // const scenarioVisibility = visibilitySelectRef.current.value;

        // console.log(scenarioName, scenarioVisibility);

        props.handleSaveNewScenario(null, scenarioName, scenarioVisibility)

    }

    return (
 <>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <CloseModal clicked={handleClose} />
    <h2 className={classes.header}>{modalTexts.saveAsScenario.title[dataCtx.lang]}:</h2>
    <p>{modalTexts.saveAsScenario.content[dataCtx.lang]}</p>
    <div>
        <div style={{width: '40%', display: 'inline-block'}}><span className={classes.modalIcon}>{modelIcon}</span>{modalTexts.modelName[dataCtx.lang]}:</div>
        <div style={{display: 'inline-block', marginLeft: '5px'}}>{modelName}</div>
    </div>
    <div className={classes.edittableContainer}>
        <label style={{width: '45%'}} htmlFor='scenario-name'><span className={classes.modalIcon}>{scenarioIcon}</span>{modalTexts.scenarioName[dataCtx.lang]}:</label>
        <input style={{marginLeft: '5px'}} type='text' id='scenario-name' required ref={scenarioNameRef} />
    </div>
    <div>
    <div>
        <label htmlFor='scenario-visibility'><span className={classes.modalIcon}>{visibilityIcon}</span>Public</label>
        {/* <select name="scenario-visibility" id="scenario-visibility" ref={visibilitySelectRef}>
            <option value="private">Private</option>
            <option value="public">Public</option>
        </select> */}
        <Switch sx={styles} defaultChecked={scenarioVisibility === 'public'} onClick={handleStatusChange} />

    </div>

    </div>
    
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>       
        <Button type='button' clicked={handleClose} text={buttonTexts.cancel[dataCtx.lang]} styling='light' />
        <Button type='button' clicked={(e) => handleSave(e)} text={buttonTexts.save[dataCtx.lang]} styling='primary' />
    </div>           
  </Box>
</Modal>
</>
    )
}

export default SaveAsModal;