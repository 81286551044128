import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { appColors } from "../../utils/colors";
import { useGetAllUsers } from "../data/useGetAllUsers";

import { orderBy } from "lodash";

const AllUsers = () => {
    const history = useHistory();
 const allUsers = useGetAllUsers();
 let displayAdmins;
 let displayNotAmins;


 if (allUsers) {
    const allAdmins = allUsers.filter(user => user.is_admin === true);
    const allNotAdmins = allUsers.filter(user => user.is_admin === false);
    const sortedAdmins = orderBy(allAdmins, 'last_name');
    const sortedNotAdmins = orderBy(allNotAdmins, 'last_name');

    displayAdmins = sortedAdmins.map(user => {
    return (
        <li key={`user-${user.id}`} style={{padding: '4px'}}>{user.name}: {user.email}</li>
    )
   });

   displayNotAmins = sortedNotAdmins.map(user => {
    return (
        <li key={`user-${user.id}`} style={{padding: '8px', cursor: 'pointer', margin: '6px 0', backgroundColor: '#fff', width: 'fit-content'}} onClick={() => history.push(`/admin/edit-user/${user.id}`, {userData: user})}>
            <span>{user.name}: {user.email} </span>
            {Object.keys(user.roles).map(project => <span style={{backgroundColor: appColors.mainAccentColor, color:' #fff', border: `1px solid ${appColors.mainAccentColor}`, margin: '4px 10px', padding: '2px', borderRadius: '4px'}}>{project}</span>)}
        </li>
    )
   });
 }
    return (
        <div className="app-main">
            <h2>All Users</h2>
            <div style={{textAlign: 'left'}}>
                <h3>Admins</h3>
                <ol >
                    {displayAdmins}
                </ol>
                <hr/>
                <h3>Users</h3>
                <ol>
                    {displayNotAmins}
                </ol>
            </div>
        </div>
    )
}

export default AllUsers;