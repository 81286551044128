import React, { useContext, useEffect, useRef, useState } from 'react';

import {Field} from 'formik'
import _ from 'lodash';
import { appColors } from '../../utils/colors';
import DataContext from '../../store/data-context';

const DisplayMainComponent = (props) => {
    const {modelMetadata, initialValues, allValuesBeforeSent, values, errors, validate, handleChange, checkValuesDifference, calculated, checkIfScenarioIsSaved} = props;
    // console.log(initialValues);
    // console.log(formValues);
    // console.log(values);

    const dataCtx = useContext(DataContext);

    const [DATA, setDATA] = useState([]);

    useEffect(() => {
        setDATA(values)
    }, [values])

    // console.log(DATA);

    // const focusedInputRef = forwardRef();
    // console.log(focusedInputRef.current);

    const [currentlyEdittedInput, setCurrentlyEdittedInput] = useState(null)

    let modelCurrency;
    if (modelMetadata && modelMetadata.hasOwnProperty('model_currency')) {
        modelCurrency = modelMetadata.model_currency
    } else {
        modelCurrency = 'PLN'
    }



    //   const focusedInput = focusedInputRef.current   
    //    if (document.activeElement === focusedInput.current) {
    //     // do something
    //     console.log('focues');
    //   }
    //   console.log(focusedInput);

    let display;
if (initialValues && initialValues.input_main  && modelMetadata && modelMetadata.columns_metadata && values && values.input_main) {
    display = values.input_main.map((tab, tabIndex) => {
   
//    console.log(modelMetadata);
        const headersDetailsPerTab = modelMetadata.columns_metadata.filter(header => header['nazwa zakładki'] === tab.name)
        const edittedColumnsPerTab =  headersDetailsPerTab.map(header => header['czy zmienna ma być edytowalna?'])
        const numFormatColumns =  headersDetailsPerTab.map(header => header['format liczby']);
        // console.log(numFormatColumns);

   return (
        <div key={`tab-${tab.name}`} style={tab.name === props.activeTab ? {display: 'block'} : {display: 'none'}} className='input-table'>
        {/* <h2>{tab.name}</h2> */}
        <table className='first-table'>
            <thead>
                <tr>
                <th style={{zIndex: '10', left: '0', position: 'sticky', borderColor: '#fff', borderBottom: '1px solid #8A8A8A'}}>Date</th>

                {
                    headersDetailsPerTab.map(header => <th key={header['nazwa wyświetlana']}>{header['nazwa wyświetlana']}</th>)
                }
                </tr>
            </thead>
            <tbody>
                {tab.data.map((week, weekIndex) => {
                    return (
                        <tr className='edit-cell' key={`week_row-${weekIndex+1}`}>
                          
                            {
                                tab.header.map((columnHeader, i) => {
                                    const edit = edittedColumnsPerTab[i-1]
                                    const numFormat = numFormatColumns[i-1]
                                    const iniValue = initialValues.input_main[tabIndex].data[weekIndex][columnHeader];
                                    const value = values.input_main[tabIndex].data[weekIndex][columnHeader];
                                    let styling = {border: `1px solid ${appColors.mainAccentColor}`};
                                    let inputStyling = {color: appColors.mainAccentColor}
                                    if  (week[columnHeader] != iniValue) {
                                        styling = {backgroundColor: appColors.changedCellColor}
                                        inputStyling={color: appColors.mainTypoColor}
                                    } else {
                                        styling = {border: `1px solid ${appColors.mainAccentColor}`};
                                        inputStyling = {color: appColors.mainAccentColor}
                                    }
                                    
                                    const onKeyUpChangesHandler = (e, inputValue, numFormat) => {
                                        // console.log('keuUP: ', e.target.value);
                                        // console.log('keuUP: ', inputValue);
                                        const compareWithInitialData = _.isEqual(initialValues, values);
                                        const compareWithCalculatedData = _.isEqual(allValuesBeforeSent, values);
                                        // console.log(compareWithInitialData);
                                        // console.log(compareWithCalculatedData);

                                        // }
                                        if (!calculated && !compareWithInitialData) {
                                            checkValuesDifference(true);
                                        } else if (calculated && !compareWithCalculatedData) {
                                            checkValuesDifference(true);
                                        } else {
                                            checkValuesDifference(false)
                                        }

                                        // let finalPercValue;
                                        // if (inputValue == e.target.value) {
                                        //    finalPercValue= e.target.value/100 
                                        // } else {
                                        //    finalPercValue = e.target.value
                                        // }
                                        // console.log(finalPercValue);
   
                                        //    if (numFormat === 'percentage') { 
                                        //        values.input_main[tabIndex].data[weekIndex][columnHeader] = finalPercValue;
                                        //    } 

                                    }

                                    const onBlurChangesHandler = (e, inputValue, numFormat) => {
                                        let finalPercValue;
                                        // console.log('finalPercValue: ', typeof finalPercValue, finalPercValue);
                                        // const eTargetValue = e.target.value;
                                        // console.log(typeof inputValue, inputValue);
                                        // console.log(typeof eTargetValue, eTargetValue);
                                        if (typeof inputValue === 'string' && /^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(inputValue)) {
                                            const newV = inputValue.replace(',', '.');  
                                            // console.log('onBlur-1: ', newV, typeof newV);
                                            e.target.value = newV;   
                                            // console.log('onBlur-2: ', typeof  e.target.value, e.target.value);
                                            finalPercValue = newV/100;
                                            // console.log('1st if, final%: ', finalPercValue, typeof finalPercValue);
                                        // } else if (typeof inputValue === 'string') {
                                        //     console.log();
                                        //     finalPercValue = inputValue/100;
                                        
                                        values.input_main[tabIndex].data[weekIndex][columnHeader] = Number(newV);

                                        numFormat === 'procentowy' ? inputValue = newV/100 : inputValue = Number(newV)

                                        } else if (typeof inputValue === 'number') {
                                            // console.log('onBlur-2a else: ', inputValue);

                                            finalPercValue = inputValue;
                                        }


                                        // values.input_main[tabIndex].data[weekIndex][columnHeader].toLocaleString('pl-PL')
                                        // if (typeof value === 'string' && !/^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(value)) {
                                        //     let errorA = true
                                        //     styling = {backgroundColor: 'red'}
                                        // console.log('onBlur-3: ', e.target.value, typeof e.target.value)
                                        // console.log('onBlur-4: ', inputValue, typeof inputValue);
                                        // console.log('onBlur-4a: ', e.target.value/100);

                                     if (Number(inputValue) === Number(e.target.value)) {
                                        // console.log('if 1: ');
                                        const v = (e.target.value).replace(',', '.')
                                        finalPercValue= v/100 
                                     } else {
                                        // console.log( typeof e.target.value);
                                        // console.log( typeof inputValue);
                                        // console.log('if 2: ', finalPercValue);
                                        const v = (e.target.value).replace(',', '.')
                                        finalPercValue = inputValue;
                                     }
                                    //  console.log('onBlur-5: ', finalPercValue, typeof finalPercValue);

                                        if (numFormat === 'procentowy') { 
                                            // console.log('onBlur-6: ', 'debug: percentage');
                                            const v = Number(finalPercValue)
                                            values.input_main[tabIndex].data[weekIndex][columnHeader] = finalPercValue;
                                            // console.log('onBlur-6a:', finalPercValue, typeof finalPercValue);
                                            // console.log('onBlur-6aa:', v, typeof v);
                                            e.target.value =  v.toLocaleString('pl-PL', {maximumFractionDigits: 5, useGrouping: true, style: 'percent'})
                                            // console.log('onBlur-6b: ', e.target.value);
                                        } else if (numFormat === 'waluta') {
                                            e.target.value = inputValue.toLocaleString('pl-PL', {maximumFractionDigits: 2, useGrouping: true, style: 'currency', currency: modelCurrency, currencyDisplay: 'code'})

                                        }
                                        else {
                                            // console.log('onBlur-7: ', 'debug: NOT percentage');
                                            e.target.value = inputValue.toLocaleString('pl-PL', {maximumFractionDigits: 2, useGrouping: true})
                                        }

                                        const compareWithInitialData = _.isEqual(initialValues, values);
                                        const compareWithCalculatedData = _.isEqual(allValuesBeforeSent, values);
                                        // console.log(compareWithInitialData);
                                        // console.log(compareWithCalculatedData);

                                        // }
                                        if (compareWithInitialData) {
                                            checkIfScenarioIsSaved(true);
                                            dataCtx.scenarioSavedHandler(true);
                                        } else {
                                            checkIfScenarioIsSaved(false);
                                            dataCtx.scenarioSavedHandler(false);
                                        }


                                        

                                        
                                    }   


                                   

                                    const onFocusHandler = (e, inputValue, numFormat) => {
                                        const value = e.target.value;
                                        // console.log('1-', value);
                                        const noSpaces = value.replaceAll(' ', '');
                                        
                                        const noPercent = noSpaces.replace('%', '');
                                        const noCommas = noPercent.replace(',', '.');
                                        // console.log('2-', noCommas);
                                        if (numFormat === 'procentowy') {
                                            e.target.value = noCommas
                                        } else {
                                            e.target.value = inputValue.toLocaleString('pl-PL', {useGrouping: false})

                                        }
                                   
                                       
                                        // inputValue = values.input_costs[rowIndex][columnHeader].toLocaleString('pl-PL', {maximumFractionDigits: 2, useGrouping: false})
                
                                    }
                                    const onFocusHandlerNOTUSED = () => {
                                       // console.log(typeof values.input_main[tabIndex].data[weekIndex][columnHeader] === 'string');

                                            //   const focusedInput = focusedInputRef   
    // //    if (document.activeElement === focusedInput.current) {
    // //     // do something
    // //     console.log('focues');
    // //   }
    // console.log(focusedInput);
                                        if (typeof value === 'string') {
                                            // console.log(values.input_main[tabIndex].data[weekIndex][columnHeader]);
                                            // console.log(values.input_main[tabIndex].data[weekIndex][columnHeader].charCodeAt(0));
                                            // console.log(values.input_main[tabIndex].data[weekIndex][columnHeader].charCodeAt(1));
                                            // console.log(values.input_main[tabIndex].data[weekIndex][columnHeader].charCodeAt(2));
                                            // console.log(values.input_main[tabIndex].data[weekIndex][columnHeader].includes(' '));
                                            // console.log(typeof values.input_main[tabIndex].data[weekIndex][columnHeader]);
                                            if (values.input_main[tabIndex].data[weekIndex][columnHeader].includes(' ')) {
                                               //  console.log('includes');
                                                values.input_main[tabIndex].data[weekIndex][columnHeader].replaceAll(" ", "");  
                                                // console.log(values.input_main[tabIndex].data[weekIndex][columnHeader]);
                                            } else {
                                               // console.log('jest OK');
 
                                            }
                                        }
                                        if (typeof value === 'number') {
                                            // console.log(values.input_main[tabIndex].data[weekIndex][columnHeader]);

                                            values.input_main[tabIndex].data[weekIndex][columnHeader] = values.input_main[tabIndex].data[weekIndex][columnHeader].toString().replaceAll(" ", "");  
                                                // console.log(typeof values.input_main[tabIndex].data[weekIndex][columnHeader]);
                                                // console.log(values.input_main[tabIndex].data[weekIndex][columnHeader]);

                                        }

                                    }


                                    let errorExists = false;
                                    let errorMessage = ''
                                    const checkErrorExistence = async () => {
                                        if (errors && 
                                            errors.hasOwnProperty('input_main') && 
                                            errors.input_main.length >= tabIndex + 1 &&
                                            errors.input_main[tabIndex].data.length >= weekIndex + 1 &&
                                            errors.input_main[tabIndex].data[weekIndex].hasOwnProperty(columnHeader)
                                        ) {
                                            errorExists = true;
                                            if (values.input_main[tabIndex].data[weekIndex][columnHeader] === '') {
                                                errorMessage = 'Required' 
                                            } else if (!/^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(values.input_main[tabIndex].data[weekIndex][columnHeader])) {
                                                errorMessage = 'Invalid number'
                                            } 

                                        } else {
                                            errorExists = false;
                                        }
                                    }
                                    checkErrorExistence()

                                    if  (week[columnHeader] != iniValue) {
                                        styling = {backgroundColor: appColors.changedCellColor, color: appColors.mainTypoColor}
                                    } 

                                                              
                                    if (edit === 1) {
                                        return (
                                            <td 
                                                key={`input_main[${tabIndex}].data[${weekIndex}].${columnHeader}`} 
                                                // style={{backgroundColor: 'lightgreen', fontWeight: 'bold'}}
                                                style={errorExists ? {backgroundColor: appColors.errorCell} : styling}
                                            >
                                                {
                                                    <>
                                                <Field type='text' id={`input_main[${tabIndex}].data[${weekIndex}].${columnHeader}`} name={`input_main[${tabIndex}].data[${weekIndex}].${columnHeader}`} 
                                                // value={(!values.input_main[tabIndex].data[weekIndex].columnHeader) ? week[columnHeader] : values.input_main[tabIndex].data[weekIndex].columnHeader } 
                                                // ref={focusedInputRef}
                                                // value={columnHeader.includes('%') 
                                                //     ? values.input_main[tabIndex].data[weekIndex][columnHeader].toLocaleString('pl-PL',  {maximumFractionDigits: 2, useGrouping: true, style: 'percent'})
                                                //     : values.input_main[tabIndex].data[weekIndex][columnHeader].toLocaleString('fr-FR',  {maximumFractionDigits: 2, useGrouping: true})
                                                //     // ? `${values.input_main[tabIndex].data[weekIndex][columnHeader]}%`
                                                //     // : values.input_main[tabIndex].data[weekIndex][columnHeader]
                                                // } 
                                                style={inputStyling}
                                                value={numFormat === 'procentowy'
                                                ? values.input_main[tabIndex].data[weekIndex][columnHeader].toLocaleString('pl-PL',  {maximumFractionDigits: 2, useGrouping: true, style: 'percent'})
                                                : numFormat === 'waluta' 
                                                ? values.input_main[tabIndex].data[weekIndex][columnHeader].toLocaleString('pl-PL',  {maximumFractionDigits: 2, useGrouping: true, style: 'currency', currency: modelCurrency, currencyDisplay: 'code'})
                                                : values.input_main[tabIndex].data[weekIndex][columnHeader].toLocaleString('pl-PL',  {maximumFractionDigits: 2, useGrouping: true})
                                                // ? `${values.input_main[tabIndex].data[weekIndex][columnHeader]}%`
                                                // : values.input_main[tabIndex].data[weekIndex][columnHeader]
                                            } 
                                                // onChange={(event) => handleChange(event, columnHeader, `input_main[${tabIndex}].data[${weekIndex}].${columnHeader}` )}/>}

                                                // onChange={props.handleChange}
                                                    // onChange={(e) => {                                                    

                                                    //     props.handleChange(e);
                                                    //     onFocusHandler(e)
                                                    // }}

                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        // onFocusHandler(e)
                                                    }}

                                                    validate={validate}
                                                    // onFocus={(e) => validateField(e.target.id)}
                                                    // onBlur={validate}
                                                    // onBlur={(event) => checkAfterBlur(event)}
                                                    onKeyUp={(e) => onKeyUpChangesHandler(e, values.input_main[tabIndex].data[weekIndex][columnHeader], numFormat)}
                                                    onFocus={(e) => onFocusHandler(e, values.input_main[tabIndex].data[weekIndex][columnHeader], numFormat)}
                                                    onBlur={(e) => onBlurChangesHandler(e, values.input_main[tabIndex].data[weekIndex][columnHeader], numFormat)}
                                                />
                                                {errors && errorExists && <div style={{color: 'white', fontWeight: 'normal' }}>{errorMessage}</div>}
                                                </>
                                                }
                                                
                                               
                                            </td>
                                        )
                                        
                                    } else if (columnHeader === 'date') {
                                        return (
                                            <th key={`input_main[${tabIndex}].data[${weekIndex}].${columnHeader}`} style={{backgroundColor: appColors.bgGray, color: appColors.mainTypoColor, whiteSpace: 'nowrap', borderBottom: 'none'}}>{week[columnHeader]}</th>
                                        )
                                    } else {
                                        return (
                                            <td key={`input_main[${tabIndex}].data[${weekIndex}].${columnHeader}`} className='non-edit-cell'>{columnHeader.includes('%') ? week[columnHeader].toLocaleString('pl-PL',  {maximumFractionDigits: 2, style: 'percent'}) :  week[columnHeader].toLocaleString('pl-PL',  {maximumFractionDigits: 2})}</td>
                                        )
                                    }
                                })
                            }
                        </tr>
                    )
                })}
            </tbody>
        </table>
        </div>
    )
})
}

return (
    <>{display}</>
);

}

export default DisplayMainComponent;