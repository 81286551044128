import React, { useState } from 'react';

import { sendPasswordResetEmail } from 'firebase/auth'
import { authentication } from '../../firebase/firebase';

const SetPassword = () => {

    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
    }  
    
    const setPasswordHandler = (email) => {
       return sendPasswordResetEmail(authentication, email, {
        
       })
    }
    return (
        <div>
            <input type='email' value={email} onChange={e => setEmail(e.target.value)} />
            <button>Set Password</button>
        </div>
    );
};

export default SetPassword;