import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { doc, getDoc, setDoc, deleteDoc, updateDoc, query, collection, where, getDocs, onSnapshot } from '@firebase/firestore';
import { db } from '../../firebase/firebase';
import { Form, Formik } from 'formik';
import _ from 'lodash';
// CONTEXT:
import AuthContext from '../../store/auth-context';
import DataContext from '../../store/data-context';
// DATA:
import { useHeaders } from '../data/useHeaders';
// import { useRootScenario } from '../data/useRootScenario';
import { useGetAllScenarios } from '../data/useGetAllScenarios';
import {rootLocalData} from '../../rootData.js'
// import { useGetPrivateScenarios } from '../data/useGetPrivateScenarios';
// import { useGetPublicScenarios } from '../data/useGetPublicScenarios';
//COMPONENTS: 
import DisplayMainComponent from './DisplayMainComponent';
import OutputTable from './OutputTable';
import DisplayCosts from './DisplayCosts';
// MODALS:
import SaveModal from '../modals/SaveModal';
import SaveAsModal from '../modals/SaveAsModal';
import ResetModal from '../modals/ResetModal';
import ConfirmModal from '../modals/ConfirmModal';
import EditModal from '../modals/EditModal';

import { Button } from '../UI/Button';
import Card from '../UI/Card';
import { deleteIcon, downloadIcon, editIcon } from '../../assets/icons/Icons';

import { modalTexts, buttonTexts, appTexts } from '../../utils/translate';

import './FirstTable.css';
import buttonClasses from "../UI/Button.module.css";

import { exportToExcelFn } from '../../utils/exportToExcelFn'; 
import { generateId } from '../../utils/generatePassword';
import { useGetModelMetadata } from '../data/useGetModelMetadata';
import DisplayGoal from './DisplayGoal';
import { useGetSelectedScenario } from '../data/useGetSelectedScenario';


const Calculator = (props) => {
  // console.log(props.match.params);

  const history = useHistory();
  const dataFromUrl = {
    projectId: props.match.params.projectId,
    modelId: props.match.params.modelId,
    scenarioId: props.match.params.scenarioId
  }

  console.log(history);

  // console.log(dataFromUrl);
  const authCtx = useContext(AuthContext);
  const dataCtx = useContext(DataContext);
  const location = useLocation();

     
    const [activeTab, setActiveTab] = useState('');
    const [initialValues, setInitialValues] = useState({});
    const [formValues, setFormValues] = useState({});
    const [outputData, setOutputData] = useState({})

    const [allValuesBeforeSent, setAllValuesBeforeSent] = useState({})

  const [allScenarios, setAllScenarios] = useState([]);
  const [allModelsSettings, setAllModelsSettings] = useState([]);
  const [projectId, setProjectId] = useState(dataFromUrl.projectId);
  const [modelId, setModelId] = useState(dataFromUrl.modelId);
  const [modelName, setModelName] = useState();

  let noData = false;

  if (location && location.state === undefined && allScenarios.length === 0) {
    noData = true
  }

  const dataFetchedChangeState = () => {
    noData = false;
  }


  const modelMetadata = useGetModelMetadata({projectId, modelId});
  const headers = useHeaders({projectId});
  const rootData = useGetAllScenarios({projectId, modelId, noData, dataFetchedChangeState});

    const scenarioSelectRef = useRef();
    const [selectedScenario, setSelectedScenario] = useState(dataFromUrl.scenarioId);
    const [selectedScenarioName, setSelectedScenarioName] = useState('');

    const handleScenarioSelection = (e) => {
      e.preventDefault();
      dataCtx.scenarioNameHandler(scenarioSelectRef.current.value);
      setSelectedScenario(scenarioSelectRef.current.value)

      // dataCtx.scenarioHandler(selectedScenario);
      // setSelectedScenario(scenarioSelectRef.current.value)
  }

  const currentScenarioData = useGetSelectedScenario({projectId, modelId, scenarioId: selectedScenario})
    let rootScenario = {};
    if (rootData) {
      rootScenario = rootData.find(scenario => scenario.id === 'root')
    }

    useEffect(() => {
      if (rootData && rootData.length > 0) {
        // console.log(rootData[0].input_main[0].name);
        setActiveTab(rootData[0].input_main[0].name);
      } else if (!rootData && allScenarios && allScenarios.length > 0) {
          setActiveTab(allScenarios[0].input_main[0].name);

      }
    }, [rootData, allScenarios])


    
    const [sentValues, setSentValues] = useState({});

    const [receivedValues, setReceivedValues] = useState({});



// VALIDATION
const validateNumbers = (value) => {
    // console.log('checked');
    let error;

    if (value === '') {
        error = 'Required';
    // } else if (/^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(value) === true) {
    //     error = 'hmm';
    } else if (/^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(value) === false) {
        error = 'not a number'
    }
    return error;

} 

    let headersArrays;

    if (headers) {
         headersArrays = headers[0].columns_metadata
    }

    let mediaHeadersArray = [];

    let mediaHeaders;
    if (headers && headers.length > 0) {
        const currentModel = headers.filter(model => model.id === modelId)
        // console.log(currentModel);
        mediaHeaders = currentModel[0].columns_metadata.filter(col => col.typ === 'mediowa')
        // console.log(mediaHeaders);
        mediaHeaders.map(mediaColumn => mediaHeadersArray.push(mediaColumn['nazwa wyświetlana']))
    }
   

    let displayTabs;

    if (rootData && rootData.length > 0) {
        // const tabsNum = rootData[0].input_main.length
        // console.log(tabsNum)

        const colNumPerTab = [];
        const mainTabsData = rootData[0].input_main;
        let stopIndex = 0;
        
        for (let i = 0; i < mainTabsData.length; i++) {
            stopIndex = stopIndex + mainTabsData[i].header.length;
            colNumPerTab.push(stopIndex);
        }

        // console.log(colNumPerTab);


        // console.log(mainTabsData);
        // console.log(rootData[0]);
        // console.log(rootScenario);

        let cutArray = [];
        if (headersArrays && colNumPerTab) {
            for (let i = 0; i < colNumPerTab.length; i++) {
                let part;
                if (i === 0) {
                    part = headersArrays.slice(2, colNumPerTab[i]+1);
                } else if (i === colNumPerTab.length-1) {
                    part = headersArrays.slice(colNumPerTab[i-1]);
                } else {
                    part = headersArrays.slice(colNumPerTab[i-1]+1, colNumPerTab[i])
                }
                cutArray.push(part)
            }
        }
        
        // console.log(cutArray);

        displayTabs = mainTabsData.map(tab => {
            
            return (
            <div className={tab.name === activeTab ? 'active' : 'inactive'} onClick={(event) => setActiveTab(event.target.innerHTML)} key={`tab-${tab.name}`}>{tab.name}</div>
            )
        
        })
    } else if (!rootData && allScenarios && allScenarios.length > 0) {
      const mainTabsData = allScenarios[0].input_main;
      displayTabs = mainTabsData.map(tab => {
            
        return (
        <div className={tab.name === activeTab ? 'active' : 'inactive'} onClick={(event) => setActiveTab(event.target.innerHTML)} key={`tab-${tab.name}`}>{tab.name}</div>
        )
    
    })
    }

    

    // FORMIK
    

// console.log(rootData);

    // console.log(typeof initialValues.input_goal)
useEffect(() => {
    //  if (!location.state && fetchedRootScenarioOnce) {
    //     console.log(fetchedRootScenarioOnce);

    //     setInitialValues(fetchedRootScenarioOnce);
    //     setFormValues(fetchedRootScenarioOnce)
    //     setOutputData({
    //         output_forecast: fetchedRootScenarioOnce.output_forecast,
    //         output_media: fetchedRootScenarioOnce.output_media
    //     })  
    //   }
      if(dataFromUrl) {
          setProjectId(dataFromUrl.projectId);
          setModelId(dataFromUrl.modelId);
          setSelectedScenario(dataFromUrl.scenarioId);
          // console.log('data from 1');
      }
      if (location.state) {
        // console.log('data from 2');
        // console.log(location.state.scenario);
            setInitialValues({...location.state.scenario})
            setOutputData({
              output_forecast: location.state.scenario.output_forecast,
              output_media: location.state.scenario.output_media
          }) 
          setAllScenarios(location.state.allScenarios);
          // console.log(allScenarios);
          setAllModelsSettings(location.state.currentModels);
          setProjectId(location.state.scenario.project_id);
          setModelId(location.state.scenario.model_id);
          setModelName(location.state.selectedModelName);
          setSelectedScenario(location.state.scenario.id);
          setSelectedScenarioName(location.state.scenario.scenario_name);

          dataCtx.projectHandler(location.state.scenario.project_id)
          dataCtx.modelHandler(location.state.scenario.model_id)
          dataCtx.scenarioHandler(location.state.scenario.id)
          dataCtx.scenarioNameHandler(location.state.scenario.scenario_name)
      } else if (!location.state && rootData) {
        
        // console.log(rootData);
        // setInitialValues()
        // console.log(allScenarios);
        // console.log('data from 3');
        
        setAllScenarios(rootData)
      } 
      // else if (!location.state && !rootData) {
      //     const myQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'));
      //     onSnapshot(myQuery, (querySnapshot) => {
      //       const allData = [];
      //       querySnapshot.forEach((doc) => {
      //           allData.push({...doc.data(), id: doc.id});
      //       });
      //       // console.log(allData);
      //       console.log('data from 4');
      //       setAllScenarios(allData);
      //     });

      // }
    }, [location.state, rootData])
    

// console.log(allScenarios);
let displayScenarioSelect;
if (allScenarios && allScenarios.length > 0) {
  displayScenarioSelect = allScenarios.map(scenario => <option selected={scenario.scenario_name === selectedScenarioName} key={`option-${scenario.scenario_name}`}>{scenario.scenario_name}</option>);
}

const [sentData, setSentData] = useState()
const [valuesSubmitted, setValuesSubmitted] = useState(false)
const [isLoading, setIsLoading] = useState(false);

// useEffect((sentValues) => {
    const handleSendValues = (data) => {

        setIsLoading(true);
    let url = `https://one-budget-allocator.groupone.pl/api/v1/${projectId}/models/${modelId}/calculate`;
// console.log(JSON.stringify(data));
    // if (valuesSubmitted) {
        fetch(url,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setIsLoading(false);
            if(res.ok) {
                return res.json()
            } else {
                let errorMessage = 'fetch fail'
                throw new Error(errorMessage)
            }
            
        }).then(data => {
         const recData = {
            output_forecast: data.data.output_forecast,
            output_media: data.data.output_media,
            
         }
         setReceivedValues(recData);

         setOutputData(recData);
         checkValuesDifference(false);
       

      }).catch(err => {
         alert(err.message);
       });
    // }
    
        setValuesSubmitted(false)
    }

    
    const handleSaveEdittedScenario =  async (e, visibility) => {
      const docId = selectedScenario;
      // const inputData = {...sentValues};
      
      const valuesToSave = {
         ...sentValues, 
         output_forecast: outputData.output_forecast,
         output_media: outputData.output_media,
         id: docId,
         model_id: modelId,
        project_id: projectId,
         scenario_name: selectedScenarioName,
         updated_at: new Date(),
         visibility: initialValues.visibility,
        created_at: initialValues.created_at,
        owner_name: initialValues.owner_name,
        owner_id: initialValues.owner_id,
         
      }

      const recordRef = doc(db, 'projects', projectId, 'models', modelId, 'scenarios', docId);

      const modelQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', selectedScenarioName));
      let scenarios = [];
      const querySnapshot = await getDocs(modelQuery);
      querySnapshot.forEach((doc) => {
          scenarios.push({...doc.data()});
      }
      );

      // const docSnap = await getDoc(recordRef); 
        // if (docSnap.exists()) {
      if (scenarios.length > 0 && scenarios[0].id !== selectedScenario) {
            alert(`The scenario with this name already exists, the process will be stopped`);
            return;
        } else {
            await setDoc(recordRef, valuesToSave
            ).then(() => {                
                setSaveModalOpen(false)
                setInitialValues(valuesToSave)
                // setAllScenarios([...allScenarios, valuesToSave]);
                // setSelectedScenario(valuesToSave.id)
                // setSelectedScenarioName(valuesToSave.scenario_name)
                // dataCtx.scenarioHandler(valuesToSave.id)
                setCalculated(false)
                history.push(`/calculator/${projectId}/${modelId}/${docId}`)
            })
        }   
      // alert('save as a NEW scenario')
    }


    const handleResetScenario = (scenarioName) => {

      let currentScenario;

      if (!allScenarios && rootData) {
        currentScenario = rootData.find(scenario => scenario.scenario_name === scenarioName)

      } 
      else if (allScenarios && allScenarios.length > 0) {
        currentScenario = allScenarios.find(scenario => scenario.scenario_name === scenarioName)
      } else {
        currentScenario = rootData.find(scenario => scenario.scenario_name === scenarioName)

      }

      setInitialValues(currentScenario)
      setOutputData({
          output_forecast: currentScenario.output_forecast,
          output_media: currentScenario.output_media
      })
      setInitialValues({...currentScenario})
      handleResetModalClose(); 
      handleLoadDefaultModalClose(); 
      history.push(`/calculator/${projectId}/${modelId}/${currentScenario.id}`)
      checkValuesDifference(false)
      setCalculated(false)

    }
    // Save as modal:
    const [saveAsModalOpen, setSaveAsModalOpen] = useState(false);
    const handleSaveAsModalOpen = () => setSaveAsModalOpen(true);
    const handleSaveAsModalClose = () => setSaveAsModalOpen(false);
    // Save modal:    
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const handleSaveModalOpen = () => setSaveModalOpen(true);
    const handleSaveModalClose = () => setSaveModalOpen(false);
    // Reset modal:
    const [resetModalOpen, setResetModalOpen] = useState(false);
    const handleResetModalOpen = () => setResetModalOpen(true);
    const handleResetModalClose = () => setResetModalOpen(false); 
    // Load default scenario modal:setAllScenarios
    const [loadDefaultModalOpen, setLoadDefaultModalOpen] = useState(false);
    const handleLoadDefaultModalOpen = () => setLoadDefaultModalOpen(true);
    const handleLoadDefaultModalClose = () => setLoadDefaultModalOpen(false); 
    // Delete scenario modal:
    const [deleteScenarioModalOpen, setDeleteScenarioModalOpen] = useState(false);
    const handleDeleteScenarioModalOpen = () => setDeleteScenarioModalOpen(true);
    const handleDeleteScenarioModalClose = () => setDeleteScenarioModalOpen(false); 
    // Edit scenario modal:
    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleEditModalOpen = () => {
      setEditModalOpen(true);
    }
    const handleEditModalClose = () => setEditModalOpen(false);


    const handleEditScenario = async (e, scenarioId, scenarioEdittedName, visibility) => {
      // alert(`To edit: ${scenarioId}`)
      const modelQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioEdittedName));
      const querySnapshot = await getDocs(modelQuery)
      const scenarioRef = doc(db, 'projects', projectId, 'models', modelId, 'scenarios', scenarioId);
      const scenarios = [];
      querySnapshot.forEach((doc) => {
          scenarios.push({...doc.data()});
      }
      );
      
      if (scenarios.length > 0 && scenarios[0].id !== scenarioId) {
          alert('Scenario with this name already exists, please introduce different name')
      } else {
          await updateDoc(scenarioRef, {visibility: visibility ? visibility : initialValues.visibility, scenario_name: scenarioEdittedName})
          .then(() => {
            setEditModalOpen(false)
            setSelectedScenarioName(scenarioEdittedName)
            // initialValues.scenario_name = scenarioEdittedName
            // initialValues.visibility = visibility
          })
      }
      
     
  }

    // const docId = generateId()
    // console.log(generateId(20));
    // console.log(docId);
    const handleSaveNewScenario = async (e, scenarioName, visibility) => {
      const docId = generateId(20);
      let inputData;
      if (!calculated) {
        inputData = {
          input_main: initialValues.input_main,
          input_costs: initialValues.input_costs,
          input_goal: initialValues.input_goal
        }
      } else {
        inputData = {...sentValues}
      }
      const valuesToSave = {
         ...inputData, 
         output_forecast: outputData.output_forecast,
         output_media: outputData.output_media,
         created_at: new Date(),
         id: docId,
         model_id: modelId,
         owner_id: authCtx.currentUserId,
         owner_name: authCtx.currentUserName === null ? authCtx.currentUserProfile.name : authCtx.currentUserName,
         project_id: projectId,
         scenario_name: scenarioName,
         updated_at: new Date(),
         visibility: visibility
      }

      const recordRef = doc(db, 'projects', projectId, 'models', modelId, 'scenarios', docId);

      const modelQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioName));
      const querySnapshot = await getDocs(modelQuery)
      const scenarios = [];
      querySnapshot.forEach((doc) => {
          scenarios.push({...doc.data()});
      }
      );

      const docSnap = await getDoc(recordRef); 
        // if (docSnap.exists()) {
      if (scenarios.length > 0 && scenarios[0].id !== docId) {
            alert(`The scenario with this name already exists, the process will be stopped`);
            return;
        } else {
            await setDoc(recordRef, valuesToSave
            ).then(() => {                
                setSaveAsModalOpen(false)
                setInitialValues(valuesToSave)
                setAllScenarios([...allScenarios, valuesToSave]);
                setSelectedScenario(docId)
                setSelectedScenarioName(valuesToSave.scenario_name)
                dataCtx.scenarioHandler(valuesToSave.id)
                dataCtx.scenarioNameHandler(valuesToSave.scenario_name)
                setCalculated(false)
                history.push(`/calculator/${projectId}/${modelId}/${docId}`)
            })
        }   
      // alert('save as a NEW scenario')
    }

    const handleDeleteScenario = async () => {
      const scenarioRef = doc(db, 'projects', projectId, 'models', modelId, 'scenarios', selectedScenario);
      await deleteDoc(scenarioRef);
      history.push(`/project/${projectId}`)
      setDeleteScenarioModalOpen(false);
    }
    const handleDeleteModalOpen = () => {setDeleteScenarioModalOpen(true);}

const [outputOutdated, setOutputOutdated] = useState(false);
const [calculated, setCalculated] = useState(false);

const checkValuesDifference = (bool) => {
   setOutputOutdated(bool)
}

const handleFirstCalculation = () => {
   setCalculated(true);
}

    return (
        <div className='app-main' style={{marginTop: '250px', paddingBottom: '20px', height: '100%'}}>
                <div className='main-content-header'>
                  <div style={{position: 'absolute', top: '30px'}}>
                     <h2>{appTexts.calculator[dataCtx.lang]}</h2>
                  </div>
                  <div style={{position: 'absolute', top: '30px', right: '2%', textAlign: 'right'}}>

                    <Card>
                        <div style={{padding: '5px 10px', textTransform: 'uppercase'}}>{appTexts.current[dataCtx.lang]} {appTexts.project.sing[dataCtx.lang]}: <b style={{textTransform: 'none'}}>{projectId}</b></div>
                        <div style={{padding: '5px 10px', textTransform: 'uppercase'}}>{appTexts.current[dataCtx.lang]} {appTexts.model.sing[dataCtx.lang]}: <b style={{textTransform: 'none'}}>{modelName}</b></div>
                    </Card>
                  </div>
                 

                  
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sollicitudin pulvinar nisl eu posuere. Quisque ut scelerisque nisi. Etiam feugiat nisl ligula, eu consectetur augue.</p> */}
              </div>
            {/* <table className='first-table'>
                <thead>
                    {displayHeaders}
                </thead>
            </table> */}

            <p></p>
            <nav className='tabs-nav'>
                {displayTabs}
                <div className={activeTab === 'Costs' ? 'active' : 'inactive'} onClick={(event) => setActiveTab(event.target.innerHTML)}>Costs</div>
            </nav>
            {/* <p>{outputOutdated ? 'shoud be recalculated' : 'DATA OK'}</p> */}
            
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validateNumbers={validateNumbers}
                onSubmit={async (values) => {

                    const input_values = {
                        input_main: [...values.input_main],
                        input_costs: [...values.input_costs],
                        input_goal: values.input_goal
                    }
                    setSentValues(input_values);
                    setAllValuesBeforeSent(values);
                    // console.log(sentValues);
                    // console.log(formValues);
                    await new Promise((r) => setTimeout(r, 500));
                    setValuesSubmitted(true)
                    // alert(JSON.stringify(input_values, null, 2));
                    // handleUpdate(formValues);
                    handleSendValues(input_values)
            }
            }
            >
                {({errors, values, handleSubmit, handleChange, handleReset, resetForm, validateField}) => {
                    // console.log(values);
                    // console.log(errors);
                    setFormValues(values);
                    // console.log(formValues);
                    setSelectedScenario(initialValues.id)
                    setSelectedScenario(initialValues.id)
                    const resetFunction = (scenarioId, scenarioName) => {
                      resetForm()
                      handleResetScenario(scenarioName)
                      setSelectedScenario(scenarioId)
                      setSelectedScenarioName(scenarioName)
                      dataCtx.scenarioHandler(scenarioId)
                    }
                    
        
                    

                    return (
                      <Form onSubmit={handleSubmit} onReset={handleReset}>
                        <div style={{minHeight: '10px', backgroundColor: '#fff'}}></div>
                        <div style={{ maxHeight: "400px", overflow: "auto", backgroundColor: '#fff' }}>
                          <div style={{position: 'absolute', top: '190px', right: '2%'}}>
                            {initialValues && initialValues.scenario_name !== 'root' && authCtx.currentUserId === initialValues.owner_id && <Button styling='light' type='button' clicked={handleSaveEdittedScenario} tooltipText='SAVE EDITTED SCENARIO' text='SAVE' notActive={initialValues.scenario_name === 'root' || !calculated || outputOutdated || (!calculated && outputOutdated) || authCtx.currentUserId !== initialValues.owner_id} /> }
                            <Button styling='light' type='button' clicked={handleSaveAsModalOpen} tooltipText='SAVE AS A NEW SCENARIO' text='SAVE AS' notActive={isLoading || outputOutdated}/>
                            <Button styling='light' type='button' clicked={handleResetModalOpen} tooltipText='RESET SCENARIO' text='RESET SCENARIO' 
                              notActive={_.isEqual(initialValues, values)}
                            />
                            {initialValues && initialValues.scenario_name !== 'root' && <Button styling='light' type='button' clicked={handleLoadDefaultModalOpen} tooltipText='RESET SCENARIO' text='LOAD DEFAULT SCENARIO' 
                              notActive={initialValues.scenario_name === 'root'}
                            />}
                            {/* <button onClick={(e) => exportToExcelFn(e, initialValues, modelMetadata, true)}>{downloadIcon} saved version</button> */}
                            <button title='DOWNLOAD TO XLSX' style={{position: 'relative', top: '19px'}} className={`${buttonClasses['custom']} ${buttonClasses['light']}`} onClick={calculated ? (e) => exportToExcelFn(e, values, modelMetadata, false) : (e) => exportToExcelFn(e, initialValues, modelMetadata, true)} disabled={outputOutdated}><span style={{position: 'relative', bottom: '9px'}}>{downloadIcon}</span></button>
                            {(authCtx.currentUserId === initialValues.owner_id || initialValues.scenario_id === 'root') && <button title={buttonTexts.delete[dataCtx.lang]} className={`${buttonClasses['custom']} ${buttonClasses['light']}`} onClick={handleDeleteModalOpen} disabled={outputOutdated}>{deleteIcon}</button>}
                          {/* <div>
                            <div>Scenario name: <b>{selectedScenarioName}</b></div> 
                            <div>Scenario status: <b>{initialValues.visibility}</b></div> 
                            {authCtx.currentUserId === initialValues.owner_id && <button onClick={handleEditModalOpen}>{editIcon}</button>}
                          </div> */}
                          </div>

                          <div style={{position: 'absolute', top: '120px', left: '120px'}}>
                              <h4>Choose scenario:</h4>
                              <Card>
                                <div style={{textAlign: 'left', padding: '10px'}}>
                                    
                                    <select style={{borderColor: '#fff'}}name='scenario-select' id='scenario-select' ref={scenarioSelectRef} onChange={() => resetFunction(initialValues.id, scenarioSelectRef.current.value)}>{displayScenarioSelect}</select>
                                  </div>
                              </Card>
                          </div>
                          <ResetModal passedFn={() => resetFunction(initialValues.id, initialValues.scenario_name)} open={resetModalOpen} handleClose={handleResetModalClose} handleAction={handleResetScenario} modalTitle={modalTexts.scenarioReset.title[dataCtx.lang]} content={modalTexts.scenarioReset.content[dataCtx.lang]} cancelButton={buttonTexts.cancel[dataCtx.lang]} confirmButton={buttonTexts.reset[dataCtx.lang]}/>
                          <ResetModal passedFn={() => resetFunction('root', 'root')} open={loadDefaultModalOpen} handleClose={handleLoadDefaultModalClose} handleAction={handleResetScenario} modalTitle={modalTexts.scenarioReset.title[dataCtx.lang]} content={modalTexts.loadDefaultScenario.content[dataCtx.lang]} cancelButton={buttonTexts.cancel[dataCtx.lang]} confirmButton={buttonTexts.reset[dataCtx.lang]}/>
                          {/* <p style={{position: 'absolute', top: '15px'}}>{initialValues.id}</p> */}
                          {/* {displayMain} */}
                          <div>
                          {allScenarios && (
                            <DisplayMainComponent
                              validate={validateNumbers}
                              // validateField={validateField}
                              initialValues={initialValues}
                              // sentValues={sentValues}
                              allValuesBeforeSent={allValuesBeforeSent}
                              // formValues={formValues}
                              values={values}
                              errors={errors}
                              // mainTabsData={initialValues.input_main}
                              // headers={headers}
                              activeTab={activeTab}
                              calculated={calculated}
                              checkValuesDifference={checkValuesDifference}
                              handleChange={handleChange}
                              modelMetadata={modelMetadata}
                            />
                          )}
                        </div>
                        </div>
                        <div
                          style={
                            activeTab === "Costs"
                              ? {
                                  display: "block",
                                  maxHeight: "400px",
                                  overflow: "overlay",
                                  backgroundColor: '#fff'
                                }
                              : {
                                  display: "none",
                                  maxHeight: "400px",
                                  overflow: "auto",
                                  backgroundColor: '#fff'
                                }
                          }
                        >
                         { <DisplayCosts
                            // rootData={rootData}
                            initialValues={initialValues}
                            values={values}
                            errors={errors}
                            validate={validateNumbers}
                            handleChange={handleChange}
                            modelMetadata={modelMetadata}
                            calculated={calculated}
                            allValuesBeforeSent={allValuesBeforeSent}
                            checkValuesDifference={checkValuesDifference}
                          />}
                        </div>
                        <div>
                          <h2>Goal</h2>
                          <DisplayGoal 
                            initialValues={initialValues}
                            values={values}
                            allValuesBeforeSent={allValuesBeforeSent}
                            calculated={calculated}
                            checkValuesDifference={checkValuesDifference}
                            errors={errors}
                            validate={validateNumbers}
                            handleChange={handleChange}
                            modelMetadata={modelMetadata}
                          />

                          {/* <table
                            className="first-table"
                            style={{ maxHeight: "50px", overflow: "scroll" }}
                          >
                            <tbody>
                              <tr>
                                <th>Goal</th>
                                <td style={initialValues.input_goal !== +values.input_goal ? {backgroundColor: '#27ae6066'} : {backgroundColor: '#5CA2D91A'}}>
                                  <Field
                                    required
                                    name={"input_goal"}
                                    id={"input_goal"}
                                    value={values.input_goal}
                                    onChange={handleChange}
                                    validate={validateNumbers}
                                  />
                                </td>{" "}
                              </tr>
                            </tbody>
                          </table> */}
                        </div>
                        {/* {formValues && <div>{JSON.stringify(formValues)}</div>} */}
                        {values && valuesSubmitted && (
                          <div>{JSON.stringify(values)}</div>
                        )}
                                                {Object.keys(errors).length > 0 && <p style={{color: 'red'}}>The table contains errors</p>}

                        <Button type="submit" styling='primary' style={{marginTop: '30px', marginBottom: '30px'}} notActive={isLoading || !outputOutdated || Object.keys(errors).length > 0} clicked={handleFirstCalculation} text={buttonTexts.calculate[dataCtx.lang]} />
                        <OutputTable modelMetadata={modelMetadata} mediaHeadersArray={mediaHeadersArray} outputData={outputData} outputOutdated={outputOutdated} isLoading={isLoading} clicked={handleFirstCalculation}/>
                      </Form>
                    );
                }

                }
                    
            </Formik>

            <SaveModal open={saveModalOpen} handleClose={handleSaveModalClose} handleSaveScenario={handleSaveEdittedScenario} modelName={modelName} scenarioName={selectedScenarioName} scenarioVisibility={initialValues.visibility}/>
            <SaveAsModal open={saveAsModalOpen} handleClose={handleSaveAsModalClose} handleSaveNewScenario={handleSaveNewScenario} modelName={modelName} />
            <ConfirmModal 
                scenarioId={selectedScenario} 
                scenarioName={selectedScenarioName}
                open={deleteScenarioModalOpen} 
                handleClose={handleDeleteScenarioModalClose} 
                handleAction={handleDeleteScenario} 
                modalTitle={modalTexts.scenarioDelete.title[dataCtx.lang]} 
                content={modalTexts.scenarioDelete.content[dataCtx.lang]} 
                cancelButton={buttonTexts.cancel[dataCtx.lang]} 
                confirmButton={buttonTexts.delete[dataCtx.lang]}
            />
             <EditModal 
                scenarioId={selectedScenario} 
                scenarioName={selectedScenarioName} 
                modelId={modelId} 
                modelName={modelName} 
                scenarioVisibility={initialValues.visibility} 
                open={editModalOpen} 
                handleClose={handleEditModalClose} 
                handleEditScenario={handleEditScenario} 
                modalTitle={modalTexts.scenarioDelete.title[dataCtx.lang]} 
                content={modalTexts.scenarioDelete.content[dataCtx.lang]} 
                cancelButton={buttonTexts.cancel[dataCtx.lang]} 
                confirmButton={buttonTexts.edit[dataCtx.lang]}


            />
        </div>
    );
};

export default Calculator;