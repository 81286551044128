import React from "react";

import { closeIcon } from '../../assets/icons/Icons';

const CloseModal = ({clicked}) => {
    return (
        <div style={{textAlign: 'right', cursor: 'pointer'}} onClick={clicked}>{closeIcon}</div>

    )
};

export default CloseModal;