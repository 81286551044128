import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './store/auth-context';
import { UserContextProvider } from './context/userContext';
import { DataContextProvider } from './store/data-context';

ReactDOM.render(
  //<React.StrictMode>
  <AuthContextProvider>
    <DataContextProvider>
  {/* <UserContextProvider> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </UserContextProvider>, */}
    </DataContextProvider>
  </AuthContextProvider>, 
  document.getElementById('root')
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
