import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { db } from '../../../firebase/firebase';
import { generateId } from '../../../utils/generatePassword';
import { Button } from '../../UI/Button';
import { doc, deleteDoc, updateDoc, getDoc, collection, query, where, getDocs, setDoc } from 'firebase/firestore';


const CreateModelSchema = (props) => {
    const history = useHistory()
    const projectId = history.location.state.projectId;
    // const projectId = props.match.params.projectId;
    const [modelId, setModelId] = useState('')
    const [docCreated, setDocCreated] = useState(false);

    const [modelIdCreated, setModelIdCreated] = useState(false)
    const [modelNameCreated, setModelNameCreated] = useState(false)

    const createModelIdHandler = () => {
        const docId = generateId(20)
        setModelId(docId);
    }

    const modelNameRef = useRef();
    const modelCurrencyRef = useRef();
    const visibilitySelectRef = useRef();
    const modelNotesRef = useRef();


    const [nameForModel, setNameForModel] = useState('');
    const handleNameChange = (e) => {
        setNameForModel(e.target.value);
        props.handleModelName(e.target.value);
    };

    const handleCollectData = (e) => {
        e.preventDefault();
        const modelName = modelNameRef.current.value;
        const currency = modelCurrencyRef.current.value;
        const notes = modelNotesRef.current.value;

        handleSetNewModel(projectId, modelId, modelName, currency, notes)
    }

    const handleSetNewModel = async (projectId, modelId, modelName, currency, notes) => {
        // const docId = generateId(20)
        // setModelId(docId);
        // const publishedCheckbox = publishedCheckboxRef.current.checked;
        // const modelVisibility = visibilitySelectRef.current.value;
        const data = {
            model_name: modelName,
            model_currency: currency,
            // visibility: modelVisibility,
            is_published: false,
            model_id: modelId,
            admin_notes: notes
        }

        const docRef = doc(db, 'projects', projectId, 'models', modelId);
        if (modelName === '') {
            alert('please fill in the model name')
        } else {
        const modelQuery = query(collection(db, 'projects', projectId, 'models'), where('model_name', '==', modelName));
        const querySnapshot = await getDocs(modelQuery)
        const modelRef = doc(db, 'projects', projectId, 'models', modelId);
        const models = [];
        querySnapshot.forEach((doc) => {
            models.push({...doc.data()});
        }
        );

        if (models.length > 0) {
                    alert('Model with this name already exists, please introduce different name')
                } else {
                    await setDoc(docRef, data)
                    props.handleModelId(modelId)
                    setDocCreated(true);      
                    // history.push(`/project/${projectId}`)         
                    props.handleDocCreation()  
                    setModelNameCreated(true)

        }
        }
    }


    return (
        <div >
            
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sollicitudin pulvinar nisl eu posuere. Quisque ut scelerisque nisi. Etiam feugiat nisl ligula, eu consectetur augue.</p> */}
            <div style={{display: 'inline-block'}}>
                {!modelIdCreated && <div style={{height: '100vh'}}>
                     <div>Model ID</div>
                     <button onClick={createModelIdHandler}>create ID for Model</button>
                     <div>MODEL ID: {modelId}</div>
                     {modelId && <button onClick={() => setModelIdCreated(true)}>NEXT</button>}
                </div>}
               {modelId && modelIdCreated &&
                <div style={{height: '100vh'}}>
                    <div>Model ID: {modelId}</div>
                    <div className='p10'>
                        <label htmlFor='model-name'>Model name: </label>
                        <input type='text' id='model-name' required ref={modelNameRef} onChange={handleNameChange} />
                        <span style={{color: 'red'}}> *</span>
                    </div>
                    <div>
                        <label htmlFor='model-currency'>Currency for the model: </label>
                        <select type='text' id='model-currency' required ref={modelCurrencyRef} >
                            <option selected value="PLN">PLN</option> 
                            <option value="EUR">EUR</option>
                            <option value="GBP">GBP</option>
                            <option value="USD">USD</option>
                        </select>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <label style={{minWidth: '40%', verticalAlign: 'top'}} htmlFor='model-notes'>Notes (visible by admins only): </label>
                        <textarea style={{width: '50%'}} cols='50' rows='15' ref={modelNotesRef}></textarea>
                    </div>
                    {modelId !== '' && nameForModel !== '' && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>       
                {/* <Button type='button' clicked={handleClose} text='ANULUJ' styling='light' /> */}
                <Button type='button' clicked={(e) => handleCollectData(e)} text='CREATE DOC IN DB' styling='primary' />
            </div>
            }
                </div>
                }
            </div>
               

            <div>
            {/* <select name="model-visibility" id="model-visibility" ref={visibilitySelectRef}> */}
                {/* <option selected value="draft">Draft</option> */}
                {/* <option value="published">Published</option> */}
                {/* <option value="published">Published</option> */}
            {/* </select> */}
            {/* <div>
                <input id='publish-checkbox' type='checkbox' style={{position: 'relative'}} ref={publishedCheckboxRef} onClick={handleCheckbox}/> 
                <label htmlFor='publish-checkbox'>Published</label>
                {published && <div style={{color: 'red'}}>Please, leave it unchecked for now, therwise the model will automatically seen by all the users. TEST IT FIRST!!!</div>}
            </div> */}
            </div>
           

        </div>
    )
}

export default CreateModelSchema;