import React, { useContext, useEffect, useState } from 'react';
import { getMetadata, ref, uploadBytesResumable } from 'firebase/storage';

import {storage, authentication} from '../../firebase/firebase';
import AuthContext from '../../store/auth-context';
import { getAuth } from 'firebase/auth';

import { useHeaders } from '../data/useHeaders';
import CreateModelSchema from './createModel/CreateModelSchema';
import { useHistory } from 'react-router-dom';
import CreateModelAddFile from './createModel/CreateModelAddFile';
import Card from '../UI/Card';
import './CreateModel.css';
import { Button } from '../UI/Button';
 

const CreateModel = () => {
    // const [file, setFile] = useState('');
    const authCtx = useContext(AuthContext);
    // const [progress, setProgress] = useState(0);


    const history = useHistory()

    const projectId = history.location.state.projectId;

    const [modelId, setModelId] = useState('')
    const handleModelId = (id) => {
        setModelId(id)
        // setModelId('rAozi8RW1wXXMtHmfpsI')
    }

    const [modelName, setModelName] = useState('');

    const handleModelName = (name) => {
        setModelName(name)
    }

    const [docCreated, setDocCreated] = useState(false);

    const handleDocCreation = () => {
        setDocCreated(true)
    }

    useEffect(() => {
        if (history.location) {
            if (history.location.state.modelId  && history.location.state.modelName) {
                handleModelId(history.location.state.modelId)
                handleModelName(history.location.state.modelName)
                handleDocCreation(true)
            } 
        }
    }, [])

    
    
    const [scriptFile, setScriptFile] = useState('')
    const setScriptFileFn = (path) => setScriptFile(path);
    const [scriptUploadProgress, setScriptUploadProgress] = useState(0);
    
    const [globalFile, setGlobalFile] = useState('')
    const setGlobalFileFn = (path) => setGlobalFile(path);
    const [globalUploadProgress, setGlobalUploadProgress] = useState(0);

    const [mainFile, setMainFile] = useState('')
    const setMainFileFn = (path) => setMainFile(path);
    const [mainUploadProgress, setMainUploadProgress] = useState(0);

    const [costsFile, setCostsFile] = useState('')
    const setCostsFileFn = (path) => setCostsFile(path);
    const [costsUploadProgress, setCostsUploadProgress] = useState(0);

    const [dictionaryFile, setDictionaryFile] = useState('')
    const setDictionaryFileFn = (path) => setDictionaryFile(path);
    const [dictionaryUploadProgress, setDictionaryUploadProgress] = useState(0);

    const handleFileChange = (event, setStateFn, setProgressFn) => {
        event.preventDefault();
        const file = event.target[0].files[0];
       handleFileUpload(file, modelId, setStateFn, setProgressFn)

    } 

    const handleFileUpload = (file, modelId, setStateFn, setProgressFn) => {
        if (!file) {
            alert("Please choose a file first!")
        }
        
        const storageRef = ref(storage, `/${projectId}/${modelId}/${file.name}`)
        
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', (snapshot) => {
            const uploadProgress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgressFn(uploadProgress);
        }, (err) => {console.log(err)},
        () => {
            getMetadata(uploadTask.snapshot.ref)
                .then(data => {
                    setStateFn(`gs://${data.bucket}/${data.fullPath}`)
                })
        })
    }


    const CreateData = () => {
        const data = {
            "costs_xlsx": costsFile,
            "main_xlsx": mainFile,
            "global_xlsx": globalFile,
            "dictionary_xlsx": dictionaryFile,
            "script": scriptFile,
            "owner_id": authCtx.currentUserProfile.id,
            "owner_name": authCtx.currentUserProfile.name
        }
        CreateRootModel(data)

    }

    const CreateRootModel = (data) => {
        

            // setIsLoading(true);
        let url = `https://one-budget-allocator.groupone.pl/api/v1/${projectId}/models/${modelId}/create`;
    // console.log(JSON.stringify(data));
        // if (valuesSubmitted) {
            fetch(url,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                // setIsLoading(false);
                return res.json()
                // if(res.ok) {
                //     return res.json()
                // } else {
                    
                //     console.log(res.json());
                //     // let errorMessage = res.error;
                //     // alert(errorMessage)
                //     // throw new Error(errorMessage)
                // }
                
            }).then(data => {
             if (data.success) {
                history.push('/projects')
             } else {
                let errorMessage = data.error;
                if (data.error.startsWith("'' does not match ")) {
                    errorMessage = 'One or more files are not uploaded, please check.'
                }
                alert(`ERROR OCCURRED! \n${errorMessage}`)

             }
            //  const recData = {
            //     output_forecast: data.data.output_forecast,
            //     output_media: data.data.output_media,
                
            //  }
            //  setReceivedValues(recData);
    
            //  setOutputData(recData);
            //  checkValuesDifference(false);
           
    
          }).catch(err => {
             alert(err);
           });
        // }
        
            // setValuesSubmitted(false)
    }
    





    return (
        <div style={{height: '100%', marginBottom: '100px', backgroundColor: '#f5f5f5'}} className='app-main'>
            <h2>Create model:</h2>

           {!docCreated && <div style={{textAlign: 'left', paddingLeft: '120px', marginBottom: '50px'}}>
                <CreateModelSchema handleModelId={handleModelId} handleDocCreation={handleDocCreation} handleModelName={handleModelName} />
            </div>
            } 
            {docCreated && <div style={{paddingLeft: '120px', paddingBottom: '50px'}}>
                <Card>
                    <div style={{textAlign: 'left'}}>
                        <div>Model ID: {modelId}</div>
                        <div>Model name: {modelName}</div>
                    </div>


                </Card>
            <div className='upload-file-container'>
                <h3>Tu wrzuć skrypt Python'a:</h3>
                <CreateModelAddFile progress={scriptUploadProgress} handleFileUpload={handleFileChange} setStateFn={(path) => setScriptFileFn(path)} setProgressFn={(progress) => setScriptUploadProgress(progress)}/>
                <div>{scriptFile}</div>
            </div> 
            <div className='upload-file-container'>
                <h3>Tu wrzuć tabelę XLS "GLOBAL":</h3>
                <CreateModelAddFile progress={globalUploadProgress} handleFileUpload={handleFileChange} setStateFn={(path) => setGlobalFileFn(path)} setProgressFn={(progress) => setGlobalUploadProgress(progress)}/>
                <div>{globalFile}</div>
            </div> 
            <div className='upload-file-container'>
                <h3>Tu wrzuć tabelę XLS "MAIN":</h3>
                <CreateModelAddFile progress={mainUploadProgress} handleFileUpload={handleFileChange} setStateFn={(path) => setMainFileFn(path)} setProgressFn={(progress) => setMainUploadProgress(progress)}/>
                <div>{mainFile}</div>
            </div> 
            <div className='upload-file-container'>
                
                <h3>Tu wrzuć tabelę XLS "COSTS":</h3>
                <CreateModelAddFile progress={costsUploadProgress} handleFileUpload={handleFileChange} setStateFn={(path) => setCostsFileFn(path)} setProgressFn={(progress) => setCostsUploadProgress(progress)}/>
                <div>{costsFile}</div>
                
            </div> 
            <div className='upload-file-container'>
                
                    <h3>Tu wrzuć tabelę XLS "DICTIONARY":</h3>
                    <CreateModelAddFile progress={dictionaryUploadProgress} handleFileUpload={handleFileChange} setStateFn={(path) => setDictionaryFileFn(path)} setProgressFn={(progress) => setDictionaryUploadProgress(progress)}/>
                    <div>{dictionaryFile}</div>
                
            </div> 
            <Button type='button' clicked={CreateData} text='Create ROOT SCENARIO' styling='primary'/>
            </div>
            }
{/* 
            <form onSubmit={() => handleFileChange}>
            <input type='file' />
            <button type="submit">Upload file</button>
            </form>
            <h3>Uploaded {progress}%</h3> */}

        </div>
    );
};

export default CreateModel;