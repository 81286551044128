import { useEffect, useState } from 'react';
import { collection, doc, query, where, onSnapshot, getDoc, collectionGroup } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';



export const useGetSelectedScenario = (props) => {

    const {projectId, modelId, scenarioId } = props;

    const [currentScenario, setCurrentScenario] = useState({});

    const user = authentication.currentUser;
    let user_id;
    if (user !== null) {
        user_id = user.uid;
    }


    useEffect (() => { 
        const fetchData = async () => {
            const docRef = doc(db, "projects", projectId, 'models', modelId, 'scenarios', scenarioId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setCurrentScenario({...docSnap.data(), id: docSnap.id })
                    console.log('data fetched');
                } else {
                    console.log('no such doc');
                }
    }
        
          
    fetchData()

        const docRef = doc(db, "projects", projectId, 'models', modelId, 'scenarios', scenarioId);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            snapshot.docChanges ((change) => {
              if (change.type === "added") {
                  console.log("New scenario: ", change.doc.data());
              }
              if (change.type === "modified") {
                  console.log("Modified scenario: ", change.doc.data());
              }
              if (change.type === "removed") {
                  console.log("Removed scenario: ", change.doc.data());
              }
            });
          });

         
    }, [scenarioId])
    console.log(currentScenario);
return currentScenario;
}; 

