// niezapisany scenariusz
export const modalTexts = {
    modelName: {
        pl: 'Nazwa modelu',
        en: 'Model name'
    },
    scenarioName: {
        pl: 'Nazwa scenariusza',
        en: 'Scenario name'
    },
    scenarioNotSaved: {
        title: {
            pl: 'Niezapisany scenariusz',
            en: 'Scenario not saved' 
        },
        content: {
            pl: 'Chcesz wyjść ze scenariusza bez jego zapisu. Czy zapisać scenariusz?',
            en: 'Do you want to leave without saving the changes?'
        }
    },
    scenarioReset: {
        title: {
            pl: 'Resetuj scenariusz',
            en: 'Reset scenario'
        },
        content: {
            pl: 'Czy na pewno chcesz zresetować wszystkie wartości wprowadzone w scenariuszu i przywrócić domyślne?',
            en: 'Are you sure you want to reset all the changes and revert to the saved version of the current scenario?'
        }

    },
    loadDefaultScenario: {
        title: {
            pl: 'Wczytaj domyślny scenariusz', 
            en: 'Load default scenario' 
        },
        content: {
            pl: 'Czy na pewno chcesz zresetować wszystkie wartości wprowadzone w scenariuszu i przywrócić domyślny scenariusz?',
            en: 'Are you sure you want to reset all the changes made in the current scenario and load the default one?'
        }

    },
    scenarioDelete: {
        title: {
            pl: 'Usuń scenariusz',
            en: 'Delete scenario',
        },
        content: {
            pl: 'Czy na pewno chcesz usunąć scenariusz?',
            en: 'Are you sure you want to delete this scenario?'
        }
    },
    saveScenario: {
        title: {
            pl: 'Zapisz obecny scenariusz',
            en: 'Save current scenario',
        },
        content: {
            pl: 'Czy na pewno chcesz zachowwać zmiany w obecnym scenariuszu?',
            en: 'Are you sure you want to save changes to the current scenario?'
        },
    },
    saveAsScenario: {
        title: {
            pl: 'Zapisz jako',
            en: 'Save as',
        },
        content: {
            pl: 'Czy na pewno chcesz stworzyć nowy scenariusz na bazie obecnego?',
            en: 'Are you sure you want to create new scenario based on the current one?'
        },
    },
    editScenario: {
        title: {
            pl: 'Edytuj scenariusz',
            en: 'Edit scenario',
        },
        content: {
            pl: 'Czy na pewno chcesz edytować scenariusz?',
            en: 'Are you sure you want to edit current scenario?'
        },
    },
}

// BUTTONS 

export const buttonTexts = {
    cancel : {
        pl: 'ANULUJ',
        en: 'CANCEL'
    },
    save: {
        pl: 'ZACHOWAJ',
        en: 'SAVE'
    },
    delete: {
        pl: 'Usuń',
        en: 'Delete'
    },
    denySave: {
        pl: 'NIE ZAPISUJ',
        en: `DON'T SAVE`
    },
    reset: {
        pl: 'RESETUJ',
        en: 'RESET'
    },
    edit: {
        pl: 'Edytuj',
        en: 'Edit'
    },
    open: {
        pl: 'Otwórz',
        en: 'Open'
    },
    calculate: {
        pl: 'Przelicz',
        en: 'Calculate'
    }
}

export const appTexts = {
    calculator: {
        pl: 'Kalkulator',
        en: 'Calculator'
    },
    project: {
        sing: {
            pl: 'projekt',
            en: 'project'
        },
        plural: {
            pl: 'projekty',
            en: 'projects'
        }
    },
    model: {
        sing: {
            pl: 'model',
            en: 'model'
        },
        plural: {
            pl: 'modele',
            en: 'models'
        }
    },
    scenario: {
        sing: {
            pl: 'scenariusz',
            en: 'scenario'
        },
        plural: {
            pl: 'scenariusze',
            en: 'scenarios'
        }

    },
    choose: {
        pl: 'Wybierz',
        en: 'Choose'
    },
    current: {
        pl: 'wybrany',
        en: 'current'
    },
    allProjectsPage: {
        choose: {
            pl: 'Wybierz projekt:',
            en: 'Choose project:'
        }
    },
    projectPage: {
        choose: {
            pl: 'Wybierz model:',
            en: 'Choose model:'
        },
        chooseModelBox: {       
            pl: 'Wybierz model, aby móc tworzyć lub edytować istniejące scenariusze.',
            en: 'Choose a model to create and edit scenarios in the calculator.'
        },
        noScenariosBox: {       
            pl: 'Nie ma jeszcze dostępnych scenariuszy w tym modelu. Kliknij "Open root scenario", aby otworzyć scenariusz bazowy.',
            en: 'There are no scenarios available for this model. Click "Open root scenario" to open the default one in the calculator.'
        }
    }
}