import React, {useContext, useEffect, useState} from 'react';
import { useHistory, Router, Route, Switch, Redirect } from 'react-router-dom';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

//import logo from './logo.svg';
import './App.css';
import CreateModel from './components/AdminView/CreateModel';
import EditSuperUser from './components/AdminView/EditSuperUser';
import CreateUserAccountForm from './components/Auth/CreateUserAccountForm';
import CreateUserAccount from './components/AdminView/createUser/CreateUserAccount';
import LoginForm from './components/Auth/LoginForm';
import ResetPassword from './components/Auth/ResetPassword';
import SetPassword from './components/Auth/SetPassword';
import HomePage from './components/HomePage';
// import CreateUser from './components/CreateUser';
import MainNavigation from './components/Navigation/MainNavigation';
import FirstTableWithFormik from './components/userView/FirstTableWithFormik';
import AuthContext from './store/auth-context';
import LoginEmail from './components/Auth/LoginEmail';
import { useGetUsersProfile } from './components/data/useGetUsersProfile';
import AllProjects from './components/AdminView/AllProjects';
import UsersProjects from './components/userView/UsersProjects';
import ProjectView from './components/CommonViews/ProjectView';
import HeaderUserProfile from './components/Navigation/HeaderUserProfile';
import Intro from './components/Intro';
import ManageProject from './components/AdminView/ManageProject';
import Calculator from './components/userView/Calculator';
import ManageModel from './components/AdminView/ManageModel';
import NotAuthorizedPage from './components/CommonViews/NotAuthorizedPage';
import CreateUserProfile from './components/AdminView/createUser/CreateUserProfile';
import SendResetPassToUser from './components/AdminView/createUser/SendResetPassToUser';
import AdminPage from './components/AdminView/AdminPage';
import EditUser from './components/AdminView/EditUser';
import AllUsers from './components/AdminView/AllUsers';


  //COLOURS:
  const mainAccentColor = '#13A3DC' //'#5CA2D9'

function App() {
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  //let isAdmin = false
const [isAdmin, setIsAdmin] = useState(null)
  const auth = getAuth();

  let userId;
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const authEmail =  user.email
      userId = user.uid;
      authCtx.currentUserId = user.uid;
      authCtx.currentUserName = user.displayName;

    } else {
      console.log('user is not logged in');
    }
  })
  // const tempUser = auth.currentUser;

  // if (tempUser) {
  //   console.log('IN');
  // } else {
  //   console.log('OUT');
  // }

 const usersProfile = useGetUsersProfile(userId);

 useEffect(() => {
 if (usersProfile) {
  setIsAdmin(usersProfile['is_admin'])
  authCtx.manageUserProfile(usersProfile)
 }
 }, [usersProfile])

 useEffect(() => {
  if (authCtx.isLoggedIn) {
   setIsAdmin(usersProfile['is_admin'])
  } else {
    setIsAdmin(null)
  }
  }, [authCtx.isLoggedIn])


 

  // const [inputValue, setInputValue] = u#13A3DCseState('');

  // const handleInputChange = (event) => {
  //   console.log(event.target.value);
  //   setInputValue(event.target.value)
  // }

  // useEffect(() => {
  //   console.log('useEffect: ' + inputValue);
  //  const identifier = setTimeout(() => {
  //     console.log('setTimeout in useEffect: ' + inputValue)
  //   }, 3000)
  //   return () => {
  //     console.log('CleanUP ' + inputValue);
  //     clearTimeout(identifier);

  //   }
  // }, [inputValue])

  let routes;
  if (isAdmin && authCtx.isLoggedIn) {
    routes = (      
    <Switch>
    <Route path='/create-super-user' exact component={CreateUserAccountForm} />
    <Route path='/admin' exact component={AdminPage} />
    <Route path='/admin/all-users' exact component={AllUsers} />
    
    <Route path='/admin/create-user' exact component={CreateUserAccount} />
    <Route path='/admin/add-users-profile-info' exact component={CreateUserProfile} />
    {/* <Route path='/admin/add-users-profile' exact component={CreateUserProfile} /> */}
    {/* <Route path='/admin/edit-user' exact component={EditSuperUser} /> */}
    <Route path='/admin/send-reset-pass-to-user' exact component={SendResetPassToUser} />
    <Route path='/admin/edit-user/:userId' exact component={EditUser} />
    
    <Route path='/login-form' exact component={LoginForm} />
    <Route path='/login' exact component={LoginEmail} />
    <Route path='/reset-pass' exact component={ResetPassword} />
    <Route path='/set-pass' exact component={SetPassword} />
    <Route path='/manage-project/:projectId/manage-model/:modelId' component={ManageModel} />
    <Route path='/manage-project/:projectId/create-model' exact component={CreateModel} />
    <Route path='/calculator/:projectId/:modelId/:scenarioId' render={(props) => <FirstTableWithFormik {...props} />} />
    <Route path='/new-calculator/:projectId/:modelId/:scenarioId' render={(props) => <Calculator {...props} />} />
    {/* <Route path='/all-projects' exact render={(props) => <AllProjects {...props} usersProfile={usersProfile}/>} /> */}
    <Route path='/manage-project/:projectId' exact render={(props) => <ManageProject {...props} />} />
    <Route path='/projects' exact render={(props) => <AllProjects {...props} />} />
    <Route path='/project/:projectId' exact render={(props) => <ProjectView {...props} />}/>
    <Route path='/reset-password' exact component={ResetPassword} />
    <Route path='/not-authorized' exact component={NotAuthorizedPage}/>
    <Route render={(props) => <MainNavigation {...props} />} />

    <Route path='/' exact component={HomePage} />
  </Switch>
    )
  } else if (!isAdmin && authCtx.isLoggedIn) {
    routes = (      
      <Switch>
        <Route path='/calculator/:projectId/:modelId/:scenarioId' render={(props) => <FirstTableWithFormik {...props} />} />
        <Route path='/new-calculator/:projectId/:modelId/:scenarioId' render={(props) => <Calculator {...props} />} />
        <Route path='/projects' exact render={(props) => <UsersProjects {...props} />} />
        <Route path='/project/:projectId' exact render={(props) => <ProjectView {...props} />} />
        <Route path='/not-authorized' exact component={NotAuthorizedPage}/>
        <Route path='/reset-password' exact component={ResetPassword} />
        <Route path='/' render={(props) => <MainNavigation {...props} />} />

        

        <Route path='/' exact component={HomePage} />

      </Switch>
      )
  } else if (!authCtx.isLoggedIn){
    routes = (      
      <Switch>
      <Route path='/login' exact component={LoginEmail} />
      <Route path='/reset-password' exact component={ResetPassword} />
      {/* <Route path='/' exact component={Intro} /> */}
      <Redirect to='/login' />
    </Switch>
    )
  }


  return (
    <div className="App">
      {/* <header className="App-header">
        {authCtx.isLoggedIn && <HeaderUserProfile />}
      </header> */}
        {/* {isAdmin === true && authCtx.isLoggedIn && <div>ADMIN</div>} */}
        {/* {isAdmin === false && authCtx.isLoggedIn && <div>NOT ADMIN</div>} */}
        {authCtx.isLoggedIn && <MainNavigation />}
      <Router history={history}>
        {routes}
      </Router>
    </div>
  );
}

export default App;
