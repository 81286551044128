import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import { doc, setDoc } from "firebase/firestore";

import { db } from '../../firebase/firebase';

import classes from './AuthForm.module.css';

const CreateUserAccountForm = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();


  const [pass, setPass] = useState('');

  const generatePassword = () => {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_-+=";
    const length = 8;
    let passwordTxt = '';

    for (let i = 0; i < length; i++) {
        passwordTxt += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    setPass(passwordTxt);

}

  // const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault(); 

    const enteredEmail = emailInputRef.current.value;
    // const enteredPassword = passwordInputRef.current.value;

    // optional: Add validation
    setIsLoading(true);
    let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAB9on5ydaw3fvbi2Cf7aJp8g7v6acFmzg';

    fetch(url,
        {
          method: 'POST',
          body: JSON.stringify({
            email: enteredEmail,
            password: pass,
            returnSecureToken: true
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        setIsLoading(false);
        if (res.ok) {
          return res.json()
        } else {
          return res.json().then((data) => {
            // show an error modal
            let errorMessage = 'Account creation failed!';
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
            } 
            throw new Error(errorMessage);
          });
        }
      }).then(data => {
        setDoc(doc(db, 'superUsers', data.localId), {
          email: data.email,
          user_id: data.localId
        })
        history.push('/edit-user', {user_id: data.localId, email: data.email});
      }).catch(err => {
        alert(err.message);
      });

  };

  return (
    <section className={classes.auth}>
      <h1>{'Create New User Account'}</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor='email'>User Email</label>
          <input type='email' id='email' required ref={emailInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor='password'>User Password</label>
          <div>
            <input style={{width: '50%'}} type='password' id='password' required value={pass} onChange={e => setPass(e.target.value)}/>
            <button style={{width: '40%', marginLeft: '2%', color: '#13A3DC', backgroundColor: '#fff' }}type='button' onClick={generatePassword}>generate Password</button>
          </div>
        </div>
        <div className={classes.actions}>
          {!isLoading && <button>{'Create Account'}</button>}
          {isLoading && <p>Sending request...</p>}
          
        </div>
      </form>
    </section>
  );
};

export default CreateUserAccountForm;
