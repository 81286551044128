import React, {useState, useEffect, useContext} from 'react';  
import _ from 'lodash';

import { Field } from 'formik';
import { appColors } from '../../utils/colors';
import DataContext from '../../store/data-context';

const DisplayCosts = (props) => {

    const {rootData, modelMetadata, initialValues, values, errors, validate, handleChange, calculated, allValuesBeforeSent, checkValuesDifference, checkIfScenarioIsSaved} = props;

    const dataCtx = useContext(DataContext);

    let displayCostsHeaders;
    let displayCosts;

    const [DATA, setDATA] = useState([]);

    useEffect(() => {
        setDATA(values)
    }, [values])

    if (initialValues) {

        if (initialValues && initialValues.input_costs && modelMetadata && modelMetadata.columns_order_costs && values && values.input_costs) {
            // const costsHeaders = Object.keys(initialValues.input_costs[0]);
            const costsHeaders = modelMetadata.columns_order_costs;
            displayCostsHeaders = costsHeaders.map(h => {
                if (h.toLowerCase() === 'month') {
                    return <th style={{textTransform: 'capitalize'}} key={`header-costs_${h}`}>{h}</th>
                } else {
                    return <th key={`header-costs_${h}`}>{h}</th>
                }
            
        }
        )
            displayCosts = initialValues.input_costs.map((row, rowIndex) => {
            
            return (
                <tr key={`costs_${rowIndex}`}>
                    {costsHeaders.map(columnHeader  => {
                    
                    const iniValue = initialValues.input_costs[rowIndex][columnHeader];
                    const value = values.input_costs[rowIndex][columnHeader];

                    let styling = {border: `1px solid ${appColors.mainAccentColor}`, backgroundColor: '#fff'};
                    let inputStyling = {color: appColors.mainAccentColor};
                    
                    if  (value != iniValue) {
                        styling = {backgroundColor: appColors.changedCellColor};
                        inputStyling={color: '#fff'};
                        // console.log(value);
                        // console.log(iniValue);
                    } else {
                        styling = {backgroundColor: '#fff', border: `1px solid ${appColors.mainAccentColor}`}
                    }

                    const onBlurHandler = (e, inputValue) => {
                        if (typeof value === 'string' && /^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(values.input_costs[rowIndex][columnHeader])) {
                            values.input_costs[rowIndex][columnHeader] = +(values.input_costs[rowIndex][columnHeader].replace(',', '.'));   
                        }

                        e.target.value = inputValue.toLocaleString('pl-PL', {maximumFractionDigits: 2, useGrouping: true});

                        const compareWithInitialData = _.isEqual(initialValues, values);

                        if (compareWithInitialData) {
                            checkIfScenarioIsSaved(true);
                            dataCtx.scenarioSavedHandler(true);
                        } else {
                            checkIfScenarioIsSaved(false);
                            dataCtx.scenarioSavedHandler(false);
                        }


                    }   
                    
                    const onKeyUpChangesHandler = () => {
                        const compareWithInitialData = _.isEqual(initialValues, values);
                        const compareWithCalculatedData = _.isEqual(allValuesBeforeSent, values);
                        // console.log(compareWithInitialData);
                        // console.log(compareWithCalculatedData);

                        // }
                        if (!calculated && !compareWithInitialData) {
                            checkValuesDifference(true);
                        } else if (calculated && !compareWithCalculatedData) {
                            checkValuesDifference(true);
                        } else {
                            checkValuesDifference(false)
                        }

                    }

                    const onFocusHandler = (e, inputValue) => {
                        e.target.value = inputValue.toLocaleString('pl-PL', {useGrouping: false, maximumFractionDigits: 10})

                        // inputValue = values.input_costs[rowIndex][columnHeader].toLocaleString('fr-FR', {maximumFractionDigits: 2, useGrouping: false})

                    }

                    let errorExists = false;
                    let errorMessage = ''
                    const checkErrorExistence = async () => {
                        if (errors && typeof errors === 'object' && Object.keys(errors).length > 0 &&
                            errors.hasOwnProperty('input_costs') && 
                            errors.input_costs.length >= rowIndex + 1 &&
                            errors.input_costs[rowIndex].hasOwnProperty(columnHeader)
                        ) {
                            errorExists = true;
                            if (values.input_costs[rowIndex][columnHeader] === '') {
                                errorMessage = 'Required' 
                            } else if (!/^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(values.input_costs[rowIndex][columnHeader])) {
                                errorMessage = 'Invalid number'
                            } 

                        } else {
                            errorExists = false;
                        }
                    }
                    checkErrorExistence()
                        if (columnHeader.toLowerCase() === 'month') {
                            return (
                                <th key={`input_costs[${rowIndex}].${columnHeader}`} style={{backgroundColor: appColors.bgGray}}>{row[columnHeader]}</th>
                            );
                        } else {
                            return (
                                <td key={`input_costs[${rowIndex}].${columnHeader}`} style={errorExists ? {backgroundColor: appColors.errorCell} : styling}>
                                    {
                                        <>
                                    <Field  id={`input_costs[${rowIndex}].${columnHeader}`} name={`input_costs[${rowIndex}].${columnHeader}`} 
                                        // value={values.input_costs[rowIndex][columnHeader].toLocaleString('fr-FR', {maximumFractionDigits: 2})} 
                                        style={inputStyling}
                                        value={values.input_costs[rowIndex][columnHeader].toLocaleString('pl-PL', {maximumFractionDigits: 2, useGrouping: true})} 
                                        onChange={handleChange}
                                        validate={validate}
                                        onKeyUp={() => onKeyUpChangesHandler()}
                                        onFocus={(e) => onFocusHandler(e, values.input_costs[rowIndex][columnHeader])}
                                        onBlur={(e) => onBlurHandler(e, values.input_costs[rowIndex][columnHeader])}
                                    />
                                    {errors && errorExists && <div style={{color: '#fff', fontWeight: 'normal' }}>{errorMessage}</div>}
                                    </>
                                    } 
                                </td>
                            )
                        }
            })}        
                </tr>
            )
        })
        }
       
        

    }

    return (
        <>
            {/* <h2>Costs</h2> */}
            <table className='first-table'>
                <thead>
                    <tr>{displayCostsHeaders}</tr>
                </thead>
                <tbody>
                    {displayCosts}
                </tbody>
            </table>
        </>        

    )

}

export default DisplayCosts;

