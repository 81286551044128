import { useContext, useEffect, useState } from 'react';
import { collection, where, query, onSnapshot } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';
import AuthContext from '../../store/auth-context';



export const useGetAllScenarios = (props) => {
    
    const {projectId, modelId, noData, dataFetchedChangeState } = props;
    const [scenarios, setScenarios] = useState();
// console.log(noData);
    const user = authentication.currentUser;
    let user_id;
    if (user !== null) {
        user_id = user.uid;
    }

    const authCtx = useContext(AuthContext);

useEffect(() => { 
    // console.log('useEffect from useGetAll');
    if(projectId && authCtx.currentUserId) {
        // console.log('No location Data');
        let  myQuery;
        if (authCtx.currentUserProfile.is_admin) {
            myQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'));
        } else {
            myQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('visibility_owner', 'in', ['public', authCtx.currentUserId]));
        }
        onSnapshot(myQuery, (querySnapshot) => {
            const allData = [];
            querySnapshot.forEach((doc) => {
                allData.push({...doc.data(), id: doc.id});
            });
            // console.log(allData);
            setScenarios(allData);
            dataFetchedChangeState()
          }, (error) => {
            console.log('error from useGetAllScenarios: ', error)
            console.log(error);
          });
    }   
}, [])

return scenarios;
}; 

