import { useContext, useEffect, useState } from 'react';
import { collection, query, onSnapshot, where } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';
import AuthContext from '../../store/auth-context';



export const useGetPrivateScenarios = (props) => {
    const {currentProject, selectedModel} = props;
    const [scenarios, setScenarios] = useState([]);
    
    const user = authentication.currentUser;
    let user_id;
    if (user !== null) {
        user_id = user.uid;
    }
    
    const authCtx = useContext(AuthContext);


useEffect(() => { 
    if(!authCtx.currentUserProfile.is_admin && user_id && currentProject && selectedModel) {
        const myQuery = query(collection(db, "projects", currentProject, 'models', selectedModel, 'scenarios'), where('visibility', '==', 'private'), where('owner_id', '==', user_id));
        onSnapshot(myQuery, (querySnapshot) => {
            const allData = [];
            querySnapshot.forEach((doc) => {
                allData.push({...doc.data(), id: doc.id});
            });
            setScenarios(allData);
          }, (error) => {
            console.log('error from useGetPrivateScenarios: ', error)
            console.log(error);
          });
    }   
}, [user_id, currentProject, selectedModel])

return scenarios;
}; 

