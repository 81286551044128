import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../store/auth-context';
import { doc, setDoc } from "firebase/firestore";

import { db } from '../../../firebase/firebase';

import classes from '../../Auth/AuthForm.module.css';
import { appColors } from '../../../utils/colors';
import { useGetAllProjects } from '../../data/useGetAllProjects';

const CreateUserAccount = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const allProjects = useGetAllProjects();
  console.log(allProjects);

  const [selectedProjects, setSelectedProjects] = useState({});

  //REFS
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();

//   let selectedProjects = {}

  const handleSelectedProjects = (e, projectData) => {
    // console.log(e);
    // console.log(projectData);
    if (e.target.checked) {
        selectedProjects[projectData.project_id] = {project_id: projectData.id, role: 'user'}
    } else {
        delete selectedProjects[projectData.id]
    }
    // console.log(selectedProjects);
  }

// useEffect(() => {
// //  console.log(selectedProjects);
// }, [selectedProjects])

  const [pass, setPass] = useState('');

  useEffect(() => {
    if (allProjects) {
            }
  }, [allProjects])

  const generatePassword = () => {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_-+=";
    const length = 8;
    let passwordTxt = '';

    for (let i = 0; i < length; i++) {
        passwordTxt += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    setPass(passwordTxt);

}

  // const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredEmail = emailInputRef.current.value;
    const enteredfirstName = firstNameInputRef.current.value;
    const enteredLastName = lastNameInputRef.current.value;
    // const enteredPassword = passwordInputRef.current.value;

    // optional: Add validation
    setIsLoading(true);
    let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAB9on5ydaw3fvbi2Cf7aJp8g7v6acFmzg';

    fetch(url,
        {
          method: 'POST',
          body: JSON.stringify({
            email: enteredEmail,
            password: pass,
            returnSecureToken: true
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        setIsLoading(false);
        if (res.ok) {
          return res.json()
        } else {
          return res.json().then((data) => {
            // show an error modal
            let errorMessage = 'Account creation failed!';
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
            } 
            throw new Error(errorMessage);
          });
        }
      }).then(data => {
        setDoc(doc(db, 'users', data.localId), {
          email: data.email,
          id: data.localId,
          first_name: enteredfirstName,
          last_name: enteredLastName,
          is_admin: false,
          name: `${enteredfirstName} ${enteredLastName}`,
          roles: selectedProjects


        })

        
        history.push(`/admin/edit-user/${data.localId}`, {user_id: data.localId, email: data.email, 
            userData: {
            id: data.localId, 
            email: data.email, 
            first_name: enteredfirstName,
            last_name: enteredLastName,
            is_admin: false,
            name: `${enteredfirstName} ${enteredLastName}`,
            roles: selectedProjects}});
        // history.push('/add-users-profile', {user_id: data.localId, email: data.email});
      }).catch(err => {
        alert(err.message);
      });

  };

  return (
    <div className='app-main'> 

    <section className={classes.auth}>


      <h1>{'Create New User Account'}</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor='email'>User Email</label>
          <input type='email' id='email' required ref={emailInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor='password'>User Password</label>
          <div>
            <input style={{width: '50%'}} type='password' id='password' required value={pass} onChange={e => setPass(e.target.value)}/>
            <button style={{width: '40%', marginLeft: '2%', color: appColors.mainAccentColor, backgroundColor: '#fff', border: `1px solid ${appColors.mainAccentColor}`, borderRadius: '3px' }} type='button' onClick={generatePassword}>generate Password</button>
          </div>
        </div>

        <hr/>
        <h3>User's data</h3>

        <div className={classes.control}>
          <label htmlFor='firstname-input'>First name</label>
          <input type='text' id='firstname-input' required ref={firstNameInputRef} />
        </div>

        <div className={classes.control}>
          <label htmlFor='email'>Last name</label>
          <input type='text' id='lastName-input' required ref={lastNameInputRef} />
        </div>

        <hr/>
        <h3>User's access</h3>
        {allProjects && allProjects.length > 0 &&
            allProjects.map((project, i) => {
                return (
                    <div>
                    <input type='checkbox' id={i} onChange={(e) => handleSelectedProjects(e, project)} />
                    <label htmlFor={i}>{project.name} ({project.id})</label>
                    </div>
                )
            } )
           
        } 

        {/* <div className={classes.control}>
          <label htmlFor='firstname-input'>First name</label>
          <input type='text' id='firstname-input' required ref={firstNameInputRef} />
        </div>

        <div className={classes.control}>
          <label htmlFor='email'>Last name</label>
          <input type='text' id='lastName-input' required ref={lastNameInputRef} />
        </div> */}
        


        <div className={classes.actions}>
          {!isLoading && <button>{'Create Account'}</button>}
          {isLoading && <p>Sending request...</p>}
          
        </div>
      </form>
    </section>
    </div>
  );
};

export default CreateUserAccount;
