import { useContext, useEffect, useState } from 'react';
import { collection, doc, query, where, getDoc, onSnapshot, collectionGroup } from "firebase/firestore";

import { db } from '../../firebase/firebase';
import AuthContext from '../../store/auth-context';



export const useGetModelMetadata = (props) => {
    const {projectId, modelId} = props;
    const authCtx = useContext(AuthContext);

    const [modelSettings, setModelSettings] = useState();
    const user_id = authCtx.currentUserProfile.id;
    // const user = authentication.currentUser;
    // let user_id;
    // if (user !== null) {
    //     user_id = user.uid;
    // }

    

useEffect(() => { 
    if(user_id && projectId && modelId) {
        const fetchHeaders = async () => {
            // const myQuery = query(doc(db, "projects", projectId, 'models', modelId));
            const modelRef = doc(db, "projects", projectId, 'models', modelId);
            const docSnap = await getDoc(modelRef);

                if (docSnap.exists()) {
                    setModelSettings({...docSnap.data(), id: docSnap.id })
                } else {
                    console.log('no such doc');
                }
    }
        
          
    fetchHeaders()




        // const myQuery = query(doc(db, "projects", projectId, 'models', modelId));
        // onSnapshot(myQuery, (querySnapshot) => {
        //     const allHeaders = [];
        //     querySnapshot.forEach((doc) => {
        //         allHeaders.push({...doc.data()});
        //     });
        //     console.log(allHeaders)
        //     setHeaders(allHeaders);

        //   });

    }
     
}, [user_id, projectId, modelId])

return modelSettings;
}; 

