import React, { useContext, useEffect, useState } from 'react';

import { Link, NavLink, useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

import logo from '../../assets/images/groupone_logo.svg'
// import CalculatorIcon from '../../assets/icons/calculator-icon.svg'
import {calculatorIcon, logoutIcon} from '../../assets/icons/Icons'
import {calculatorIconColor} from '../../assets/icons/Icons'

import './MainNavigation.css';
import HeaderUserProfile from './HeaderUserProfile';
import DataContext from '../../store/data-context';
import ConfirmModalTemplate from '../modals/ConfirmModalTemplate';

import { modalTexts } from '../../utils/translate';

const MainNavigation = (props) => {

    const authCtx = useContext(AuthContext);
    const dataCtx = useContext(DataContext);
    const history = useHistory();
    const isLoggedIn = authCtx.isLoggedIn;
    const isAdmin = authCtx.currentUserProfile.is_admin;

    const [url, setUrl] = useState('/');
    const [passedObj, setPassedObj] = useState({})


    const [showAlert, setShowAlert] = useState(false);
    const handleAlertClose = () => setShowAlert(false)

    const [navOpen, setNavOpen] = useState();


    const onLogout = () => {
        // authCtx.isLoggedIn = false;
        dataCtx.scenarioSavedHandler(true);
        history.push('/');
        authCtx.logout()
    }

    const handleRedirection = (path, obj) => {
        setUrl(path)
        setPassedObj(obj)
        if (dataCtx.scenarioSaved) {
            history.push(path, obj)
        } else {
           setShowAlert(true);
        }
    }
    const handleDirectRedirection = () => {
        setShowAlert(false);
        dataCtx.scenarioSavedHandler(true);
        history.push(url, passedObj)
        setUrl('')
        setPassedObj({})

    }
    
    return (
        <>
       
        <nav className={navOpen === undefined ? 'App-nav' : navOpen === false ? 'App-nav App-nav-animation-closing' : 'App-nav App-nav-animation-opening'} >
        <img src={logo} className="logo" alt="logo" onClick={() => handleRedirection('/projects')} />
        {/* <button onClick={() => setNavOpen(!navOpen)}>{navOpen === undefined ? 'show nav' : navOpen === false ? 'show nav' : 'hide nav'}</button> */}
             
            <ul className='main-nav' style={{display: 'inherit', gap: '20px'}}>
                {/* <li><button onClick={() => setNavOpen(!navOpen)}>{navOpen ? 'hide nav' : 'show nav'}</button></li> */}
                {/* {!isLoggedIn && <li>
                    <Link to='/auth'>Login</Link>
                </li>} */}
                {/* {isLoggedIn && <li>
                    <Link to='/create-user'>Create User</Link>
                </li>} */}
                {/* {<li>
                    <Link to='/'>Home</Link>
                </li>} */}
                {/* {isLoggedIn && isAdmin && <li>
                    <Link to='/create-user'>Create User</Link>
                </li>} */}
                
                {isLoggedIn &&
                    <li>
                    <div className='link' onClick={() => handleRedirection('/projects')}>Projects</div>
                </li>}
                {isLoggedIn && isAdmin &&<li>
                    {/* <Link to='/admin'>Admin links</Link> */}
                    <div className='link' onClick={() => handleRedirection('/admin')}>Admin links</div>

                </li>}
                {/* {isLoggedIn && <li>
                    <NavLink to='/calculator'>
                        <div className='calc-icon'></div>
                       Calculator
                    </NavLink>
                </li>} */}
                <li>

                    {/* {isLoggedIn && <div type='button' onClick={onLogout}>{logoutIcon}</div>} */}
                    {!isLoggedIn && <button type='button' onClick={() => history.push('/login')}>LOG IN</button>}
                </li>
            </ul>
            <div 
            // style={{position: 'absolute', bottom: '50px', textAlign: 'center'}}
            >
                {isLoggedIn && <div title='LOG OUT' type='button' style={{cursor: 'pointer'}} onClick={onLogout} className='logout-icon'></div>}
                <HeaderUserProfile onLogout={onLogout} handleRedirection={handleRedirection}/>
            </div>
        </nav>
        <ConfirmModalTemplate 
            open={showAlert}
            handleClose={handleAlertClose}
            actions={[handleDirectRedirection, handleAlertClose]}
            modalTitle={modalTexts.scenarioNotSaved.title[dataCtx.lang]} 
            content={modalTexts.scenarioNotSaved.content[dataCtx.lang]} 

        />

        </>
    );
}

export default MainNavigation;