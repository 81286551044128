import React, { useMemo } from 'react';

// import '../components/userView/CustomTable.css';

import {matchSorter} from 'match-sorter';


const changeMinutes = (dt, minutes) => {
    return new Date(dt.getTime() - minutes*60000)
}

export const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
};

export const dateMinFilter = (rows, id, filterValues)=> {
    const startDate = filterValues[0] ? new Date(filterValues[0]) : undefined

    if (startDate) {
      return rows.filter(r => {
        const cellDate = new Date(r.values[id]);
        const timezoneDifference = cellDate.getTimezoneOffset();
        const fDate = changeMinutes(cellDate, timezoneDifference);

        return fDate >= startDate;
      })
    } else {
      return rows
    }
};

export const dateMaxFilter = (rows, id, filterValues)=> {
    const endDate = filterValues[0] ? new Date(filterValues[0]) : undefined

    if (endDate) {
        return rows.filter(r => {
        const cellDate = new Date(r.values[id]);
        const timezoneDifference = cellDate.getTimezoneOffset();
        const fDate = changeMinutes(cellDate, timezoneDifference);

        return fDate <= endDate;
        })
    } else {
        return rows;
    }
};


export const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  })  => {
    const count = preFilteredRows.length;
  
    return (
      <input
        value={filterValue || ''}
        style={{maxWidth: '90%'}}
        className='search-input'
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} scenarios...`}
      />
    )
  }

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])
  
    // Render a multi-select box
    return (
      <select
        style={{maxWidth: '100%'}}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
};

  export const DateColumnFilter = ({
    column: {
      filterValue = [],
      preFilteredRows,
      setFilter,
      id
    }}) =>
  {
//     const [min, max] = useMemo(() => {
//       let min = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)
//       let max = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)

// //   const changeMinutes = (dt, minutes) => {
// //     return new Date(dt.getTime() + minutes*60000)
// //   }
//   let dates = []
//       preFilteredRows.forEach(row => {
//         const rowDate = new Date(row.values[id])
//         // const timezoneDifference = rowDate.getTimezoneOffset();
        
        
//         // const formDate = changeMinutes(rowDate, timezoneDifference);

//         dates.push(rowDate);
        

//         // console.log(rowDate);
//         // console.log(formDate);

//         // min = rowDate <= min ? rowDate : min
//         // max = rowDate >= max ? rowDate : max
//         min = Math.min(...dates);
//         const maxDate = Math.max(...dates);
//         max = new Date(maxDate)
//         return dates;
     
//       })
//       min = new Date(Math.min(...dates))
//       max = new Date(Math.max(...dates))

//       return [min, max]
//     }, [id, preFilteredRows])
  
    // const minDay = min.getDate();
    // const minMonth = min.getMonth()+1 < 10 ? '0'+ min.getMonth()+1 : min.getMonth()+1;
    // const minYear = min.getFullYear()
    return (
      <div className="date-input-wrapper">
        <input
          // min={`${minYear}-${minMonth}-${minDay}`}
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? val : undefined, old[1]])
          }}
          type="date"
          value={filterValue[0] || ''}
          className='date date-input'
        />
      </div>
    )
  };