import React, {useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { doc, deleteDoc, updateDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import _ from 'lodash';
// import ReactExport from 'react-export-excel';

import AuthContext from '../../store/auth-context';
import { useGetModels } from '../data/useGetModels';
import { useGetPrivateScenarios } from '../data/useGetPrivateScenarios';
import { useGetProjectSettings } from '../data/useGetProjectSettings';
import { useGetPublicScenarios } from '../data/useGetPublicScenarios';
import { useGetModelMetadata } from '../data/useGetModelMetadata';

import './ProjectView.css';
import { db } from '../../firebase/firebase';
import EditModal from '../modals/EditModal';
import ConfirmModal from '../modals/ConfirmModal';

import { buttonTexts, modalTexts, appTexts } from '../../utils/translate';
import { appColors } from '../../utils/colors';
import Card from '../UI/Card';
import { chooseModelIcon, deleteIcon, downloadIcon, editIcon, openIcon, openRootScenarioIcon } from '../../assets/icons/Icons';
import DataContext from '../../store/data-context';
import ScenariosTable from './ScenariosTable';
import { ActionButton } from '../UI/ActionButton';

import chooseModelImg from '../../assets/images/chooseModelIcon.png'
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ProjectView = (props) => {
    const authCtx = useContext(AuthContext);
    const dataCtx = useContext(DataContext);
    const location = useLocation();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false);

    const [selectedModel, setSelectedModel] = useState('');
    const [selectedModelName, setSelectedModelName] = useState('');

    let currentProject;
    let currentProjectName;
    if (location.state && location.state.projectId) {
        currentProject = location.state.projectId;
    } else if (!location.state) {
        currentProject = props.match.params.projectId;
    }

    if (location && location.state && location.state.projectName) {
        currentProjectName = location.state.projectName;
    }
        
    useEffect(() => {
        if (location.state) {
            if (location.state.modelId) {setSelectedModel(location.state.modelId)}
            if (location.state.modelName) {setSelectedModelName(location.state.modelName)}
        }

        
    }, [])

    const currentModels = useGetModels({currentProject});
    const currentProjectSettings = useGetProjectSettings({currentProject})
    const privateScenarios = useGetPrivateScenarios({currentProject, selectedModel});
    const publicScenarios = useGetPublicScenarios({currentProject, selectedModel});

    if (currentModels) {
// console.log(currentModels);
    }


    const modelMetadata = useGetModelMetadata({projectId: currentProject, modelId: selectedModel});

    // Edit Scenario (name & visibility)
    const [currentScenarioId, setCurrentScenarioId] = useState();
    const [currentScenarioName, setCurrentScenarioName] = useState();
    const [currentScenarioVisibility, setCurrentScenarioVisibility] = useState();
    const [currentScenarioOwnerId, setCurrentScenarioOwnerId] = useState();
    const handleEditScenario = async (e, scenarioId, scenarioName, visibility) => {
        // alert(`To edit: ${scenarioId}`)
        const publicScenariosQuery = query(collection(db, 'projects', currentProject, 'models', selectedModel, 'scenarios'), where('scenario_name', '==', scenarioName), where('visibility', '==', 'public'));
        const privateScenariosQuery = query(collection(db, 'projects', currentProject, 'models', selectedModel, 'scenarios'), where('scenario_name', '==', scenarioName), where('visibility', '==', 'private'), where('owner_id', '==', authCtx.currentUserId));
        const publicQuerySnapshot = await getDocs(publicScenariosQuery)
        const privateQuerySnapshot = await getDocs(privateScenariosQuery)
        const scenarioRef = doc(db, 'projects', currentProject, 'models', selectedModel, 'scenarios', scenarioId);
        const scenarios = [];
        publicQuerySnapshot.forEach((doc) => {
            scenarios.push({...doc.data()});
        }
        );
        privateQuerySnapshot.forEach((doc) => {
            scenarios.push({...doc.data()});
        }
        );
        
        if (scenarios.length > 0 && scenarios[0].id !== scenarioId) {
            alert('Scenario with this name already exists, please introduce different name')
        } else {
            await updateDoc(scenarioRef, {visibility: visibility, scenario_name: scenarioName, visibility_owner: visibility ===  undefined ? currentScenarioOwnerId : visibility === 'public' ? 'public' : currentScenarioOwnerId })
            .then(() => setEditModalOpen(false))
        }
        
       
    }

    // Edit visibility modal:
    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleEditModalOpen = (e, scenarioId, scenarioName, visibility, ownerId) => {
        setCurrentScenarioId(scenarioId);
        setCurrentScenarioName(scenarioName)
        setCurrentScenarioVisibility(visibility);
        setCurrentScenarioOwnerId(ownerId);
        setEditModalOpen(true);
    };
    const handleEditModalClose = () => setEditModalOpen(false);

    const handleOpen = (e, scenario) => {
        setButtonClicked(true)
        history.push(`/calculator/${currentProject}/${selectedModel}/${scenario.id}`, {scenario, currentProject, currentProjectName, allScenarios, currentModels, selectedModelName, buttonClicked})
    }

    const handleDownload = (e, scenario) => {
        alert(`Download: ${scenario.id} `)
    }

    // Delete scenario
    const handleDeleteScenario = async (e, scenarioId) => {
        
        const scenarioRef = doc(db, 'projects', currentProject, 'models', selectedModel, 'scenarios', scenarioId);
        await deleteDoc(scenarioRef);
        setDeleteModalOpen(false);
    }
    // Delete modal:
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const handleDeleteModalOpen = (e, scenarioId, scenarioName) => {
        setCurrentScenarioId(scenarioId);
        setCurrentScenarioName(scenarioName);
        setDeleteModalOpen(true);
    }
    const handleDeleteModalClose = () => setDeleteModalOpen(false);


    // if (currentModels) {
    //     console.log(currentModels);
    // }

    // if (currentProjectSettings) {
    //     console.log(currentProjectSettings);
    // }

    
    let currentModelsSuperUsers;

    if (currentProjectSettings) {
        // currentModelsSuperUsers = currentProjectSettings.super_users.map((user, userIndex) => <li key={`superUser-${userIndex}`}>{user}</li>)
    }

    const modelSelectRef = useRef();

    const handleModelSelection = (e) => {
        e.preventDefault();
        setSelectedModel(modelSelectRef.current.value);
        dataCtx.modelHandler(modelSelectRef.current.value);
        const selectedModel = currentModels.find(model => model.id === modelSelectRef.current.value)
         setSelectedModelName(selectedModel.model_name)  

    
        


    }

    // console.log(currentModels);
    
    let modelSelection;
    if (currentModels) {
        const sortedModels = _.sortBy(currentModels, model => model.model_name)
        const publishedModels = sortedModels.filter(pm => pm.is_published === true)
        // console.log(publishedModels);
        if (authCtx.currentUserProfile.is_admin) {
            modelSelection = sortedModels.map(modelId => <option defaultValue={selectedModelName} key={`option-${modelId.id}`} value={modelId.id} style={modelId.is_published ? {color: 'green'} : {color: 'red'}}>{modelId.model_name}</option>)
        } else {
            modelSelection = publishedModels.map(modelId => <option key={`option-${modelId.id}`} value={modelId.id}>{modelId.model_name}</option>)

        }
    }

    let currentModelsDisplay;
    let currentModelNotes;
    if (currentModels && selectedModel !== '') {
        // currentModelsDisplay = currentModels.map((model, modelIndex) => {
        // return (
        //     <>
        //         <div key={model.id} style={{textAlign: 'left', margin: '40px'}}>
        //             <h3> MODEL ID: {model.id}</h3>
        //             <p>Authorized Users:</p>
        //             <ul>
        //                 {model.authorized_users.map((user, userIndex) => <li key={`auth-user-${userIndex}`}>{user}</li>)}
        //             </ul>
        //         </div>
        //     </>
        
        // )
        // })
        const currentModel = currentModels.find(model => model.model_id === selectedModel);
        currentModelNotes = <div className='admin-notes'><b>NOTES (visible by admins only):</b><br/>{currentModel.admin_notes}</div>
    }

        let allScenarios = [] 
    if (publicScenarios && privateScenarios) {
        allScenarios = [...publicScenarios, ...privateScenarios]
    }

    let scenariosDisplay;
    let sortedScenarios;
    if (allScenarios) {
        const allButRootScenarios = allScenarios.filter(scenario => scenario.id !== 'root')
        sortedScenarios = _.sortBy(allScenarios, scenario => scenario.scenario_name)
        scenariosDisplay = sortedScenarios.map(scenario => {
            const timestampSec =  scenario.updated_at.seconds;
            const date = new Date(timestampSec*1000);
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() < 9 ? `0${date.getMonth()+1}` : date.getMonth()+1;

            return (
                <tr key={scenario.id} style={{backgroundColor: 'white'}}>
                    {/* <input type='checkbox' style={{position: 'relative'}}/> */}
                    <td style={{padding: '10px 20px'}}>{scenario.scenario_name}</td>
                    <td style={{padding: '10px 20px'}}>{scenario.owner_name}</td>
                    <td style={{padding: '10px 20px'}}>{`${day}.${month}.${date.getFullYear()}`}</td>
                    <td style={{padding: '10px 20px'}}>{scenario.visibility}</td>
                    <td style={{padding: '10px 20px', minWidth: '200px', display: 'flex'}}>
                        <button className='list-btn square-button' onClick={(e) => handleEditModalOpen(e, scenario.id, scenario.scenario_name, scenario.visibility, scenario.owner_id)} style={scenario.id === 'root' ? {visibility: 'hidden'} : {visibility: 'inherit'}} disabled={authCtx.currentUserProfile.is_admin ? false : !authCtx.is_admin && (authCtx.currentUserId !== scenario.owner_id || scenario.id === 'root') ? true : false} title="Edit model">{editIcon}</button>
                        <button className='list-btn rectangle-button accent-color-button' onClick={(e) => handleOpen(e, scenario)} style={{color: '#fff' }} title={buttonTexts.open[dataCtx.lang]}>{openIcon}<div>{buttonTexts.open[dataCtx.lang]}</div> </button>
                        <button className='list-btn square-button adj-display' title='Download' disabled={true}  onClick={(e) => handleDownload(e, scenario)}>{downloadIcon}</button>
                        <button className='list-btn square-button' onClick={(e) => handleDeleteModalOpen(e, scenario.id, scenario.scenario_name)} style={scenario.id === 'root' ? {visibility: 'hidden'} : {visibility: 'inherit'}} disabled={authCtx.currentUserProfile.is_admin ? false : !authCtx.is_admin && (authCtx.currentUserId !== scenario.owner_id || scenario.id === 'root') ? true : false} title='Delete'>{deleteIcon}</button>
                        {/* <ExportToExcel data={scenario} modelMetadata={modelMetadata} fileName={scenario.scenario_name}/> */}
                    </td>
                </tr>
            
            );
        } )
    }
    let rootScenario;
    if (allScenarios) {
        rootScenario = allScenarios.find(scenario => scenario.id === 'root')
    }


    // let publicScenariosDisplay;
    // if (publicScenarios) {
    //     publicScenariosDisplay = publicScenarios.map(pubS => {

    //         return (
    //             <tr key={`scen-list-${pubS.id}`}>
    //                 <td>{pubS.id}</td>
    //                 <td>{pubS.owner_name}</td>
    //                 <td> date</td>
    //                 <td></td>
    //             </tr>
            
    //         );
    //     } )
    // }    

    // let privateScenariosDisplay;
    // if (privateScenarios) {
    //     privateScenariosDisplay = privateScenarios.map(privSc => {
    //         return (
    //             <tr key={`scen-list-${privSc.id}`}>
    //                 <td>{privSc.id}</td>
    //                 <td>{privSc.owner_name}</td>
    //                 <td></td>
    //                 <td></td>
    //             </tr>
            
    //         );
    //     } )
    // }


    return (
        <div className='app-main'>
            {/* <h1>Project View</h1> */}
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
            <Card>
                <p>CURRENT PROJECT: <b style={{color: appColors.mainAccentColor, marginLeft: '6px'}}>{currentProjectName}</b></p>
            </Card>
            </div>
            <div className='main-content-header'>
               
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sollicitudin pulvinar nisl eu posuere. Quisque ut scelerisque nisi. Etiam feugiat nisl ligula, eu consectetur augue.</p> */}
            </div>
            
            {/* <p>Authorized Super Users:</p>
             <ul>
                {currentModelsSuperUsers}
            </ul> */}
          
            <div style={{textAlign: 'left', minHeight: '2.5rem', marginTop: '40px'}}>
                 {selectedModel !== '' && <h4>{appTexts.projectPage.choose[dataCtx.lang]}</h4>}
            </div>
            
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className=''>
            
                <Card>
                    <img style={{marginRight: '10px'}} src={chooseModelImg}/>
                    <select name="model-select" id="model-select" ref={modelSelectRef} onChange={handleModelSelection}>
                        <option value="" defaultValue hidden>{selectedModelName ? selectedModelName : appTexts.projectPage.choose[dataCtx.lang]}</option>
                        {modelSelection}
                    </select>
                {selectedModel !== '' && allScenarios.length > 0 && <button style={{color: '#fff', padding: '10px', width: 'auto', display: 'inline'}} className='list-btn rectangle-button accent-color-button' type='button' onClick={(e) => handleOpen(e, rootScenario)}>Open root scenario</button>}
                {authCtx.currentUserProfile.is_admin && selectedModel !== '' && <button className='list-btn square-button' type='button' onClick={(e) => history.push(`/manage-project/${currentProject}/manage-model/${selectedModel}`, {currentModels, selectedModelName, projectName: currentProjectName})} title="Edytuj Model">{editIcon}</button>}
                </Card>
                {/* {authCtx.currentUserProfile.is_admin && selectedModel !== '' && <button className='list-btn square-button' type='button' onClick={(e) => history.goBack()} title="Edytuj Model">back</button>} */}
            
                {/* <button className='list-btn square-button' onClick={(e) => handleEditModalOpen(e, scenario.id, scenario.scenario_name, scenario.visibility)} style={scenario.id === 'root' ? {visibility: 'hidden'} : {visibility: 'inherit'}} disabled={authCtx.currentUserProfile.is_admin ? false : !authCtx.is_admin && (authCtx.currentUserId !== scenario.owner_id || scenario.id === 'root') ? true : false} title="Edytuj">{editIcon}</button> */}
<div style={{display: 'flex', justifyContent: 'flex-end'}}>
                {selectedModel !== '' && allScenarios.length > 0 && <ActionButton type='button' clicked={(e) => handleOpen(e, rootScenario)} icon={openRootScenarioIcon} text='OPEN ROOT SCENARIO'  title='OPEN ROOT SCENARIO' />}
            </div>
            </div>
            

            {/* <hr/> */}
            {/* {currentModelsDisplay} */}
            {/* <hr/> */}
            <div style={{position: 'relative'}}>
                {authCtx.currentUserProfile.is_admin && selectedModel !== '' && 
                <>
                
                {currentModelNotes}
                </>}

            </div>
            <div style={{
                textAlign: 'center', marginTop: '40px'
                // marginLeft: '-40px', 
                // marginRight: '-40px'
                }}>
                                <h2 style={{textTransform: 'uppercase'}}>{appTexts.scenario.plural[dataCtx.lang]}</h2>

            
            {/* <div style={{padding: '15px', backgroundColor: '#f5f5f5', width: '100%'}}>
            <table className='custom-table' style={{borderSpacing: '0 10px', margin: '0 auto', borderCollapse: 'separate', backgroundColor: '#f5f5f5'}} >
                <thead>
                    <tr>
                       <th>Nazwa scenariusza</th> 
                       <th>Autor</th> 
                       <th>Ostatni zapis</th> 
                       <th>Status</th> 
                       <th style={{minWidth: '200px'}}></th> 
                    </tr>
                    
                </thead>
                <tbody>

                    {scenariosDisplay}
                </tbody>
            </table>
            </div> */}
            {/* <h3>private scenarios</h3> */}
            
            {(selectedModel === '' || (selectedModel !== '' && allScenarios.length === 1)) && <div style={{position: 'absolute', zIndex: '50', height: '300px', width: '85%'}}></div>}
            {isLoading && <div style={{position: 'absolute', zIndex: '50', height: '300px', width: '85%', paddingTop: '150px', fontSize: '24px', fontWeight: 'bold'}}>Loading...</div>}
            {selectedModel === '' && !isLoading && 
                <div style={{zIndex: '50', paddingTop: '100px', fontSize: '24px', fontWeight: 'bold'}}>
                    <div style={{backgroundColor: 'rgba(256,256,256,1)', width: '500px', margin: '0 auto', padding: '20px', boxShadow: '2px 0px 5px 0px rgb(0 0 0 / 20%)'}}>
                        {appTexts.projectPage.chooseModelBox[dataCtx.lang]}
                    </div>
                </div>
            }
            {selectedModel !== '' && allScenarios.length === 1 && 
                <div style={{zIndex: '50', paddingTop: '100px', fontSize: '24px', fontWeight: 'bold'}}>
                    <div style={{backgroundColor: 'rgba(256,256,256,1)', width: '500px', margin: '0 auto', padding: '20px', boxShadow: '2px 0px 5px 0px rgb(0 0 0 / 20%)'}}>
                        {appTexts.projectPage.noScenariosBox[dataCtx.lang]}
                    </div>
                </div>
            }
            {selectedModel && allScenarios.length > 1 && 
            <ScenariosTable 
                allScenarios={allScenarios} 
                sortedScenarios={sortedScenarios}
                rootScenario={rootScenario}
                handleEditModalOpen={handleEditModalOpen} 
                handleOpen={handleOpen} 
                handleDownload={handleDownload} 
                handleDeleteModalOpen={handleDeleteModalOpen}
                modelMetadata={modelMetadata}
            />}
            
            </div>
            <EditModal 
                scenarioId={currentScenarioId} 
                scenarioName={currentScenarioName} 
                modelId={selectedModel} 
                modelName={selectedModelName} 
                scenarioVisibility={currentScenarioVisibility} 
                scenarioOwnerId={currentScenarioOwnerId}
                open={editModalOpen} 
                handleClose={handleEditModalClose} 
                handleEditScenario={handleEditScenario} 
                modalTitle={modalTexts.scenarioDelete.title[dataCtx.lang]} 
                content={modalTexts.scenarioDelete.content[dataCtx.lang]} 
                cancelButton={buttonTexts.cancel[dataCtx.lang]} 
                confirmButton={buttonTexts.edit[dataCtx.lang]}


            />
            <ConfirmModal 
                scenarioId={currentScenarioId} 
                scenarioName={currentScenarioName}
                open={deleteModalOpen} 
                handleClose={handleDeleteModalClose} 
                handleAction={handleDeleteScenario} 
                modalTitle={modalTexts.scenarioDelete.title[dataCtx.lang]} 
                content={modalTexts.scenarioDelete.content[dataCtx.lang]} 
                cancelButton={buttonTexts.cancel[dataCtx.lang]} 
                confirmButton={buttonTexts.delete[dataCtx.lang]}
            />
    
        </div>
    )
}

export default ProjectView;