import { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

const EditUser = (props) => {

    const location = useLocation();
    const history = useHistory()

    const [currentUser, setCurrentUser] = useState({});
    const [currentProjects, setCurrentProjects] = useState([]); 

      //REFS
  const emailInputRef = useRef();
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();

    useEffect(() => {
        if (location && location.state) {
            setCurrentUser(location.state.userData)
        }

        let projects = [];

        if (location && location.state && location.state.userData && location.state.userData.roles) {
                projects = Object.keys(location.state.userData.roles);
            }

            // console.log(projects);

        
        // if (projects.length > 0) {
        //     displayProjects = projects.map(project => <div>{project}</div>)
        // }
        setCurrentProjects(projects);

    }, [location.state])
let displayProjects;
    if (currentProjects && currentProjects.length > 0) {
        displayProjects = currentProjects.map(project => <div  key={project}>{project}</div>)
    }
    
    
    useEffect(() => {
        //  let projects = [];

        // if (location && location.state && location.state.userData && location.state.userData.roles) {
        //         projects = Object.keys(location.state.userData.roles);
        //     }

        //     console.log(projects);

        
        // if (projects.length > 0) {
        //     displayProjects = projects.map(project => <div>{project}</div>)
        // }
    }, [location.state.userData.roles])
   
   

    const userId = props.match.params.userId;
    return (
        <div className="app-main">
            <h2>User Details</h2>
            {currentUser && 
                <div style={{textAlign: 'left'}}>
                    <h3>Details:</h3>
                    <div>user id: {currentUser.id}</div>
                    <div>Email: {currentUser.email}</div>
                    <div>
                        {/* <label htmlFor="firstName">First name:</label>
                        <input id='firstName' ref={firstNameInputRef} defaultValue={currentUser.first_name}/> */}
                        First name: {currentUser.first_name}
                    </div>
                    <div>                        
                        {/* <label htmlFor="lastName">Last name:</label>
                        <input id='lastName' ref={lastNameInputRef} defaultValue={currentUser.last_name}/> */}
                        Last name: {currentUser.last_name}
                    </div>
                    {/* <div>Name: {currentUser.name}</div> */}
                    <h3>Granted access to:</h3>

                    <div>
                        {displayProjects}
                    </div>
                    <br/>
                    <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => history.push('/admin/send-reset-pass-to-user', {user_id: currentUser.id, email: currentUser.email})}>GO TO: Send resetPassword page </div>
                </div>
            }
            

            
            

        </div>
    )
}

export default EditUser;