import React from 'react';
import { Box, Modal  } from '@mui/material';
import { Button } from '../UI/Button';

import classes from './Modals.module.css';
import CloseModal from './CloseModal';

const modalWidth = 400;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiInputBase-input': {
        padding: '6px 0 6px 14px'
    }
  };


const ConfirmModal = (props) => {
    const {modalTitle, content, cancelButton, confirmButton, handleClose, open, handleAction, scenarioName, scenarioId} = props;

    const handleSave = (e) => {
        e.preventDefault();
        handleAction(e, scenarioId)  
    }

    return (
 <>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <CloseModal clicked={handleClose}/>
    <h2 className={classes.header}>{modalTitle}</h2>
    <p style={{textAlign: 'center'}}>{content}</p>   
    <p style={{textAlign: 'center', fontWeight: 'bold'}}>{scenarioName}</p>   
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>       
      <Button type='button' clicked={handleClose} text={cancelButton} styling='light' />
      <Button type='button' clicked={(e) => handleSave(e)} text={confirmButton} styling='primary' />
    </div>                
  </Box>
</Modal>
</>
    )
}

export default ConfirmModal;