import React, { useRef } from 'react';
import { Typography, Box, Modal } from '@mui/material';
import { Button } from '../UI/Button';

import classes from './Modals.module.css';
import CloseModal from './CloseModal';


const modalWidth = 400;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiInputBase-input': {
        padding: '6px 0 6px 14px'
    }
  };


const ResetModal = (props) => {
    const {modalTitle, content, cancelButton, confirmButton, handleClose, open, handleAction, secondAction, doubleAction, passedFn} = props;

    const scenarioNameRef = useRef();
    const visibilitySelectRef = useRef();
    
    
    const handleSave = (e) => {
        e.preventDefault();
        const scenarioName = scenarioNameRef.current.value;
        const scenarioVisibility = visibilitySelectRef.current.value;
        // console.log(scenarioName, scenarioVisibility);

        props.handleSaveNewScenario(null, scenarioName, scenarioVisibility)
        
    }

    return (
 <>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
          <CloseModal clicked={handleClose}/>
          <h2 className={classes.header}>{modalTitle}</h2>
          <p style={{textAlign: 'center'}}>{content}</p>   
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>       
            <Button type='button' doubleAction={doubleAction} clicked={handleClose} text={cancelButton} styling='light' />
            <Button type='button' doubleAction={doubleAction} secondAction={secondAction} clicked={passedFn} text={confirmButton} styling='primary' />
        </div>        

            
  </Box>
</Modal>
</>
    )
}

export default ResetModal;