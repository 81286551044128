import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AddDocumentImg from '../../../assets/images/printScreens/addDocument.PNG';
import createProfileImg from '../../../assets/images/printScreens/addProfile.PNG';
import { appColors } from "../../../utils/colors";

const CreateUserProfile = () => {

    const location = useLocation();
    const history = useHistory();

    const [userId, setUserId] = useState('') 
    const [userEmail, setUserEmail] = useState('') 


    useEffect(() => {
        if(location.state && location.state.user_id) {
            setUserId(location.state.user_id);
        }
        if(location.state && location.state.email) {
            setUserEmail(location.state.email);
        }
    }, [location.state])


    return (
        <div className="app-main">
            <h1>Create user's profile</h1>
            <ol style={{textAlign: 'left'}}>
                <li>Otwórz <a href="https://console.firebase.google.com/project/econometric-model-app/" >Firebase Console</a>.</li>
                <li>Wybierz w menu po lewej stronie "Firestore Database".</li>
                <li>W pierwszej kolumnie wybierz na kolekcję "users".</li>
                <li>W drugiej kolumnie powinien pojawić się nagłówek "users".</li>
                <li>Tuż pod nagłówkiem kliknij w link "+ Add document".</li>
                
                <li>W wyskakującym okienku:  
                    <br/>
                    <img src={AddDocumentImg} />
                    <br/>
            <ol style={{ listStyle: 'lower-alpha'}}>
                <li> w polu "Document ID" wpisz ID usera:
                    <div style={{textAlign: 'center', fontWeight: 'bold', border: `1px solid ${appColors.mainAccentColor}`, width: '50%'}}>{userId}</div>
                </li>
                <li>
                    poniżej w polu: <br/>
                    - "Field" wpisz klucz "id"<br/>
                    - "Type" pozostaw lub wybierz "string"<br/>
                    - "Value" wklej również id usera:  <div style={{textAlign: 'center', fontWeight: 'bold', border: `1px solid ${appColors.mainAccentColor}`, width: '50%'}}>{userId}</div>
                </li>
                <li>
                    kliknij w znaczek "+" poniżej, aby dodać kolejny element
                </li>
                <li>
                    poniżej w polu: <br/>
                    - "Field" wpisz klucz "email"<br/>
                    - "Type" pozostaw lub wybierz "string"<br/>
                    - "Value" wklej email usera:
                    <div style={{textAlign: 'center', fontWeight: 'bold', border: `1px solid ${appColors.mainAccentColor}`, width: '50%'}}>{userEmail}</div>
                </li>
                <li>Skonstruuj cały profil zgodnie z poniższym przykładem:
                    <br/>

                    <img src={createProfileImg} />
                </li>  
                <li>Kliknij 'SAVE'</li>                 
            </ol>
                   

                    

                </li>
            </ol>

            <h2>DONE?</h2>
            <button onClick={() => history.push('/send-reset-pass-to-user', {user_id: userId, email: userEmail})}>CONTINUE</button>

        </div>
    )
}

export default CreateUserProfile;