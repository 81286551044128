import React, {useState} from 'react';
import { useHistory, useLocation } from 'react-router';
import { sendPasswordResetEmail } from 'firebase/auth';

import SnackBar from '../../UI/SnackBar';

import { authentication } from '../../../firebase/firebase'

const SendResetPassToUser = () => {
    const location = useLocation();
    const history = useHistory();

    let userId;
    let userEmail;

    if (location.state) {
        userId = location.state.user_id;
        userEmail = location.state.email;
    }

      // SnackBar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleOpenSnackbar = () => setOpenSnackbar(true);
  const handleCloseSnackbar = () => setOpenSnackbar(false); 

    const sendSetPasswordEmail = () => {
        return sendPasswordResetEmail(authentication, userEmail, {
            url: `https://one-budget-allocator.groupone.pl/login`,
          }).then(
            handleOpenSnackbar()
          ).catch(err => {
                alert(err.message);
          })
    }


    return (
        <div className='app-main'>
            <h1>Sending reset password email to the user</h1>
            <div>{userId}</div>
            <div>{userEmail}</div>
            <p>If the profile setup is complete and ready click the button below to send the reset password email to the user:</p>
            <button type='button' onClick={sendSetPasswordEmail}>Send Password Reset Email to <br/><b>{userEmail}</b></button>
            <div style={{margin: '50px', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => history.push('/admin/all-users')}>Go to: ALL USERS LIST</div>

            <SnackBar handleClose={handleCloseSnackbar} open={openSnackbar} severity="success" content="Email successfully saved! Be aware that it has probably entered into the spam."/>

        </div>
    )
}

export default SendResetPassToUser;