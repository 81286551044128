import { useContext, useEffect, useState } from 'react';
import { collection, query, onSnapshot } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';
import AuthContext from '../../store/auth-context';



export const useGetAllProjects = () => {
    const [projects, setProjects] = useState();

    const authCtx = useContext(AuthContext);

    useEffect(() => { 
        if(authCtx.currentUserId && authCtx.currentUserProfile.is_admin) {
            const myQuery = query(collection(db, 'projects'));
            onSnapshot(myQuery, (querySnapshot) => {
                const allData = [];
                querySnapshot.forEach((doc) => {
                    allData.push({...doc.data(), id: doc.id});
                });
                setProjects(allData);
            }, (error) => {
                console.log('error from useGetAllProjects: ', error)
                console.log(error);
            });
        }   
    }, [authCtx.currentUserId, authCtx.is_admin]);

return projects;
}; 

