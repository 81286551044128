import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { downloadIcon } from '../../assets/icons/Icons';
import './ProjectView.css';




const ExportToExcel = (props) => {
    const {data, modelMetadata} = props;

    const csvData = data.input_costs;
    const csvMainData = data.input_main[0].data;
    const csvMainHeaders = data.input_main[0].header;

    let mainData;
    let costsData;

    let mainHeadersData = [];
    let costsHeadersData;


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    const CostsHeadings = {}
    const CostsHeadingsArray = []
    if (modelMetadata && modelMetadata.columns_order_costs) {
        modelMetadata.columns_order_costs.map(h => {
            return ({...CostsHeadings[h] = h})
        })
        CostsHeadingsArray.push(CostsHeadings)
    }

    const MainHeadings = {}
    const MainHeadingsArray = []
    const mainHeaders = []
    if (modelMetadata && modelMetadata.columns_metadata && csvMainHeaders) {
        // modelMetadata.columns_metadata.map((obj, objIndex) => MainHeadings.obj[objIndex]['nazwa wyświetlana'] = obj[objIndex]['nazwa wyświetlana']
        csvMainHeaders.map(header => {
            return ({...MainHeadings[header] = modelMetadata.columns_metadata[0]['nazwa wyświetlana']})

            // console.log(obj['nazwa zmiennej w tabeli źródłowej']);
        })
        MainHeadingsArray.push(MainHeadings)

        // for (let i = 1; i < modelMetadata.columns_metadata.length; i++) {
        //     mainHeaders.push(modelMetadata.columns_metadata[i]['nazwa zmiennej w tabeli źródłowej'])
        // }
        // for (let i = 0; i < csvMainHeaders.length; i++) {
        //    const header = {csvMainHeaders[i]: ['nazwa wyświetlana']
        //     mainHeaders.push(header)
        // }
    }

    // newJson = oldJson.map(rec => {
    //     return {
    //       'Last Name': rec.lastName,
    //       'First Name': rec.firstName,
    //       ...
    //     }
    //   })

    // console.log(MainHeadings);
    // console.log(CostsHeadings);
    // console.log(MainHeadingsArray);
    // console.log(mainHeaders);

    const exportToCSV = (csvData, fileName) => {
        // const ws = XLSX.utils.json_to_sheet(csvData);
        const ws = XLSX.utils.json_to_sheet(CostsHeadingsArray, {header: 1 });
        // wscols["!cols"] = wscols;

        XLSX.utils.sheet_add_json(ws, csvData, {
            header: [...modelMetadata.columns_order_costs], skipHeader: true, origin: -1
        })
        const main = XLSX.utils.json_to_sheet(MainHeadings, {header: [...csvMainHeaders], skipHeader: false, origin: 0});
        XLSX.utils.sheet_add_json(main, csvMainData, {
            header: [...mainHeaders], skipHeader: true, origin: -1
        })
        
        const wb = { Sheets: { 'dat': main, 'data2': main }, SheetNames: ['dat', 'data2'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const handleExportToExcel = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const scenarioMainData = data.input_main;

        // let varHPTab = [];
        // scenarioMainData.map(tab => varHPTab.push(tab.header))
        // console.log(varHPTab);
        // let displayHeadersPerTab = [];

// console.log( modelMetadata.columns_metadata);
// console.log();

    // ARRAY OF DISPLAY HEADERs OF INPUT_MAIN:

    const allMainHeadersData = [];
    modelMetadata.columns_metadata.map(el => allMainHeadersData.push(el['nazwa wyświetlana']))
    allMainHeadersData.shift()

    // OBJECT OF ALL DISPLAY HEADERs OF INPUT_MAIN:
        const dHeadersObj = {};

        scenarioMainData.map((inputMainTab) => {

           modelMetadata.columns_metadata.map(metaColumn => {
                if (metaColumn['nazwa zmiennej w tabeli źródłowej'] !== '-') {
                     dHeadersObj[metaColumn['nazwa zmiennej w tabeli źródłowej']] = metaColumn['nazwa wyświetlana'];
                }
            })

            // console.log(dHeadersObj);
            // console.log(inputMainTab.data)
        //     inputMainTab.header.map(h => {
        //         // console.log(dHeadersObj[h])
        

        
        // console.log(dHeadersObj);
        // console.log(inputMainTab);
        //         //    modelMetadata.columns_metadata.map(disHeader => disHeader['nazwa zakladki'] === inputMainTab.name)

        // })
    })
        // modelMetadata.columns_metadata.fil(el => mainHeadersData.push(el['nazwa wyświetlana']))
        
        // Data for workbook:
        const modelName = modelMetadata.model_name;
        const scenarioName = data.scenario_name;
        const costsData = data.input_costs;
        const goalData = [{'Goal': data.input_goal}];
        const outputMediaData = data.output_media;
        const outputForecastData = data.output_forecast;

        const transformedOutputMediaData = [...outputMediaData];


        // const allMainHeadersData = [];
        // modelMetadata.columns_metadata.map(el => allMainHeadersData.push(el['nazwa wyświetlana']))
        // allMainHeadersData.shift()
        // console.log(allMainHeadersData);
        // console.log(scenarioMainData);

        // Headers for workbook: 
        const costsHeadersData = modelMetadata.columns_order_costs;
        const outputMediaHeaders = modelMetadata.columns_order_output_media;
        const outputForecastHeaders = modelMetadata.columns_order_output_forecast;

        transformedOutputMediaData.map((row, rowIndex) => {
            row[' '] = allMainHeadersData[rowIndex+1]
        })


       const transformedOutputMediaHeaders = [...outputMediaHeaders];
       transformedOutputMediaHeaders.unshift(' ');

        const wb = XLSX.utils.book_new()
        const wscosts = XLSX.utils.json_to_sheet(costsData, {header: [...costsHeadersData]});
        const wsgoal = XLSX.utils.json_to_sheet(goalData);
        const wsOutputMedia = XLSX.utils.json_to_sheet(transformedOutputMediaData, {header: [...transformedOutputMediaHeaders]});
        const wsOutputForecast = XLSX.utils.json_to_sheet(outputForecastData, {header: [...outputForecastHeaders]});
        // const wsmain = XLSX.utils.json_to_sheet(mainData, {header: [...mainHeadersData]});
        // mainData.map((tab, tabIndex) => {
        //     const data = XLSX.utils.json_to_sheet(mainData[tabIndex], {header: [...mainHeadersData]});
        //     return XLSX.utils.book_append_sheet (wb, data, `Main-${tabIndex+1}`)
        // })
        scenarioMainData.map((tab, tabIndex) => {
            // const newHeaders = mainData[tabIndex].header.map(h, i => returnmainHeadersData[i]= h)
            const headers = [...tab.header]

            const headersDisPerTab = {};
            headers.map(h => {
                return headersDisPerTab[h] = dHeadersObj[h]
            })
            const changedData = [...tab.data]
            if (changedData[0].date !== 'Date') {
                changedData.unshift(headersDisPerTab)
            }

            const data = XLSX.utils.json_to_sheet(changedData, {header: [...headers], skipHeader: true, origin: 0});
             
                XLSX.utils.book_append_sheet (wb, data, `Main-${scenarioMainData[tabIndex].name}`)
            
        })
        XLSX.utils.book_append_sheet (wb, wscosts, 'Costs')
        XLSX.utils.book_append_sheet (wb, wsgoal, 'Goal')
        XLSX.utils.book_append_sheet (wb, wsOutputMedia, 'Output-Media')
        XLSX.utils.book_append_sheet (wb, wsOutputForecast, 'Output-Forecast')
        // console.log('written')
        const t = new Date();
        const dateString = `${t.getFullYear()}-${t.getMonth() < 9 ? '0'+ (t.getMonth()+1) : (t.getMonth()+1)}-${t.getDate()}--${t.getHours() < 10 ? '0'+t.getHours() : t.getHours()}-${t.getMinutes() < 10 ? '0'+t.getMinutes() : t.getMinutes()}-${t.getSeconds() < 10 ? '0'+t.getSeconds() : t.getSeconds()}`;
        XLSX.writeFile(wb, modelName+'_'+scenarioName+' ('+dateString+').xlsx', {bookType: 'xlsx'});
    }

    return (
        <>
        {/* <button onClick={logME}>LOG</button> */}
        {/* <button onClick={(e) => exportToCSV(csvData, fileName)}>Export to excel</button> */}
        {/* <button onClick={(e) =>handleExportToExcel(costsData, 'file')}>Export excel NEW</button> */}
        <button className='list-btn square-button icon-button light' title='Download' disabled={false}  onClick={(e) => handleExportToExcel(e)}>{downloadIcon}</button>
        </>
    )
}

export default ExportToExcel;