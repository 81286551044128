import { useContext, useState, useRef } from 'react';
import { Typography, Box, Modal, Switch } from '@mui/material';

import DataContext from '../../store/data-context';
import { modalTexts, buttonTexts } from '../../utils/translate'; 
import {Button} from '../UI/Button';
import CloseModal from './CloseModal';
import { modelIcon, scenarioIcon, visibilityIcon } from '../../assets/icons/Icons';


import classes from './Modals.module.css';
import { appColors } from '../../utils/colors';

const modalWidth = 450;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    '& .MuiInputBase-input': {
        padding: '6px 0 6px 14px'
    }
  };


const SaveModal = (props) => {

    const {handleClose, open, modelName, scenarioName, scenarioVisibility} = props;
    const dataCtx = useContext(DataContext);

    const visibilitySelectRef = useRef();

    // console.log(scenarioVisibility);

    const styles = {
        '& .MuiSwitch-switchBase.Mui-checked': {color: appColors.mainAccentColor},  
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {backgroundColor: appColors.mainAccentColor}
      } 

      const [status, setStatus] = useState();
   
      const handleStatusChange = () => {
          if (scenarioVisibility === 'private') {
              setStatus('public')
          } else if (scenarioVisibility === 'public') {
              setStatus('private');
          }
      }

    
    const handleSave = (e) => {
        e.preventDefault();
        // const scenarioVisibility = visibilitySelectRef.current.value;

        // console.log(scenarioVisibility);

        props.handleSaveScenario(null, status)

    }

    return (
 <>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    {/* <Typography id="modal-modal-title" variant="h6" component="h2">
      Edytuj scenariusz:
    </Typography> */}
    <CloseModal clicked={handleClose} />
    <h2 className={classes.header}>{modalTexts.saveScenario.title[dataCtx.lang]}:</h2>
    <p>{modalTexts.saveScenario.content[dataCtx.lang]}</p>
          <div >
                <div style={{display: 'inline-block', width: '45%'}}><span className={classes.modalIcon}>{modelIcon}</span>{modalTexts.modelName[dataCtx.lang]}: </div>
                <div style={{display: 'inline-block'}}>{modelName}</div>
            </div>
            <div >
                <div style={{display: 'inline-block', width: '45%'}} ><span className={classes.modalIcon}>{scenarioIcon}</span>{modalTexts.scenarioName[dataCtx.lang]}: </div>
                <div style={{display: 'inline-block'}}>{scenarioName}</div>
            </div>
            <div>
            <div>
                <label htmlFor='scenario-visibility'><span className={classes.modalIcon}>{visibilityIcon}</span>Public</label>
                {/* <select name="scenario-visibility" id="scenario-visibility" ref={visibilitySelectRef}>
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                </select> */}
                <Switch sx={styles} defaultChecked={scenarioVisibility === 'public'} onClick={handleStatusChange} />
            </div>

            </div>
           
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>       
                <Button type='button' clicked={handleClose} text={buttonTexts.cancel[dataCtx.lang]} styling='light' />
                <Button type='button' clicked={(e) => handleSave(e)} text={buttonTexts.save[dataCtx.lang]} styling='primary' />
            </div>

 
            {/* <div>
                <label htmlFor='scenario-name'>scenario name</label>
                <input type='text' id='scenario-name' required ref={scenarioNameRef} />
            </div>
            <div>
            <select name="scenario-visibility" id="scenario-visibility" ref={visibilitySelectRef}>
                <option value="private">Private</option>
                <option value="public">Public</option>
            </select>
            </div>
           
            <button type='button' onClick={(e) => handleSave(e)}>Save</button> */}

            
  </Box>
</Modal>
</>
    )
}

export default SaveModal;