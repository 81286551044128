import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const ManageProject = (props) => {
    const history = useHistory()
    const projectId = props.match.params.projectId;
    const projectName = history.location.state.projectName;

    return (
        <div className='app-main'>
            <h2>Manage project</h2>
            <nav style={{paddingTop: '30px', width: '70%', margin: '0 auto'}}>
                <h3>Admin's links: </h3>
                <div onClick={() => history.push(`${projectId}/create-model`, {projectId, projectName})} style={{textDecoration: 'underline', cursor: 'pointer'}}>Create new model</div>
        <br/>
                <hr />
                <h3>User's links: </h3>
                <div onClick={() => history.push(`/project/${projectId}`, {projectId, projectName})} style={{textDecoration: 'underline', cursor: 'pointer'}} >All scenarios</div>
            </nav>
        </div>
    )
}

export default ManageProject;