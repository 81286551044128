import { idText } from "typescript";

export const generatePassword = () => {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_-+=";
    const length = 8;
    let passwordTxt = '';

    for (let i = 0; i < length; i++) {
        passwordTxt += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    return passwordTxt;

}

export const generateId = (charNum) => {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    
    let idTxt = '';

    for (let i = 0; i < charNum; i++) {
        idTxt += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    return idTxt;

}