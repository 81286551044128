import React, { useContext } from 'react';
import { Field } from 'formik';
import _ from 'lodash';

import './FirstTable.css';

import DataContext from '../../store/data-context';
import { appColors } from '../../utils/colors';
import goalImg from '../../assets/images/goalIcon.png';

const DisplayGoal = (props) => {
    const {initialValues, values, handleChange, allValuesBeforeSent, calculated, checkValuesDifference, checkIfScenarioIsSaved, errors, validate, modelMetadata} = props;


    const dataCtx = useContext(DataContext);

    let modelCurrency;
    if (modelMetadata && modelMetadata.hasOwnProperty('model_currency')) {
        modelCurrency = modelMetadata.model_currency
    } else {
        modelCurrency = 'PLN'
    }

    let errorExists = false;
    let errorMessage = ''
    const checkErrorExistence = async () => {
        if (errors && errors.hasOwnProperty('input_goal')) {
            errorExists = true;
            if (values.input_goal === '') {
                errorMessage = 'Required' 
            } else if (!/^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(values.input_goal)) {
                errorMessage = 'Invalid number'
            } 

        } else {
            errorExists = false;
        }
    }
    checkErrorExistence()

    const onBlurHandler = (e, inputValue) => {
        if (typeof values.input_goal === 'string' && /^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(values.input_goal)) {
            values.input_goal = +(values.input_goal.replace(',', '.'));   
        }
        e.target.value =  values.input_goal.toLocaleString('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'currency', currency: modelCurrency, currencyDisplay: 'code'});


        const compareWithInitialData = _.isEqual(initialValues, values);

        if (compareWithInitialData) {
            checkIfScenarioIsSaved(true);
            dataCtx.scenarioSavedHandler(true);
        } else {
            checkIfScenarioIsSaved(false);
            dataCtx.scenarioSavedHandler(false);
        }
    }   

    const onFocusHandler = (e, inputValue) => {
        e.target.value = inputValue.toLocaleString('fr-FR', {useGrouping: false});
    }

    const onKeyUpChangesHandler = () => {
        const compareWithInitialData = _.isEqual(initialValues, values);
        const compareWithCalculatedData = _.isEqual(allValuesBeforeSent, values);
        // console.log(compareWithInitialData);
        // console.log(compareWithCalculatedData);

        // }
        if (!calculated && !compareWithInitialData) {
            checkValuesDifference(true);
        } else if (calculated && !compareWithCalculatedData) {
            checkValuesDifference(true);
        } else {
            checkValuesDifference(false)
        }

    }


    return (
        <table
        className="first-table"
        style={{ maxHeight: "50px", overflow: "scroll" }}
        >
            <tbody>
            <tr >
                <th><b>GOAL: </b></th>
                <td className='goal-input' style={errorExists ? {backgroundColor: appColors.errorCell, padding: '10px'} : !errorExists && initialValues.input_goal !== +values.input_goal ? {backgroundColor: appColors.changedCellColor, padding: '10px'} : {backgroundColor: '#fff', padding: '10px'}}>
                <img src={goalImg} />
                <Field
                    required
                    name={"input_goal"}
                    id={"input_goal"}
                    value={!values.input_goal || values.input_goal === 0 ? 0 : values.input_goal.toLocaleString('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'currency', currency: modelCurrency, currencyDisplay: 'code'})}
                    onChange={handleChange}
                    validate={validate}
                    onFocus={(e) => onFocusHandler(e, values.input_goal)}
                    onBlur={(e) => onBlurHandler(e, values.input_goal)}
                    onKeyUp={() => onKeyUpChangesHandler()}
                    // autocomplete='off'
                />
                {errors && errorExists && <div style={{color: 'white', fontWeight: 'normal' }}>{errorMessage}</div>}

                </td>
            </tr>
            </tbody>
        </table>
    )
}

export default DisplayGoal;