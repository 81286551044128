import React, { useContext } from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useHistory } from "react-router-dom";

import AuthContext from "../../store/auth-context";

import { authentication } from '../../firebase/firebase';

import googleLogo from '../../assets/images/google-icon.png';
// import '../Navigation/Navigation.css';

import classes from './AuthForm.module.css';

const LoginGoogle = () => {

    const authCtx = useContext(AuthContext);
    const history = useHistory();

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('email');
        signInWithPopup(authentication, provider)
            .then(result => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const idToken = credential.idToken; 
                // const accessToken = credential.accessToken;
                
                const user = result.user;
                authCtx.currentUserId = result.user['uid'];
                authCtx.currentUserName = result.user['displayName'];
                // const userJSON = user.toJSON();
                // const expirationTime = userJSON.stsTokenManager.expirationTime;
                // const googleId = userJSON.providerData[0].uid;
                // const lastLoginAt = userJSON.lastLoginAt;

                authCtx.login(idToken);

                // authCtx.user = user.toJSON();

                history.push('/projects');
            })
            .catch(error => {
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // const email = error.email;
                // const credential = GoogleAuthProvider.credentialFromError(error);
            })
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>   
            <button className={classes.googleButton} onClick={signInWithGoogle}><img src={googleLogo}/>Login with Google</button>    
        </div>
    );
};

export default LoginGoogle;