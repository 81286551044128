import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { doc, setDoc, deleteDoc, updateDoc, query, collection, where, getDocs, onSnapshot } from '@firebase/firestore';
import { db } from '../../firebase/firebase';
import { Form, Formik } from 'formik';
import _ from 'lodash';
// CONTEXT:
import AuthContext from '../../store/auth-context';
import DataContext from '../../store/data-context';
// DATA:
// import { useRootScenario } from '../data/useRootScenario';
import { useGetAllScenarios } from '../data/useGetAllScenarios';
import {rootLocalData} from '../../rootData.js'
// import { useGetPrivateScenarios } from '../data/useGetPrivateScenarios';
// import { useGetPublicScenarios } from '../data/useGetPublicScenarios';
//COMPONENTS: 
import DisplayMainComponent from './DisplayMainComponent';
import OutputTable from './OutputTable';
import DisplayCosts from './DisplayCosts';
// MODALS:
import SaveModal from '../modals/SaveModal';
import SaveAsModal from '../modals/SaveAsModal';
import ResetModal from '../modals/ResetModal';
import ConfirmModal from '../modals/ConfirmModal';
import ConfirmModalTemplate from '../modals/ConfirmModalTemplate';
import EditModal from '../modals/EditModal';

import { Button } from '../UI/Button';
import { ActionButton } from '../UI/ActionButton';
import Card from '../UI/Card';
import SnackBar from '../UI/SnackBar';
import { deleteIcon, deleteScenarioIcon, downloadIcon, downloadScenarioIcon, editIconWhite, loadDefaultScenarioIcon, restoreSavedScenarioIcon, saveScenarioIcon } from '../../assets/icons/Icons';
import chooseScenarioImg from '../../assets/images/chooseScenarioIcon.png';
import { modalTexts, buttonTexts, appTexts } from '../../utils/translate';

import './FirstTable.css';
import '../CommonViews/ProjectView.css';
import buttonClasses from "../UI/Button.module.css";

import { exportToExcelFn } from '../../utils/exportToExcelFn'; 
import { generateId } from '../../utils/generatePassword';
import { useGetModelMetadata } from '../data/useGetModelMetadata';
import DisplayGoal from './DisplayGoal';
import { appColors } from '../../utils/colors';


const FirstTableWithFormik = (props) => {

  const history = useHistory();
  // console.log(props);
  const currentUrl = props.match.path;
  const dataFromUrl = {
    projectId: props.match.params.projectId,
    modelId: props.match.params.modelId,
    scenarioId: props.match.params.scenarioId
  }

  const authCtx = useContext(AuthContext);
  const dataCtx = useContext(DataContext);
  const location = useLocation();
     
  const [activeTab, setActiveTab] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [outputData, setOutputData] = useState({})

  const [allValuesBeforeSent, setAllValuesBeforeSent] = useState({})

  const [allScenarios, setAllScenarios] = useState([]);
  const [modelsSettings, setModelsSettings] = useState([]);
  const [projectId, setProjectId] = useState(dataFromUrl.projectId);
  const [projectName, setProjectName] = useState('');
 
  const [modelId, setModelId] = useState(dataFromUrl.modelId);
  const [modelName, setModelName] = useState('');

  const [projectsMetadata, setProjectsMetadata] = useState();

  // SnackBar
  const [openSnackbarSaved, setOpenSnackbarSaved] = useState(false);
  const handleOpenSnackbarSaved = () => setOpenSnackbarSaved(true);
  const handleCloseSnackbarSaved = () => setOpenSnackbarSaved(false);



  let noData = false;

  useEffect(() => {
    if (location && location.state && location.state.currentProjectName) {
      setProjectName(location.state.currentProjectName);

    } else {
      // console.log('no project name');
    }
  }, [])


  if (location && location.state === undefined && allScenarios.length === 0) {
    noData = true
  }

  const dataFetchedChangeState = () => {
    noData = false;
  }


  const modelMetadata = useGetModelMetadata({projectId, modelId});
  const rootData = useGetAllScenarios({projectId, modelId, noData, dataFetchedChangeState});

  const scenarioSelectRef = useRef();
  const [selectedScenario, setSelectedScenario] = useState(dataFromUrl.scenarioId);
  const [selectedScenarioName, setSelectedScenarioName] = useState('');

  

  const handleScenarioSelection = (e, initialValues) => {
    e.preventDefault();
    // dataCtx.scenarioNameHandler(scenarioSelectRef.current.value);

    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const selectedOptionId = el.getAttribute('id')
    // console.log(selectedOptionId);
    setSelectedScenario(selectedOptionId)
    // console.log(initialValues);

    const selectedScenario = allScenarios.find(sc => sc.id === selectedOptionId);
    // console.log(selectedScenario);
    if (selectedScenario) {
      setSelectedScenarioName(selectedScenario.scenario_name)
    }

      handleInternalScenarioChange(initialValues, selectedScenario, scenarioSaved)

  }

    let rootScenario = {};
    if (rootData) {
      rootScenario = rootData.find(scenario => scenario.id === 'root')
    }

    useEffect(() => {
      if (rootData && rootData.length > 0) {
        setActiveTab(rootData[0].input_main[0].name);
      } else if (!rootData && allScenarios && allScenarios.length > 0) {
          setActiveTab(allScenarios[0].input_main[0].name);
      }
    }, [rootData, allScenarios])


    
    const [sentValues, setSentValues] = useState({});

    const [receivedValues, setReceivedValues] = useState({});



// VALIDATION
const validateNumbers = (value) => {
    // console.log('checked');
    let error;

    if (value === '') {
        error = 'Required';
    // } else if (/^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(value) === true) {
    //     error = 'hmm';
    } else if (/^[0-9]+(,[0-9]{1,}|\.[0-9]{1,})?$/.test(value) === false) {
        error = 'not a number'
    }
    return error;

} 

    let headersArrays;

    if (modelMetadata) {
      headersArrays = modelMetadata.columns_metadata
 }
    let mediaHeadersArray = [];

    let mediaHeaders;

    if (modelMetadata) {
      mediaHeaders = modelMetadata.columns_metadata.filter(col => col.typ === 'media')
      mediaHeaders.map(mediaColumn => mediaHeadersArray.push(mediaColumn['nazwa wyświetlana']))
  }
   

    let displayTabs;

    if (rootData && rootData.length > 0) {

        const colNumPerTab = [];
        const mainTabsData = rootData[0].input_main;
        let stopIndex = 0;
        
        for (let i = 0; i < mainTabsData.length; i++) {
            stopIndex = stopIndex + mainTabsData[i].header.length;
            colNumPerTab.push(stopIndex);
        }

        displayTabs = mainTabsData.map(tab => {
           return (
            <div className={tab.name === activeTab ? 'active' : 'inactive'} onClick={(event) => setActiveTab(event.target.innerHTML)} key={`tab-${tab.name}`}>{tab.name}</div>
            )
        
        })
    } else if (!rootData && allScenarios && allScenarios.length > 0) {
      const mainTabsData = allScenarios[0].input_main;
      displayTabs = mainTabsData.map(tab => {
            
        return (
        <div className={tab.name === activeTab ? 'active' : 'inactive'} onClick={(event) => setActiveTab(event.target.innerHTML)} key={`tab-${tab.name}`}>{tab.name}</div>
        )
    
    })
    }
if (rootData) {

  // console.log(rootData);
} else {
  // console.log('norootData');
}

    // FORMIK
    
useEffect(() => {
      if(dataFromUrl) {
          setProjectId(dataFromUrl.projectId);
          setModelId(dataFromUrl.modelId);
          setSelectedScenario(dataFromUrl.scenarioId);
          //  console.log('data from 1');
      }

      // if (modelMetadata) {
      //   setModelName(modelMetadata.model_name)
      // }
      if (rootData) {
        setAllScenarios(rootData);
        const curScenario = rootData.find(sc => sc.id === props.match.params.scenarioId);
        setInitialValues({...curScenario})
        // console.log('data from rootdata');
      } else if (location.state) {
        // console.log('data from 2');
        // console.log(location.state.scenario);
          setInitialValues({...location.state.scenario})
          setOutputData({
            output_forecast: location.state.scenario.output_forecast,
            output_media: location.state.scenario.output_media
          }) 
          setAllScenarios(location.state.allScenarios);
          setModelsSettings(location.state.currentModels);
          setProjectId(location.state.scenario.project_id);
          setModelId(location.state.scenario.model_id);
          setModelName(location.state.selectedModelName);
          setSelectedScenario(location.state.scenario.id);
          setSelectedScenarioName(location.state.scenario.scenario_name);

          dataCtx.projectHandler(location.state.scenario.project_id)
          dataCtx.modelHandler(location.state.scenario.model_id)
          dataCtx.scenarioHandler(location.state.scenario.id)
          dataCtx.scenarioNameHandler(location.state.scenario.scenario_name)
      } else if (!location.state && rootData) {
        
        // console.log(rootData);
        // setInitialValues()
        // console.log(allScenarios);
        // console.log('data from 3');
        
        setAllScenarios(rootData)
        
      } 
      else if (authCtx.currentUserId && !location.state && !rootData) {

        setIsLoadingData(true)
        // console.log('fetching');
          const myQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('visibility_owner', 'in', ['public', authCtx.currentUserId]));
          onSnapshot(myQuery, (querySnapshot) => {
            const allData = [];
            querySnapshot.forEach((doc) => {
                allData.push({...doc.data(), id: doc.id});
            });
            // console.log(allData);
            // console.log('data from 4');
            setAllScenarios(allData);

            const currScenario = allData.find(s => s.id === props.match.params.scenarioId);
            // console.log(currScenario);
            if (currScenario) {
              setInitialValues(currScenario)            
              setOutputData({
                output_forecast: currScenario.output_forecast,
                output_media: currScenario.output_media
              }) 
              setSelectedScenario(currScenario.id)
              setSelectedScenarioName(currScenario.scenario_name)
            } else if (allData && !currScenario ) {
              dataCtx.scenarioSavedHandler(true)
              setScenarioSaved(true)
              history.push('/not-authorized');              
            }
            setIsLoadingData(false);
          }, (error) => {
            console.log('Error from inside Calculator: ', error );
            setIsLoadingData(false)

          });
      }
    }, [location.state, rootData, authCtx.currentUserId])

    useEffect(() => {
      if (modelMetadata) {
        setModelName(modelMetadata.model_name);
      }
    }, [modelMetadata])

  //   useEffect(() => {
  //     if (usersProjects) {
  //         setUP(usersProjects)
  //         // console.log(usersProjects);
  //     } 
  // }, [authCtx])
  
  // console.log(isLoading);
  
  useEffect(() => {   
      let userProjects = [''];
      if (authCtx && authCtx.currentUserProfile && authCtx.currentUserProfile.roles) {
          userProjects = Object.keys(authCtx.currentUserProfile.roles);
        }

      let myQuery;
      if (location && !location.state && authCtx) {
        if (authCtx.currentUserProfile.is_admin) {
          myQuery = query(collection(db, 'projects'));
        } else if (!authCtx.currentUserProfile.is_admin && userProjects.length > 0) {
          myQuery = query(collection(db, 'projects'), where('project_id', 'in', userProjects));
        }

        onSnapshot(myQuery, (querySnapshot) => {
          const allData = [];
          querySnapshot.forEach((doc) => {
              allData.push({...doc.data(), id: doc.id}); 
              
          });

          setProjectsMetadata(allData)

          const curProjectName = allData.find(project => project.project_id === dataFromUrl.projectId);
          setProjectName(curProjectName.name)
      }, (error) => {
              console.log('error from fetchProjectsCalc: ', error)
          }
  
      );
  
      }
  
  
       
  },[authCtx, authCtx.currentUserId, authCtx.currentUserProfile.roles, location, projectId]);


// console.log(allScenarios);
let displayScenarioSelect;
// if (allScenarios && allScenarios.length > 0) {
//   displayScenarioSelect = allScenarios.map(scenario => <option selected={scenario.scenario_name === selectedScenarioName} key={`option-${scenario.scenario_name}`}>{scenario.scenario_name}</option>);
// }

if (rootData && rootData.length > 0 ) {
  const sortedRootData = _.sortBy(rootData, scenario => scenario.scenario_name)
  displayScenarioSelect = sortedRootData.map(scenario => <option id={scenario.id} selected={scenario.scenario_name === selectedScenarioName} key={`option-${scenario.id}`}>{scenario.scenario_name}</option>);
} else if (!rootData && allScenarios && allScenarios.length > 0) {
  const sortedAllScenarios = _.sortBy(allScenarios, scenario => scenario.scenario_name)
    displayScenarioSelect = sortedAllScenarios.map(scenario => <option id={scenario.id} selected={scenario.scenario_name === selectedScenarioName} key={`option-${scenario.id}`}>{scenario.scenario_name}</option>);

}

const [sentData, setSentData] = useState()
const [valuesSubmitted, setValuesSubmitted] = useState(false)
const [isLoading, setIsLoading] = useState(false);
const [isLoadingData, setIsLoadingData] = useState(false);

// useEffect((sentValues) => {
    const handleSendValues = (data) => {

        setIsLoading(true);
    let url = `https://one-budget-allocator.groupone.pl/api/v1/${projectId}/models/${modelId}/calculate`;
// console.log(JSON.stringify(data));
    // if (valuesSubmitted) {
        fetch(url,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setIsLoading(false);
            if(res.ok) {
                return res.json()
            } else {
                let errorMessage = 'Calculation failed'
                throw new Error(errorMessage)
            }
            
        }).then(data => {
         const recData = {
            output_forecast: data.data.output_forecast,
            output_media: data.data.output_media,
            
         }
         setReceivedValues(recData);

         setOutputData(recData);
         checkValuesDifference(false);
       

      }).catch(err => {
         alert(err.message);
       });
    // }
    
        setValuesSubmitted(false)
    }

    
    const handleSaveEdittedScenario =  async (e, visibility) => {
      const docId = selectedScenario;
      // const inputData = {...sentValues};
      
      const valuesToSave = {
         ...sentValues, 
         output_forecast: outputData.output_forecast,
         output_media: outputData.output_media,
         id: docId,
         model_id: modelId,
         project_id: projectId,
         scenario_name: selectedScenarioName,
         updated_at: new Date(),
         visibility: visibility === undefined ? initialValues.visibility : visibility,
         created_at: initialValues.created_at,
         owner_name: initialValues.owner_name,
         owner_id: initialValues.owner_id,
         visibility_owner: visibility ===  undefined ? initialValues.visibility_owner : visibility === 'public' ? 'public' : initialValues.owner_id
         
      }

      const recordRef = doc(db, 'projects', projectId, 'models', modelId, 'scenarios', docId);

      // const publicScenariosQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', selectedScenarioName), where('visibility', '==', 'public'));
      // const privateScenariosQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', selectedScenarioName), where('visibility', '==', 'private'), where('owner_id', '==', authCtx.currentUserId));
      const sQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', selectedScenarioName), where('visibility_owner', 'in', ['public', authCtx.currentUserId]));
      let scenarios = [];
      // const querySnapshotPublic = await getDocs(publicScenariosQuery);
      // const querySnapshotPrivate = await getDocs(privateScenariosQuery);
      const querySnapshotAvailable = await getDocs(sQuery);
      // querySnapshotPublic.forEach((doc) => {
      //     scenarios.push({...doc.data()});
      // });
      // querySnapshotPrivate.forEach((doc) => {
      //   scenarios.push({...doc.data()});
      // });
      querySnapshotAvailable.forEach((doc) => {
        scenarios.push({...doc.data()});
      });

      // const docSnap = await getDoc(recordRef); 
        // if (docSnap.exists()) {
      if (scenarios.length > 0 && scenarios[0].id !== selectedScenario) {
            alert(`The scenario with this name already exists, the process will be stopped`);
            return;
        } else {
            await setDoc(recordRef, valuesToSave
            ).then(() => {                
                setSaveModalOpen(false)
                setInitialValues(valuesToSave)
                // setAllScenarios([...allScenarios, valuesToSave]);
                // setSelectedScenario(valuesToSave.id)
                // setSelectedScenarioName(valuesToSave.scenario_name)
                // dataCtx.scenarioHandler(valuesToSave.id)
                setCalculated(false)
                setScenarioSaved(true)
                dataCtx.scenarioSavedHandler(true);
                handleOpenSnackbarSaved()
                history.push(`/calculator/${projectId}/${modelId}/${docId}`)
            })
        }   
      // alert('save as a NEW scenario')
    }


    const handleResetScenario = (scenarioName) => {

      // console.log(scenarioName);

      let currentScenario;

      if (!allScenarios && rootData) {
        currentScenario = rootData.find(scenario => scenario.scenario_name === scenarioName)

      } 
      else if (allScenarios && allScenarios.length > 0) {
        currentScenario = allScenarios.find(scenario => scenario.scenario_name === scenarioName)
      } else {
        currentScenario = rootData.find(scenario => scenario.scenario_name === scenarioName)

      }

      // console.log(currentScenario);
      setInitialValues(currentScenario)
      setOutputData({
          output_forecast: currentScenario.output_forecast,
          output_media: currentScenario.output_media
      })
      setInitialValues({...currentScenario})
      handleResetModalClose(); 
      handleLoadDefaultModalClose(); 
      history.push(`/calculator/${projectId}/${modelId}/${currentScenario.id}`)
      checkValuesDifference(false)
      dataCtx.scenarioSavedHandler(true)
      setScenarioSaved(true)
      setCalculated(false)

    }
    // Save as modal:
    const [saveAsModalOpen, setSaveAsModalOpen] = useState(false);
    const handleSaveAsModalOpen = () => setSaveAsModalOpen(true);
    const handleSaveAsModalClose = () => setSaveAsModalOpen(false);
    // Save modal:    
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const handleSaveModalOpen = () => setSaveModalOpen(true);
    const handleSaveModalClose = () => setSaveModalOpen(false);
    // Reset modal:
    const [resetModalOpen, setResetModalOpen] = useState(false);
    const handleResetModalOpen = () => { 
      setResetModalOpen(true);
    }
    const handleResetModalClose = () => {
      setResetModalOpen(false); 

    }
    // Load default scenario modal:setAllScenarios
    const [loadDefaultModalOpen, setLoadDefaultModalOpen] = useState(false);
    const handleLoadDefaultModalOpen = () => setLoadDefaultModalOpen(true);
    const handleLoadDefaultModalClose = () => setLoadDefaultModalOpen(false); 
    // Delete scenario modal:
    const [deleteScenarioModalOpen, setDeleteScenarioModalOpen] = useState(false);
    const handleDeleteScenarioModalOpen = () => setDeleteScenarioModalOpen(true);
    const handleDeleteScenarioModalClose = () => setDeleteScenarioModalOpen(false); 
    // Edit scenario modal:
    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleEditModalOpen = () => {
      setEditModalOpen(true);
    }
    const handleEditModalClose = () => setEditModalOpen(false);

    // not saved scenario modal:
    const [notSavedModalOpen, setNotSavedModalOpen] = useState(false);
    const handleNotSavedModalOpen = () => {
      setNotSavedModalOpen(true);
    }
    const handleNotSavedModalClose = () => setNotSavedModalOpen(false);
  // not saved scenario and scenario change
    const [scenarioChangeAlertModalOpen, setScenarioChangeAlertModalOpen] = useState(false)
    const handleScenarioChangeAlertModalOpen = () => setScenarioChangeAlertModalOpen(true)
    const handleScenarioChangeAlertModalClose = (scName) => {
      setSelectedScenarioName(scName)
      scenarioSelectRef.current.value = scName;
      setScenarioChangeAlertModalOpen(false)
      // console.log(initialValues);
    }

    const handleEditScenario = async (e, scenarioId, scenarioEdittedName, visibility) => {
      // console.log(visibility);
      // alert(`To edit: ${scenarioId}`)
      // const modelQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioEdittedName));
      const publicScenariosQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioEdittedName), where('visibility', '==', 'public'));
      const privateScenariosQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioEdittedName), where('visibility', '==', 'private'), where('owner_id', '==', authCtx.currentUserId));

      // const querySnapshot = await getDocs(modelQuery)
      const querySnapshotPublic = await getDocs(publicScenariosQuery);
      const querySnapshotPrivate = await getDocs(privateScenariosQuery);
      const scenarioRef = doc(db, 'projects', projectId, 'models', modelId, 'scenarios', scenarioId);
      const scenarios = [];
      // querySnapshot.forEach((doc) => {
      //     scenarios.push({...doc.data()});
      // }
      // );
      querySnapshotPublic.forEach((doc) => {
          scenarios.push({...doc.data()});
      });
      querySnapshotPrivate.forEach((doc) => {
        scenarios.push({...doc.data()});
      });

      // console.log('checked');
      
      if (scenarios.length > 0 && scenarios[0].id !== scenarioId) {
          alert('Scenario with this name already exists, please introduce different name')
      } else {
          await updateDoc(scenarioRef, {visibility: visibility ? visibility : initialValues.visibility, scenario_name: scenarioEdittedName, visibility_owner: (visibility && visibility === 'public') ? 'public' : (visibility && visibility === 'private') ? authCtx.currentUserId : initialValues.visibility_owner })
          .then(() => {
            setEditModalOpen(false)
            setSelectedScenarioName(scenarioEdittedName)
            // initialValues.scenario_name = scenarioEdittedName
            // initialValues.visibility = visibility
          })
      }
      
     
  }

    // const docId = generateId()
    // console.log(generateId(20));
    // console.log(docId);
    const handleSaveNewScenario = async (e, scenarioName, visibility) => {
      const docId = generateId(20);
      let inputData;
      if (!calculated) {
        inputData = {
          input_main: initialValues.input_main,
          input_costs: initialValues.input_costs,
          input_goal: initialValues.input_goal
        }
      } else {
        inputData = {...sentValues}
      }
      const valuesToSave = {
         ...inputData, 
         output_forecast: outputData.output_forecast,
         output_media: outputData.output_media,
         created_at: new Date(),
         id: docId,
         model_id: modelId,
         owner_id: authCtx.currentUserId,
         owner_name: authCtx.currentUserProfile.name,
         project_id: projectId,
         scenario_name: scenarioName,
         updated_at: new Date(),
         visibility: visibility,
         visibility_owner: visibility === 'public' ? 'public' : authCtx.currentUserId
      }

      const recordRef = doc(db, 'projects', projectId, 'models', modelId, 'scenarios', docId);

      // const modelQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioName));
      const publicScenariosQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioName), where('visibility', '==', 'public'));
      const privateScenariosQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioName), where('visibility', '==', 'private'), where('owner_id', '==', authCtx.currentUserId));
      const sQuery = query(collection(db, 'projects', projectId, 'models', modelId, 'scenarios'), where('scenario_name', '==', scenarioName), where('visibility_owner', 'in', ['public', authCtx.currentUserId]))
      // const querySnapshot = await getDocs(modelQuery)
      const scenarios = [];
      // querySnapshot.forEach((doc) => {
      //     scenarios.push({...doc.data()});
      // }
      // );


      // const querySnapshot = await getDocs(modelQuery)
      // const querySnapshotPublic = await getDocs(publicScenariosQuery);
      // const querySnapshotPrivate = await getDocs(privateScenariosQuery);
      const querySnapshotAvailable = await getDocs(sQuery);
      // querySnapshot.forEach((doc) => {
      //     scenarios.push({...doc.data()});
      // }
      // );
      // querySnapshotPublic.forEach((doc) => {
      //     scenarios.push({...doc.data()});
      // });
      // querySnapshotPrivate.forEach((doc) => {
      //   scenarios.push({...doc.data()});
      // });

      querySnapshotAvailable.forEach((doc) => {
        scenarios.push({...doc.data()});
    });

      // const docSnap = await getDoc(recordRef); 
        // if (docSnap.exists()) {
      if (scenarios.length > 0 && scenarios[0].id !== docId) {
            alert(`The scenario with this name already exists, the process will be stopped`);
            return;
        } else {
            await setDoc(recordRef, valuesToSave
            ).then(() => {                
                setSaveAsModalOpen(false)
                setInitialValues(valuesToSave)
                setAllScenarios([...allScenarios, valuesToSave]);
                setSelectedScenario(docId)
                setSelectedScenarioName(valuesToSave.scenario_name)
                dataCtx.scenarioHandler(valuesToSave.id)
                dataCtx.scenarioNameHandler(valuesToSave.scenario_name)
                setCalculated(false)
                setScenarioSaved(true);
                dataCtx.scenarioSavedHandler(true);
                history.push(`/calculator/${projectId}/${modelId}/${docId}`, {...location.state, scenario: valuesToSave, currentProjectName: projectName, modelName: modelName, allScenarios: [...allScenarios, valuesToSave]})
                noData = true;
            })
        }   
      // alert('save as a NEW scenario')
    }

    const handleDeleteScenario =  () => {
      const scenarioRef = doc(db, 'projects', projectId, 'models', modelId, 'scenarios', selectedScenario);
      deleteDoc(scenarioRef);
      history.push(`/project/${projectId}`, {projectId: projectId, projectName: projectName, modelId: modelId, modelName: modelName})
      dataCtx.scenarioSavedHandler(true);
      setDeleteScenarioModalOpen(false);
    }
    const handleDeleteModalOpen = () => {setDeleteScenarioModalOpen(true);}

const [outputOutdated, setOutputOutdated] = useState(false);
const [calculated, setCalculated] = useState(false);

const checkValuesDifference = (bool) => {
   setOutputOutdated(bool)
}

const handleFirstCalculation = () => {
   setCalculated(true);
}

const [scenarioSaved, setScenarioSaved] = useState(true)
const checkIfScenarioIsSaved = (bool) => {
  setScenarioSaved(bool)
}

const [url, setUrl] = useState('')
const [obj, setObj] = useState({})

const handleRedirection = (url, obj) => {
  setUrl(url)
  setObj(obj)
  if (scenarioSaved) {
    history.push(url, obj)
  } else {

    setNotSavedModalOpen(true)
  }
 }

 const handleDirectRedirection = () => {
  setUrl('')
  setObj({})
  // console.log(url);
  history.push(url, obj)
 }

 const [firstAction, setFirstAction] = useState(() => {})

 const handleInternalScenarioChange = (initialValues, selectedScenario, scenarioSaved) => {
  if (!scenarioSaved) {
    setInitialValues(initialValues);

  } else {
    setInitialValues(selectedScenario)
  }
 }

 const [selectedOptionId, setSelectedOptionId] = useState(null);
 const [selectedOptionValue, setSelectedOptionValue] = useState(null);
 

    return (
        <div className='app-main' 
          style={{
            // marginTop: '250px', 
            paddingBottom: '20px', 
            height: '100%'}}
          >
              <div onClick={() => handleRedirection(`/project/${projectId}`, {projectId: projectId, projectName: projectName, modelId: modelId, modelName: modelName})} style={{textAlign: 'left', cursor: 'pointer', fontSize: '0.8rem', textDecoration: 'underline'}}>&lt;    Go back to the models list</div>
                <div className='main-content-header'>
                  
                  <div 
                    // style={{position: 'absolute', top: '30px'}}
                  >
                     <h2>{appTexts.calculator[dataCtx.lang]}</h2>
                  </div>
                  <div 
                    style={{
                      // position: 'absolute', 
                      // top: '30px', 
                      right: '20px', 
                      textAlign: 'right'}}
                    >

                    <Card>
                        <div style={{padding: '5px 10px', textTransform: 'uppercase'}}>{appTexts.current[dataCtx.lang]} {appTexts.project.sing[dataCtx.lang]}: <b style={{textTransform: 'none', color: appColors.mainAccentColor, marginLeft: '10px'}}>{projectName}</b></div>
                        <div style={{padding: '5px 10px', textTransform: 'uppercase'}}>{appTexts.current[dataCtx.lang]} {appTexts.model.sing[dataCtx.lang]}: <b style={{textTransform: 'none', color: appColors.mainAccentColor, marginLeft: '10px'}}>{modelName}</b></div>
                    </Card>
                  </div>
                 

                  
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sollicitudin pulvinar nisl eu posuere. Quisque ut scelerisque nisi. Etiam feugiat nisl ligula, eu consectetur augue.</p> */}
              </div>
            {/* <table className='first-table'>
                <thead>
                    {displayHeaders}
                </thead>
            </table> */}

            <p></p>
            <nav className='tabs-nav'>
                {displayTabs}
                <div className={activeTab === 'Costs' ? 'active' : 'inactive'} onClick={(event) => setActiveTab(event.target.innerHTML)}>Costs</div>
            </nav>
            {/* <p>{outputOutdated ? 'shoud be recalculated' : 'DATA OK'}</p> */}
            
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validateNumbers={validateNumbers}
                onSubmit={async (values) => {

                    const input_values = {
                        input_main: [...values.input_main],
                        input_costs: [...values.input_costs],
                        input_goal: values.input_goal
                    }
                    setSentValues(input_values);
                    setAllValuesBeforeSent(values);
                    // console.log(sentValues);
                    // console.log(formValues);
                    await new Promise((r) => setTimeout(r, 500));
                    setValuesSubmitted(true)
                    // alert(JSON.stringify(input_values, null, 2));
                    // handleUpdate(formValues);
                    handleSendValues(input_values)
            }
            }
            >
                {({errors, values, handleSubmit, handleChange, handleReset, resetForm, validateField}) => {
                    // console.log(values);
                    // console.log(errors);
                    // console.log(formValues);
                    let passedEvent;
                    setSelectedScenario(initialValues.id)
                    setSelectedScenario(initialValues.id)
                    
                    const handleResetData = (e, initialValues, scId, newScName, scenarioSaved) => {
                      let optionId;
                      let optionValue;
                      if (e) {
                        const index = e.target.selectedIndex;
                        const el = e.target.childNodes[index];
                        optionId = el.getAttribute('id');
                        optionValue = e.target.value;
                        setSelectedOptionId(optionId);
                        setSelectedOptionValue(optionValue);
                       
                      } 

                      resetFunction(initialValues, optionId, optionValue, scenarioSaved)

                    }

                    const resetFunction = (initialValues, scenarioId, scenarioName, saved) => {
                      let selectedId;
                      let selectedValue;

                      if (!scenarioId) {
                        selectedId = selectedOptionId
                      } else {
                        selectedId = scenarioId;
                      }

                      if (!scenarioName) {
                        selectedValue = selectedOptionValue
                      } else {
                        selectedValue = scenarioName;
                      }
                      
                      if (!saved) {
                        // console.log(initialValues);
                        handleScenarioChangeAlertModalOpen()
                        // setFirstAction(() => resetFunction(null, initialValues, null, scenarioName, true))
                      } else {
                        // setSelectedScenario(selectedOptionId)

                        const selectedScenario = allScenarios.find(sc => sc.id === selectedId);
                        

                        const changed = allScenarios.find(sc => sc.scenario_name === selectedValue) 
                        // console.log(changed);
                        handleResetScenario(selectedValue)
                        setSelectedScenario(selectedId)
                        setSelectedScenarioName(selectedValue)
                        dataCtx.scenarioHandler(selectedId)
                        resetForm()
                        setScenarioSaved(true)
                        dataCtx.scenarioSavedHandler(true)
                        handleScenarioChangeAlertModalClose(selectedValue)
                        // history.push(`/calculator/${projectId}/${modelId}/${changed.id}`)
                      }

                      

                    }
                    
        
                    

                    return (
                      <Form onSubmit={handleSubmit} onReset={handleReset}>
                        <div style={{minHeight: '10px', backgroundColor: '#fff'}}></div>
                        <div style={{ maxHeight: "400px", overflow: "auto", backgroundColor: '#fff' }}  className='input-table'>
                          <div style={{position: 'absolute', top: '280px', right: '20px', display: 'flex', flexDirection: 'row'}}>
                            {initialValues && initialValues.scenario_name !== 'root' && authCtx.currentUserId === initialValues.owner_id && <ActionButton styling='light' type='button' 
                            // clicked={handleSaveModalOpen} 
                            clicked={handleSaveEdittedScenario} 
                            tooltipText='SAVE EDITTED SCENARIO' text='SAVE' icon={saveScenarioIcon} notActive={initialValues.scenario_name === 'root' || !calculated || outputOutdated || (!calculated && outputOutdated) || authCtx.currentUserId !== initialValues.owner_id} /> }
                            <ActionButton styling='light' type='button' clicked={handleSaveAsModalOpen} tooltipText='SAVE AS A NEW SCENARIO' text='SAVE AS' icon={saveScenarioIcon} notActive={isLoading || outputOutdated}/>
                            <ActionButton styling='light' type='button' clicked={handleResetModalOpen} tooltipText='RESET SCENARIO' text='RESTORE LAST SAVED' icon={restoreSavedScenarioIcon}
                              notActive={_.isEqual(initialValues, values)}
                            />
                            {initialValues && initialValues.scenario_name !== 'root' && <ActionButton styling='light' type='button' clicked={handleLoadDefaultModalOpen} tooltipText='RESET SCENARIO' text='LOAD DEFAULT' icon={loadDefaultScenarioIcon} 
                              notActive={initialValues.scenario_name === 'root'}
                            />}
                            {/* <button onClick={(e) => exportToExcelFn(e, initialValues, modelMetadata, true)}>{downloadIcon} saved version</button> */}
                            {/* <button title='DOWNLOAD TO XLSX' style={{position: 'relative', top: '18px'}} className={`${buttonClasses['custom']} ${buttonClasses['light']}`} onClick={calculated ? (e) => exportToExcelFn(e, values, modelMetadata, false) : (e) => exportToExcelFn(e, initialValues, modelMetadata, true)} disabled={outputOutdated}><span className='svg-container' style={{position: 'relative', bottom: '9px'}}>{downloadIcon}</span></button> */}
                            <ActionButton title='DOWNLOAD TO XLSX' type='button' clicked={calculated ? (e) => exportToExcelFn(e, values, modelMetadata, false) : (e) => exportToExcelFn(e, initialValues, modelMetadata, true)} notActive={outputOutdated} text='DOWNLOAD' icon={downloadScenarioIcon}/>
                            {/* {(authCtx.currentUserId === initialValues.owner_id || initialValues.scenario_id === 'root') && <button title={buttonTexts.delete[dataCtx.lang]} style={{position: 'relative', top: '7px'}} className={`${buttonClasses['custom']} ${buttonClasses['light']}`} onClick={handleDeleteModalOpen} disabled={outputOutdated}><span style={{position: 'relative', bottom: '3px'}}>{deleteIcon}</span></button>} */}
                            {(authCtx.currentUserId === initialValues.owner_id || initialValues.scenario_id === 'root') && <ActionButton tooltipText={buttonTexts.delete[dataCtx.lang]} clicked={handleDeleteModalOpen} icon={deleteScenarioIcon} text='DELETE'/>}

                          </div>
                          <div className='scenario-info' >
                            <div>Scenario name: <b>{selectedScenarioName}</b></div> 
                            <div>Scenario status: <b>{initialValues.visibility}</b></div> 
                            {authCtx.currentUserId === initialValues.owner_id && <button className='list-btn square-button accent-color-button icon-button' disabled={!dataCtx.scenarioSaved} onClick={handleEditModalOpen}>{editIconWhite}</button>}
                          </div>
                          <div style={{position: 'absolute', top: '240px'}}>
                              <h4>Choose scenario:</h4>
                              <Card>
                                <div style={{textAlign: 'left', padding: '10px', display: 'flex'}}>
                                <img style={{marginRight: '10px'}} src={chooseScenarioImg}/>
                                    
                                    <select style={{borderColor: '#fff'}} name='scenario-select' id='scenario-select' ref={scenarioSelectRef} 
                                      // onChange={(e) => handleScenarioSelection(e, initialValues)} 
                                      onChange={(e) => handleResetData(e, initialValues, null, scenarioSelectRef.current.value, scenarioSaved)}
                                    >
                                      {displayScenarioSelect}
                                    </select>
                                  </div>
                              </Card>
                          </div>
                          <ResetModal passedFn={(e) => resetFunction(initialValues, initialValues.id, initialValues.scenario_name, true)} open={resetModalOpen} handleClose={handleResetModalClose} handleAction={handleResetScenario} modalTitle={modalTexts.scenarioReset.title[dataCtx.lang]} content={modalTexts.scenarioReset.content[dataCtx.lang]} cancelButton={buttonTexts.cancel[dataCtx.lang]} confirmButton={buttonTexts.reset[dataCtx.lang]}/>
                          <ResetModal passedFn={(e) => resetFunction(initialValues, 'root', 'root', true)} open={loadDefaultModalOpen} handleClose={handleLoadDefaultModalClose} handleAction={handleResetScenario} modalTitle={modalTexts.scenarioReset.title[dataCtx.lang]} content={modalTexts.loadDefaultScenario.content[dataCtx.lang]} cancelButton={buttonTexts.cancel[dataCtx.lang]} confirmButton={buttonTexts.reset[dataCtx.lang]}/>
                          {/* <p style={{position: 'absolute', top: '15px'}}>{initialValues.id}</p> */}
                          {/* {displayMain} */}
                          <div>
                          {allScenarios && (
                            <DisplayMainComponent
                              validate={validateNumbers}
                              // validateField={validateField}
                              initialValues={initialValues}
                              // sentValues={sentValues}
                              allValuesBeforeSent={allValuesBeforeSent}
                              // formValues={formValues}
                              values={values}
                              errors={errors}
                              // mainTabsData={initialValues.input_main}
                              // headers={headers}
                              activeTab={activeTab}
                              calculated={calculated}
                              checkValuesDifference={checkValuesDifference}
                              checkIfScenarioIsSaved={checkIfScenarioIsSaved}
                              handleChange={handleChange}
                              modelMetadata={modelMetadata}
                            />
                          )}
                          {isLoadingData &&
                            <div>Fetching data...</div>
                          }
                        </div>
                        </div>
                        <div
                          className='input-table'
                          style={
                            activeTab === "Costs"
                              ? {
                                  display: "block",
                                  maxHeight: "400px",
                                  overflow: "auto",
                                  backgroundColor: '#fff'
                                }
                              : {
                                  display: "none",
                                  maxHeight: "400px",
                                  overflow: "auto",
                                  backgroundColor: '#fff'
                                }
                          }
                        >
                         { <DisplayCosts
                            // rootData={rootData}
                            initialValues={initialValues}
                            values={values}
                            errors={errors}
                            validate={validateNumbers}
                            handleChange={handleChange}
                            modelMetadata={modelMetadata}
                            calculated={calculated}
                            allValuesBeforeSent={allValuesBeforeSent}
                            checkValuesDifference={checkValuesDifference}
                            checkIfScenarioIsSaved={checkIfScenarioIsSaved}
                          />}
                        </div>
                        <div style={{marginTop: '50px'}}>
                          {/* <h2></h2> */}
                          <DisplayGoal 
                            initialValues={initialValues}
                            values={values}
                            allValuesBeforeSent={allValuesBeforeSent}
                            calculated={calculated}
                            checkValuesDifference={checkValuesDifference}
                            checkIfScenarioIsSaved={checkIfScenarioIsSaved}
                            errors={errors}
                            validate={validateNumbers}
                            handleChange={handleChange}
                            modelMetadata={modelMetadata}
                          />

                          {/* <table
                            className="first-table"
                            style={{ maxHeight: "50px", overflow: "scroll" }}
                          >
                            <tbody>
                              <tr>
                                <th>Goal</th>
                                <td style={initialValues.input_goal !== +values.input_goal ? {backgroundColor: '#27ae6066'} : {backgroundColor: '#5CA2D91A'}}>
                                  <Field
                                    required
                                    name={"input_goal"}
                                    id={"input_goal"}
                                    value={values.input_goal}
                                    onChange={handleChange}
                                    validate={validateNumbers}
                                  />
                                </td>{" "}
                              </tr>
                            </tbody>
                          </table> */}
                        </div>
                        {/* {formValues && <div>{JSON.stringify(formValues)}</div>} */}
                        {values && valuesSubmitted && (
                          <div>{JSON.stringify(values)}</div>
                        )}
                                                {Object.keys(errors).length > 0 && <p style={{color: 'red'}}>The table contains errors</p>}
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <Button type="submit" styling='primary' style={{marginTop: '30px', marginBottom: '30px'}} notActive={isLoading || !outputOutdated || Object.keys(errors).length > 0} clicked={handleFirstCalculation} text={buttonTexts.calculate[dataCtx.lang]} />
                        </div>
                        <OutputTable modelMetadata={modelMetadata} mediaHeadersArray={mediaHeadersArray} outputData={outputData} outputOutdated={outputOutdated} isLoading={isLoading} clicked={handleFirstCalculation}/>
                        <ConfirmModalTemplate
                          scenarioId={selectedScenario} 
                          scenarioName={selectedScenarioName}
                          open={scenarioChangeAlertModalOpen} 
                          handleClose={handleScenarioChangeAlertModalClose} 
                          // handleAction={handleDeleteScenario} 
                          modalTitle={modalTexts.scenarioNotSaved.title[dataCtx.lang]} 
                          content={modalTexts.scenarioNotSaved.content[dataCtx.lang]} 
                          cancelButton={buttonTexts.cancel[dataCtx.lang]} 
                          confirmButton={buttonTexts.delete[dataCtx.lang]}
                          actions={[() => resetFunction(initialValues, null, null, true), () => handleScenarioChangeAlertModalClose(initialValues.scenario_name)]}
                          initialValues={initialValues}
                          selectedScenarioName={scenarioSelectRef && scenarioSelectRef.current ? scenarioSelectRef.current.value : null}
                          passedEvent={passedEvent}
                          resetFunction={resetFunction}
                          
                        />
                      </Form>
                    );
                }

                }
                    
            </Formik>

            <SaveModal open={saveModalOpen} handleClose={handleSaveModalClose} handleSaveScenario={handleSaveEdittedScenario} modelName={modelName} scenarioName={selectedScenarioName} scenarioVisibility={initialValues.visibility}/>
            <SaveAsModal open={saveAsModalOpen} handleClose={handleSaveAsModalClose} handleSaveNewScenario={handleSaveNewScenario} modelName={modelName} />
            <ConfirmModal 
                scenarioId={selectedScenario} 
                scenarioName={selectedScenarioName}
                open={deleteScenarioModalOpen} 
                handleClose={handleDeleteScenarioModalClose} 
                handleAction={handleDeleteScenario} 
                modalTitle={modalTexts.scenarioDelete.title[dataCtx.lang]} 
                content={modalTexts.scenarioDelete.content[dataCtx.lang]} 
                cancelButton={buttonTexts.cancel[dataCtx.lang]} 
                confirmButton={buttonTexts.delete[dataCtx.lang]}
            />
            <ConfirmModalTemplate
                initialValues={initialValues}
                scenarioId={selectedScenario} 
                scenarioName={selectedScenarioName}
                open={notSavedModalOpen} 
                handleClose={handleNotSavedModalClose} 
                // handleAction={handleDeleteScenario} 
                modalTitle={modalTexts.scenarioNotSaved.title[dataCtx.lang]} 
                content={modalTexts.scenarioNotSaved.content[dataCtx.lang]} 
                cancelButton={buttonTexts.cancel[dataCtx.lang]} 
                confirmButton={buttonTexts.delete[dataCtx.lang]}
                actions={[handleDirectRedirection, handleNotSavedModalClose]}
                url={url}
                obj={obj}
            />

             <EditModal 
                scenarioId={selectedScenario} 
                scenarioName={selectedScenarioName} 
                modelId={modelId} 
                modelName={modelName} 
                scenarioVisibility={initialValues.visibility} 
                open={editModalOpen} 
                handleClose={handleEditModalClose} 
                handleEditScenario={handleEditScenario} 
                modalTitle={modalTexts.scenarioDelete.title[dataCtx.lang]} 
                content={modalTexts.scenarioDelete.content[dataCtx.lang]} 
                cancelButton={buttonTexts.cancel[dataCtx.lang]} 
                confirmButton={buttonTexts.edit[dataCtx.lang]}


            />
            <SnackBar handleClose={handleCloseSnackbarSaved} open={openSnackbarSaved} severity="success" content="Scenario successfully saved!"/>

        </div>
      
    );
};

export default FirstTableWithFormik;