import React, { useState } from 'react';

const DataContext = React.createContext({
    lang: 'en',
    langHandler: (lang) => {},
    currentProject: '',
    projectHandler: (projectId) => {},
    currentModel: '',
    modelHandler: (modelId) => {},
    currentScenario: '',
    scenarioHandler: (scenarioId) => {},
    currentScenarioName: '',
    scenarioNameHandler: (scenarioName) => {},
    scenarioSavedHandler: (bool) => {},
    scenarioSaved: Boolean
});

export const DataContextProvider = (props) => {
    const [language, setLanguage] = useState('en');
    const [currentProject, setCurrentProject] = useState('')
    const [currentModel, setCurrentModel] = useState('')
    const [currentScenario, setCurrentScenario] = useState('')
    const [currentScenarioName, setCurrentScenarioName] = useState('')

   const [scenarioSaved, setScenarioSaved] = useState(true);

    const langChangeHandler = (lang) => setLanguage(lang);
    const projectChangeHandler = (projectId) => setCurrentProject(projectId);
    const modelChangeHandler = (modelId) => setCurrentModel(modelId);
    const scenarioChangeHandler = (scenarioId) => setCurrentScenario(scenarioId);
    const scenarioNameChangeHandler = (scenarioName) => setCurrentScenarioName(scenarioName);

    const scenarioSavedHandler = (bool) => {
        setScenarioSaved(bool)
    }


    const contextValue = {
        lang: language,
        langHandler: langChangeHandler,
        currentProject: currentProject,
        projectHandler: projectChangeHandler,
        currentModel: currentModel,
        modelHandler: modelChangeHandler,
        currentScenario: currentScenario,
        scenarioHandler: scenarioChangeHandler,
        currentScenarioName: currentScenarioName,
        scenarioNameHandler: scenarioNameChangeHandler,
        scenarioSaved: scenarioSaved,
        scenarioSavedHandler: scenarioSavedHandler
    };

    return (
        <DataContext.Provider value={contextValue}>
            {props.children}
        </DataContext.Provider>
    )
};

export default DataContext;