import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import { authentication } from '../firebase/firebase';
import {onAuthStateChanged, signOut} from 'firebase/auth';


let logoutTimer;

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => {},
    logout: () => {},
    manageUserProfile: (profile) => {},
    currentUserId: null,
    currentUserName: null,
    currentUserProfile: {}
});

const calculateRamainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const ramainingDuration = adjExpirationTime - currentTime;

    return ramainingDuration;
};

const retrievedStoredToken = () => {
    

    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expirationTime');

    const remainingTime = calculateRamainingTime(storedExpirationDate);

    if (remainingTime <= 60000) {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        return null;
    }
    return {token: storedToken, duration: remainingTime};
}

export const AuthContextProvider = (props) => {
    const history = useHistory();
    const [currUser, setCurrUser] = useState()
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(authentication, (currentUser) => {
          setCurrUser(currentUser);
        });
       
        return unsubscribe
        
      }, []);

    const tokenData = retrievedStoredToken();
    // let initialToken;
    const initialToken = localStorage.getItem('token_em');

    // if (tokenData) {
    //     initialToken = tokenData.token;
    //     console.log(tokenData);
    // }
    // console.log(tokenData);

    const [token, setToken] = useState(initialToken);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [currentUserName, setCurrentUserName] = useState(null);

    const userIsLoggedIn = !!token;

    const logoutHandler = useCallback(() => {
        setToken(null);
        localStorage.removeItem('token_em');
        localStorage.removeItem('expirationTime_em');

        setCurrentUserId(null);
        setCurrentUserName(null);
        setCurrUser(null);

        signOut(authentication).then(() => {
        // Sign-out successful.
           
        }).catch((error) => {
        // An error happened.
        });
        
        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
    }, []);

    const loginHandler = useCallback((token, currentUserId, currentUserName) => {
        setToken(token);
        setCurrentUserId(currentUserId);
        setCurrentUserName(currentUserName);
        localStorage.setItem('token_em', token);
        // localStorage.setItem('expirationTime_em', expirationTime);

        // const remainingTime = calculateRamainingTime(expirationTime);

        // logoutTimer = setTimeout(logoutHandler, remainingTime);
    }, []);

    useEffect(() => {
        if (tokenData) {

            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
            // setToken(tokenData.token);
           
        }
    }, [tokenData, logoutHandler]);

    const [userProfile, setUserProfile] = useState({})
    const manageUserProfile = (profile) => {
        setUserProfile(profile)
    }

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        manageUserProfile: manageUserProfile,
        currentUserId: currentUserId,
        currentUserName: currentUserName,
        currentUserProfile: userProfile
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;