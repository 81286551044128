import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
apiKey: "AIzaSyAB9on5ydaw3fvbi2Cf7aJp8g7v6acFmzg",
authDomain: "econometric-model-app.firebaseapp.com",
projectId: "econometric-model-app",
storageBucket: "econometric-model-app.appspot.com",
messagingSenderId: "179959696267",
appId: "1:179959696267:web:0661f753e0743a79d53d8e"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const authentication = getAuth(app);
export const storage = getStorage(app);

const myfunc = onAuthStateChanged(authentication, user => {
        // Check for user status

        if (user) {
            console.log('user is logged');
        return user;
        } else {
            console.log('user is not signed in');
        }
        return user;
        });
myfunc()

// onAuthStateChanged(authentication, user => {
//         // Check for user status
//         console.log(user);
//         console.log(authentication);
//         if (user) {
//             console.log('user is logged');
//         return user;
//         } else {
//             console.log('user is not signed in');
//         }
//         return user;
//         });







