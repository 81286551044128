import React, { useContext, useRef, useState } from "react";
import { signInWithEmailAndPassword, getAuth, signInWithPopup, GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";
import { useHistory } from "react-router-dom";

import AuthContext from "../../store/auth-context";

import { authentication } from '../../firebase/firebase';

import classes from './AuthForm.module.css';
import LoginGoogle from "./LoginGoogle";
import logo from '../../assets/images/groupone_logo.svg';



// import '../Navigation/Navigation.css';

const LoginEmail = () => {

    const authCtx = useContext(AuthContext);
    const history = useHistory();

    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)


    const signInWithEmail = (e) => {
        e.preventDefault();

        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;

        const provider = new EmailAuthProvider();
        // provider.addScope('emaul')

        setIsLoading(true);
        const auth = getAuth();

        signInWithEmailAndPassword(authentication, enteredEmail, enteredPassword)
            .then((userCredential) => {
                // const cred = EmailAuthProvider.
                const user = userCredential.user;
                const currentUser = authentication.currentUser;
                // const token = .currentUser.IdTokenResult();
                // // authCtx.login(idToken, result.user['uid'], result.user['displayName']);
                const idToken = currentUser.getIdToken().then(
                    (res) => {
                        return res
                    }
                );
                setIsLoading(false)
                return idToken;
                // console.log(token);
            }).then((idToken) => {
                authCtx.login(idToken);
                history.push('/projects');
            }
            )
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message
                console.log(error.message)
                setError(errorMessage)
                setIsLoading(false)
            }).then(() => {
                
               }
            )
    }

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('email');
        signInWithPopup(authentication, provider)
            .then(result => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const idToken = credential.idToken; 
                // const accessToken = credential.accessToken;
                
                const user = result.user;
                // const userJSON = user.toJSON();
                // const expirationTime = userJSON.stsTokenManager.expirationTime;
                // const googleId = userJSON.providerData[0].uid;
                // const lastLoginAt = userJSON.lastLoginAt;

                authCtx.login(idToken, result.user['uid'], result.user['displayName']);

                // authCtx.user = user.toJSON();

                history.push('/create-model');
            })
            .catch(error => {
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // const email = error.email;
                // const credential = GoogleAuthProvider.credentialFromError(error);
            })
    }


    return (
      <div className="app-main login">
          <img src={logo} style={{width: '100px'}} className="logo" alt="logo" />
        <section className={classes.auth}>

          <h1>Login</h1>
          <form style={{marginRight: '0'}} onSubmit={signInWithEmail}>
            <div className={classes.control}>
              <label htmlFor='email'>Your Email</label>
              <input type='email' id='email' required ref={emailInputRef} />
            </div>
            <div className={classes.control}>
              <label htmlFor='password'>Your Password</label>
              <input type='password' id='password' required ref={passwordInputRef} />
            </div>
            <div className={classes.resetPassword} onClick={() => history.push('/reset-password')}>reset password</div>
            <div className={classes.actions}>
              {!isLoading && <button>LOGIN</button>}
              {isLoading && <p>Sending request...</p>}
              <div className={classes.buttonInfo}>Login only with existing account</div>
              {error && <div style={{color: 'red'}} className={classes.buttonInfo}>{error}</div>}
            </div>
          </form>
          <hr className={classes.hr}/>
          <LoginGoogle />
        </section>
        </div>

      );
    // return (
    //     <div>   
    //         <button style={{fontSize: '1.3rem', marginTop: '50px'}} onClick={signInWithGoogle}>Google Login</button>    
    //     </div>
    // );
};

export default LoginEmail;