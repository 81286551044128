import { getAuth } from 'firebase/auth';
import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

import AuthContext from '../../store/auth-context';

import classes from './AuthForm.module.css';
import LoginGoogle from './LoginGoogle';
// import logo from '../../assets/images/groupone_logo.svg'
import logo from '../../assets/images/groupone_logo.svg';


const LoginForm = () => {
  const history = useHistory();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault(); 

    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    setIsLoading(true);
    let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAB9on5ydaw3fvbi2Cf7aJp8g7v6acFmzg'

    fetch(url,
        {
          method: 'POST',
          body: JSON.stringify({
            email: enteredEmail,
            password: enteredPassword,
            returnSecureToken: true
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        setIsLoading(false);
        if (res.ok) {
          return res.json()
        } else {
          return res.json().then((data) => {
            // show an error modal
            let errorMessage = 'Authentication failed!';
            // if (data && data.error && data.error.message) {
            //   errorMessage = data.error.message;
            // } 
            
            throw new Error(errorMessage);
          });
        }
      }).then(data => {
        const expirationTime = new Date(new Date().getTime() + (+data.expiresIn * 1000));
        authCtx.login(data.idToken, expirationTime.toISOString());

        return data;
        // console.log(auth.currentUser);
        // history.replace('/home');
      }).then(() => {
        const auth = getAuth();
        return auth;
      }
        
      ).then((auth, data) => {
        onAuthStateChanged(auth, data => {
          // Check for user status

          if (data) {
              console.log('user is logged');
          return data;
          } else {
              console.log('user is not signed in');
          }
          });

      }
      )
      .catch(err => {
        alert(err.message);
      });

  };

  return (
    <>
    <div style={{position: 'absolute', top: '50px'}}>

    </div>
    <section className={classes.auth}>
      <div>LOGo</div>
      <img src={logo} style={{width: '100px'}} className="logo" alt="logo" />
      <h1>Login</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor='email'>Your Email</label>
          <input type='email' id='email' required ref={emailInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor='password'>Your Password</label>
          <input type='password' id='password' required ref={passwordInputRef} />
        </div>
        <div className={classes.actions}>
          {!isLoading && <button>Login</button>}
          {isLoading && <p>Sending request...</p>}
          <div>Login with existing account</div>
        </div>
      </form>
      <hr/>
      <LoginGoogle />
    </section>
    </>
  );
};

export default LoginForm;
