import { useEffect, useState } from 'react';
import { collection, query, onSnapshot,where } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';

export const useGetModels = (props) => {
    const {currentProject} = props;
    const [models, setModels] = useState();

    const authCtx = useContext(AuthContext)


useEffect(() => { 
    if(currentProject) {
        let myQuery;
        if (authCtx.currentUserProfile.is_admin) {
            myQuery = query(collection(db, "projects", currentProject, 'models'));
        } else {
            myQuery = query(collection(db, "projects", currentProject, 'models'), where('is_published', '==', true));

        }


        // const myQuery = query(collection(db, "projects", currentProject, 'models'));
        onSnapshot(myQuery, (querySnapshot) => {
            const allData = [];
            querySnapshot.forEach((doc) => {
                allData.push({...doc.data(), id: doc.id});
            });
            setModels(allData);
            
          }, (error) => {
            console.log('error from useGetModels: ', error)
            console.log(error);
          });

    }   
}, [authCtx, currentProject])

return models;
}; 

