import React from "react";
import { Snackbar, Alert } from '@mui/material';


const SnackBar = (props) => {


    return (
        <>
        
            <Snackbar open={props.open} autoHideDuration={5000} onClose={props.handleClose} anchorOrigin={{ vertical: 'top', horizontal:'right' }}>
            <Alert onClose={props.handleClose} severity={props.severity} sx={{ width: '100%' }}>
                {props.content}
            </Alert>
            </Snackbar>
        
        </>
    )

};


export default SnackBar;