import { appColors } from '../../utils/colors';
import Card from '../UI/Card';
import './FirstTable.css';
import './OutputTable.css';

const OutputTable = (props) => {
    const {modelMetadata, mediaHeadersArray, outputData, outputOutdated, isLoading, clicked} = props;

    let outputMediaDisplay;
    let horizontalHeadersDisplay;

    let modelCurrency;
    if (modelMetadata && modelMetadata.hasOwnProperty('model_currency')) {
        modelCurrency = modelMetadata.model_currency
    } else {
        modelCurrency = 'PLN'
    }

    if (outputData && outputData.output_media && modelMetadata && modelMetadata.columns_order_output_media) {
        // const outputDataKeys = Object.keys(outputData.output_media[0]);
        const outputDataHeaders = modelMetadata.columns_order_output_media;

        horizontalHeadersDisplay = outputDataHeaders.map((hHeader, i) => <th key={`hh-${i}`}>{hHeader}</th>)
        // console.log(outputDataKeys);
        outputMediaDisplay = outputData.output_media.map((row, rowIndex) => {
            return (
                <tr className='output-table' key={`outputMedia-row-${rowIndex}`}>
                    <th>{mediaHeadersArray[rowIndex]}</th>
                    {outputDataHeaders.map((data, dataIndex) => {
                        if (data.includes('%')) {
                            return (
                                <td key={`output-cell-${rowIndex}-${dataIndex}`}>{`${(row[data]*100).toLocaleString('fr-FR',  {maximumFractionDigits: 2})}%`}</td>
                            )
                        } else if (data === 'Effectiveness' || data === 'ROAS') {
                            return (
                                <td key={`output-cell-${rowIndex}-${dataIndex}`}>{row[data].toLocaleString('fr-Fr',  {minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                            )                       
                        } else if (data === 'Spend' || data === 'Contribution') {
                            return (
                                <td key={`output-cell-${rowIndex}-${dataIndex}`}>{row[data].toLocaleString('fr-Fr',  {minimumFractionDigits: 0, maximumFractionDigits: 0, style: 'currency', currency: modelCurrency, currencyDisplay: 'code'})}</td>
                            );
                        }
                        else {
                            return (
                                <td key={`output-cell-${rowIndex}-${dataIndex}`}>{row[data].toLocaleString('fr-Fr',  {maximumFractionDigits: 2})}</td>
                            )
                        }
                    }
                    
                    )}
                </tr>
            )
        })
    }

    let horizontalHeadersForecastDisplay;
    let outputForecastDisplay;      
    // console.log();
    // let modelCurrency;
    //     if (modelMetadata && modelMetadata.hasOwnProperty('model_currency')) {
    //         modelCurrency = modelMetadata.model_currency
    //     } else {
    //         modelCurrency = 'pln'
    //     }

    if (outputData && outputData.output_forecast && modelMetadata && modelMetadata.columns_order_output_forecast) {
        // const outputDataKeys = Object.keys(outputData.output_forecast[0]);
        const outputDataHeaders = modelMetadata.columns_order_output_forecast;

        horizontalHeadersForecastDisplay = outputDataHeaders.map((header, i) => <th key={`hHeaderForecast-${i}`}>{header}</th>)
        outputForecastDisplay = outputDataHeaders.map((r, rIndex) => {
            if (r.includes('%')) {
                return (
                    <td key={`outputForcast-cell-${rIndex}`}>{`${(outputData.output_forecast[0][r]*100).toLocaleString('fr-FR', {maximumFractionDigits: 2})}%`}</td>
                )
            } else if (r === 'Goal achivement') {
                return (
                    <td style={outputData.output_forecast[0][r] >= 1 ? {color: 'rgba(39, 174, 96, 1)', fontWeight: 'bold'} : {color: 'inherit'}}key={`outputForcast-cell-${rIndex}`}>{outputData.output_forecast[0][r].toLocaleString('pl-PL', {maximumFractionDigits: 2, style: 'percent'})}</td>

                )
            } else if (r === 'Forecast media sales' || r === 'Media spend' || r === 'Forecast total sales') {
                return (
                    <td key={`outputForcast-cell-${rIndex}`}>{outputData.output_forecast[0][r].toLocaleString('fr-FR', {maximumFractionDigits: 0, style: 'currency', currency: modelCurrency, currencyDisplay: 'code'})}</td>
                )

            } else {
                return (
                    <td key={`outputForcast-cell-${rIndex}`}>{outputData.output_forecast[0][r].toLocaleString('fr-FR', {maximumFractionDigits: 2})}</td>

                )
            }
        })
    }

    return (
        <div style={{margin: '40px 20px', position: 'relative', paddingTop: '20px', backgroundColor: 'white'}}>
            {outputOutdated && <div style={{position: 'absolute', zIndex: '50', backgroundColor: 'rgba(256,256,256,0.8)', height: '100%', width: '100%'}}></div>}
            {isLoading && <div style={{position: 'absolute', zIndex: '50', backgroundColor: 'rgba(256,256,256,0.8)', height: '100%', width: '100%', paddingTop: '150px', fontSize: '30px', fontWeight: 'bold'}}>Calculating...</div>}
            {outputOutdated && !isLoading && 
                <div style={{position: 'absolute', zIndex: '50', width: '100%', paddingTop: '150px', fontSize: '30px', fontWeight: 'bold'}}>
                    <div style={{backgroundColor: 'rgba(256,256,256,1)', width: '500px', margin: '0 auto', padding: '20px', boxShadow: '2px 0px 5px 0px rgb(0 0 0 / 20%)'}}>
                        {/* Wprowadziłeś nowe dane. <button onClick={clicked} style={{color: appColors.mainAccentColor, cursor: 'pointer', border: 'none', backgroundColor: 'transparent', fontSize: '30px', fontWeight: 'bold', padding: '0'}}>Przelicz</button>, aby wygenerować nowy wynik scenariusza. */}
                        You have entered new data. Press <button onClick={clicked} style={{color: appColors.mainAccentColor, cursor: 'pointer', border: 'none', backgroundColor: 'transparent', fontSize: '30px', fontWeight: 'bold', padding: '0'}}>caluculate</button> to generate new output result.
                    </div>
                </div>
            }
            <h1>OUTPUT TABLES</h1>
            <h2>Media Output</h2>
            
            <table style={{width: '100%'}} className='first-table'>
                <thead style={{top: '72px'}}>
                    <tr>
                        <th style={{backgroundColor: '#fff', borderBottomColor: '#fff'}}></th>
                        {horizontalHeadersDisplay}
                    </tr>
                </thead>
                <tbody>
                    {outputMediaDisplay}
                </tbody>
            </table>
            
            <h2>Forecast output</h2>
            
            <table style={{width: '100%'}} className='first-table forecast-table'>
                <thead>
                    <tr>
                        {horizontalHeadersForecastDisplay}
                    </tr>
                </thead>
                <tbody>
                    <tr className='output-table'>
                        {outputForecastDisplay}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default OutputTable;