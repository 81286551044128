import { useContext, useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, collectionGroup } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';
import AuthContext from '../../store/auth-context';



export const useHeaders = (props) => {
    const {projectId} = props;
    const [headers, setHeaders] = useState();

    const user = authentication.currentUser;
    let user_id;
    if (user !== null) {
        user_id = user.uid;

        
    }

    const authCtx = useContext(AuthContext);
    // console.log(authCtx);

useEffect(() => { 
    if(authCtx.currentUserId) {
        const myQuery = query(collection(db, "projects", projectId, 'models'));
        onSnapshot(myQuery, (querySnapshot) => {
            const allHeaders = [];
            querySnapshot.forEach((doc) => {
                allHeaders.push({...doc.data(), id: doc.id});
            });
            setHeaders(allHeaders);

          }, (error) => {
            console.log('error from useHeaders: ', error)
          });

    }
     
}, [authCtx.currentUserId])

return headers;
}; 

