import { useContext, useEffect, useState } from 'react';
import { collection, query, onSnapshot, where } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';
import AuthContext from '../../store/auth-context';



export const useGetPublicScenarios = (props) => {

    const {currentProject, selectedModel} = props;
    const [scenarios, setScenarios] = useState([]);

    const user = authentication.currentUser;
    let user_id;
    if (user !== null) {
        user_id = user.uid;
    }

    const authCtx = useContext(AuthContext);
    
useEffect(() => { 
    if(user_id && currentProject && selectedModel) {
        let myQuery;
        if (authCtx.currentUserProfile.is_admin) {
            myQuery = query(collection(db, "projects", currentProject, 'models', selectedModel, 'scenarios'));
        } else {
            myQuery = query(collection(db, "projects", currentProject, 'models', selectedModel, 'scenarios'), where('visibility', '==', 'public'));

        }
        onSnapshot(myQuery, (querySnapshot) => {
            const allData = [];
            querySnapshot.forEach((doc) => {
                allData.push({...doc.data(), id: doc.id});
            });

            setScenarios(allData);
          }, (error) => {
            console.log('error from useGetPublicScenarios: ', error)
            console.log(error);
          });
    }   
}, [user_id, currentProject, selectedModel])

return scenarios;
}; 

