import React, {useContext, useEffect, useRef, useState} from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import classes from './AuthForm.module.css';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

const ResetPassword = () => {
    const auth = getAuth();
    const history = useHistory();
    const emailInputRef = useRef();
 console.log(history);
    
    const authCtx = useContext(AuthContext);
    
    const [usersEmail, setUsersEmail] = useState('');

    useEffect(() => {
          if (history && history.location && history.location.state && history.location.state.email) {
      setUsersEmail(history.location.state.email);
    }
    }, [history.location])


    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(null);




    const sendResetPasswordEmail = (e) => {
        e.preventDefault()
        setError(null)
        setIsLoading(true)
        const userEmail = emailInputRef.current.value;
        setEmailSent(true)
        return sendPasswordResetEmail(auth, userEmail, {
            url: `https://one-budget-allocator.groupone.pl/login`,
          }).then(
            setIsLoading(false)
          ).catch(error => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorMessage)
          })
          
    }

    const onLinkClicked = (e, url) => {
        setEmailSent(false)
        history.push(url)
    }
    return (
      <div className='app-main'>
        <section className={classes.auth}>
        <h1>Password reset</h1>
        <form onSubmit={sendResetPasswordEmail}>

          <div className={classes.control}>
            <label htmlFor='email'>Your Email</label>
            <input type='email' id='email' required ref={emailInputRef} defaultValue={usersEmail}/>
          </div>

          <div className={classes.actions}>
            {!isLoading && <button>SEND RESET EMAIL</button>}
            {isLoading && <p>Sending email...</p>}
            {/* {<p className={classes.link} onClick={onLinkClicked}>go back to login page</p>}             */}
            {authCtx.isLoggedIn && <p className={classes.link} onClick={(e) => onLinkClicked(e, '/projects')}>go to home page</p>}
            {!authCtx.isLoggedIn && !emailSent &&  <p className={classes.link} onClick={(e) => onLinkClicked(e, '/login')}>go back to login page</p>}
          </div>
        </form>
        {emailSent && 
        <div style={{textAlign: 'center'}}>
            {emailSent && !error &&  <p>email was sent, check you inbox</p>}
            {emailSent && error &&  <p style={{color: 'red'}}>{error}</p>}
            <p className={classes.link} onClick={sendPasswordResetEmail}>resend email</p>

        </div>}

      </section>
      </div>
    );
};

export default ResetPassword;