import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import DataContext from '../../store/data-context';
import { appTexts } from '../../utils/translate';
import { useGetAllProjects } from '../data/useGetAllProjects';

import '../userView/UsersProjects.css'
import AllProjectsList from './AllProjectsList';


const AllProjects = () => {    
    const history = useHistory();

    const authCtx = useContext(AuthContext);
    const dataCtx = useContext(DataContext);
    const usersProfile = authCtx.currentUserProfile;

   const allProjects = useGetAllProjects();
    if (allProjects) {
        // console.log(allProjects);
    }


return (
    <div className='app-main'>
        <h2>All Projects</h2>
        <h3 style={{textAlign: 'left', fontWeight: 'bold', padding: '20px 40px'}}>{appTexts.choose[dataCtx.lang]} {appTexts.project.sing[dataCtx.lang]}:</h3>
        <ul style={{listStyle: 'none'}}>
           {allProjects && <AllProjectsList allProjects={allProjects} />}
        </ul>

    </div>
)

}

export default AllProjects;