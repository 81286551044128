import { useEffect, useState } from 'react';
import { collection, doc, query, where, onSnapshot, getDoc, collectionGroup } from "firebase/firestore";

import { db , authentication } from '../../firebase/firebase';



export const useGetProjectSettings = (props) => {
    const [data, setData] = useState();

    const {currentProject} = props;

    const user = authentication.currentUser;
    let user_id;
    if (user !== null) {
        user_id = user.uid;
    }


    useEffect (() => { 
        const fetchData = async () => {
            const docRef = doc(db, "projects", currentProject);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setData({...docSnap.data(), id: docSnap.id })
                } else {
                    console.log('no such doc');
                }
        }
        
          
        fetchData()

         
    }, [currentProject])
return data;
}; 

