import React, { useContext, useEffect, useState } from 'react';
import { collection, query, onSnapshot, doc, getDoc, where } from "firebase/firestore";
import { db } from '../../firebase/firebase';


import { useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import DataContext from '../../store/data-context';
import { appTexts } from '../../utils/translate';
import { useGetUsersProjects } from '../data/useGetUsersProjects';

import './UsersProjects.css'
import {orderBy} from 'lodash';
import { appColors } from '../../utils/colors';

const UsersProjects = (props) => {
    const authCtx = useContext(AuthContext);
    const dataCtx = useContext(DataContext);
    const usersProfile = authCtx.currentUserProfile;

    const [uP, setUP] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [usersProjects, setUsersProjects] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);



    const handleLoadingStatus = (bool) => {
       setIsLoading(bool)
    }

    const history = useHistory();
    // let usPr = []
    if(authCtx) {

        // usPr = Object.keys(authCtx.currentUserProfile.roles);
    }
//    const usersProjects = useGetUsersProjects(handleLoadingStatus)

    // console.log(usersProjects);
// useEffect(() => {
//     if (usersProjects) {
//         setUP(usersProjects)
//         console.log(usersProjects);
//     } 
// }, [usersProjects])

useEffect(() => {
    if (usersProjects) {
        setUP(usersProjects)
        // console.log(usersProjects);
    } 
}, [authCtx])

// console.log(isLoading);

useEffect(() => { 
    
    setIsLoading(true)

    let userProjects = [];
    if (authCtx && authCtx.currentUserProfile && authCtx.currentUserProfile.roles) {
        userProjects = Object.keys(authCtx.currentUserProfile.roles);
    }
    if (userProjects.length > 0) {
        // const userProjects = Object.keys(authCtx.currentUserProfile.roles);

        const myQuery = query(collection(db, 'projects'), where('project_id', 'in', userProjects));
        onSnapshot(myQuery, (querySnapshot) => {
            setIsLoading(true);
            const allData = [];
            querySnapshot.forEach((doc) => {
                allData.push({...doc.data(), id: doc.id}); 
                
            });
            const orderedData = orderBy(allData, 'name');
            setUsersProjects(orderedData);
            // console.log(allData);        
            setIsLoading(false);
            setDataFetched(true);
            

        }, (error) => {
            console.log('error from UsersProjects: ', error)
            console.log(error);
            setIsLoading(false)
        }

        );

    } else {
        setDataFetched(true);
        setIsLoading(false)

    }


     
},[authCtx, authCtx.currentUserId, authCtx.currentUserProfile.roles]);



    // let usersProjects;

    let projectsDisplay;
    if (usersProfile && usersProjects) {
        // usersProjects = Object.keys(usersProfile.roles)
        if (usersProjects.length === 1) {

            history.push(`/project/${usersProjects[0].project_id}`, {projectId: usersProjects[0].project_id, projectName: usersProjects[0].name})
            // projectsDisplay = usersProjects.map(project => <li key={project.project_id}><div onClick={() => history.push(`/project/${project.project_id}`, {projectId: project.project_id})} className='project-card'>{project.name}</div></li>)

        } else if (dataFetched && usersProjects.length === 0 ) {
            projectsDisplay = <div>No projects available.</div>
        } else if (dataFetched && usersProjects.length > 1) {
            projectsDisplay = usersProjects.map(project => <li key={project.project_id}>
                <div onClick={() => history.push(`/project/${project.project_id}`, {projectId: project.project_id, projectName: project.name})} className='project-card' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='project-card-part project-name'>{project.name}</div>
                    <div className='project-card-part project-arrow'>&gt;</div>
                </div>
                </li>)
        } 
    }
    return (
        <div className='app-main'>
            <h2>User's Projects</h2>
            {usersProfile && usersProjects && usersProjects.length > 0 && <h3 style={{textAlign: 'left', fontWeight: 'bold', padding: '20px 40px'}}>{appTexts.choose[dataCtx.lang]} {appTexts.project.sing[dataCtx.lang]}:</h3>}
            {usersProjects && <ul style={{listStyle: 'none'}}>
                {projectsDisplay}
            </ul>}
            {isLoading && <div>Loading...</div>}

        </div>
    )
}

export default UsersProjects;