import React from 'react';
import { Box, Modal  } from '@mui/material';
import { Button } from '../UI/Button';

import classes from './Modals.module.css';
import CloseModal from './CloseModal';

const modalWidth = 600;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiInputBase-input': {
        padding: '6px 0 6px 14px'
    }
  };


const ConfirmModalTemplate = (props) => {
    const {modalTitle, content, cancelButton, confirmButton, handleClose, open, handleAction, scenarioName, scenarioId, actions, url, obj, e, resetFunction} = props;

    const handleSave = (e) => {
        e.preventDefault();
        handleAction(e, scenarioId)  
    }

    const texts = ['Discard changes', 'Cancel'];
    const types = ['button', 'button'];
    const styles = ['primary', 'light'];
    // const actions = [() => console.log('saving'), () => console.log('not saving'), () => console.log('canceling'), ]
    const buttonsNumber = texts.length;
    
    let displayButtons = [];
    for (let i = 0; i < buttonsNumber; i++) {
      const btn = <Button key={`option-button-${i}`} type={types[i]} clicked={actions[i]} text={texts[i]} styling={styles[i]} />
      displayButtons.push(btn)
      
    }

    // console.log(url, obj);

    return (
 <>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <CloseModal clicked={handleClose}/>
    <h2 className={classes.header}>{modalTitle}</h2>
    <p style={{textAlign: 'center'}}>{content}</p>   
    <p style={{textAlign: 'center', fontWeight: 'bold'}}>{scenarioName}</p>   
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>       
      {displayButtons.length > 0 && displayButtons.map(btn => btn)}
    </div>                
  </Box>
</Modal>
</>
    )
}

export default ConfirmModalTemplate;