import { Typography, Box, Modal, TextField, MenuItem, Switch } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '../UI/Button';

import { closeIcon, modelIcon, scenarioIcon, visibilityIcon } from '../../assets/icons/Icons';

import { modalTexts, buttonTexts } from '../../utils/translate';
import classes from './Modals.module.css';
import CloseModal from './CloseModal';
import DataContext from '../../store/data-context';
import { appColors } from '../../utils/colors';

const modalWidth = 450;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiInputBase-input': {
        padding: '6px 0 6px 14px'
    }
  };


const EditModal = (props) => {

    const {handleClose, open, scenarioId, scenarioName, modelName, scenarioVisibility, scenarioOwnerId} = props;

    const dataCtx = useContext(DataContext);
    
    const styles = {
        '& .MuiSwitch-switchBase.Mui-checked': {color: appColors.mainAccentColor},  
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {backgroundColor: appColors.mainAccentColor}
      } 

    const scenarioNameInputRef = useRef();
    // const visibilitySelectRef = useRef();

        const [status, setStatus] = useState(scenarioVisibility);
   
    const handleStatusChange = () => {
        if (scenarioVisibility === 'private') {
            setStatus('public')
        } else if (scenarioVisibility === 'public') {
            setStatus('private');
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        const scenarioEdittedName = scenarioNameInputRef.current.value;

        props.handleEditScenario(null, scenarioId, scenarioEdittedName, status, scenarioOwnerId)

    }



    return (
 <>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
        <CloseModal clicked={handleClose} />
          <h2 className={classes.header}>{modalTexts.editScenario.title[dataCtx.lang]}:</h2>
          <p>{modalTexts.editScenario.content[dataCtx.lang]}</p>
          <div >
                <div style={{ width: '45%', display: 'inline-block'}}><span className={classes.modalIcon}>{modelIcon}</span>{modalTexts.modelName[dataCtx.lang]}</div>
                <div style={{ width: '45%', display: 'inline-block'}}>{modelName}</div>
            </div>
            <div className={classes.edittableContainer}>
                {/* <div style={{ width: '40%', display: 'inline-block'}}>Nazwa scenariusza</div> */}
                <label style={{ width: '45%', display: 'inline-block'}} htmlFor='scenario-name'><span className={classes.modalIcon}>{scenarioIcon}</span >{modalTexts.scenarioName[dataCtx.lang]}</label>
                <input type='text' id='scenario-name' required defaultValue={scenarioName} ref={scenarioNameInputRef} />
            </div>
            <div>
            <div style={{ width: '45%'}}>
                <label htmlFor='scenario-visibility'><span className={classes.modalIcon}>{visibilityIcon}</span>Public</label>
                {/* <select name="scenario-visibility" id="scenario-visibility" ref={visibilitySelectRef}>
                    <option value="private" selected={scenarioVisibility === 'private'}>Private</option>
                    <option value="public" selected={scenarioVisibility === 'public'}>Public</option>
                </select> */}
                <Switch sx={styles} defaultChecked={scenarioVisibility === 'public'} onClick={handleStatusChange} />
            </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>       
                <Button type='button' clicked={handleClose} text={buttonTexts.cancel[dataCtx.lang]} styling='light' />
                <Button type='button' clicked={(e) => handleSave(e)} text={buttonTexts.save[dataCtx.lang]} styling='primary' />
            </div>
            
  </Box>
</Modal>
</>
    )
}

export default EditModal;