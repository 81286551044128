import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const AdminPage = (props) => {
    const history = useHistory()


    return (
        <div className='app-main'>
            <nav style={{paddingTop: '50px', width: '70%', margin: '0 auto'}}>
                <h3>Admin's links: </h3>
                <div onClick={() => history.push(`/admin/create-user`)} style={{textDecoration: 'underline', cursor: 'pointer'}}>Create new user</div>
                <div onClick={() => history.push(`/admin/all-users`)} style={{textDecoration: 'underline', cursor: 'pointer'}}>All users</div>
             </nav>
        </div>
    )
}

export default AdminPage;