import { useHistory } from 'react-router-dom';
import '../userView/UsersProjects.css'


const AllProjectsList = (props) => {
    const {allProjects} = props;

    const history = useHistory();

    let listDisplay;
    if (allProjects) {

        listDisplay = allProjects.map((project, projectIndex) => {
                return (
                    <li key={`${project.id}-${projectIndex}`}>
                        <div onClick={() => history.push(`/manage-project/${project.id}`, {projectId: project.project_id, projectName: project.name})} className='project-card'>
                            {/* <h4>{project.name}</h4> */}
                            {/* <h4>SUPER USERS:</h4> */}
                            {/* {project.super_users.map((user, userIndex) => <div key={`${project.id}-superuser-${userIndex}`}>{user}</div>)} */}
                            <div className='project-card-part project-name'><b>{project.name}</b></div>
                            <div className='project-card-part project-arrow'>&gt;</div>
                        </div>
                    </li>
                )
            });
        
        

    }

    return (
        <>
            {listDisplay}
        </>
    )
}

export default AllProjectsList;