import * as XLSX from 'xlsx';


export const exportToExcelFn = (event, data, modelMetadata, saved) => {
    event.stopPropagation();
    event.preventDefault();

    const fileNameSaved = saved ? data.scenario_name : ' !!!not saved!!! ';
    

// Data for workbook:
    const modelName = modelMetadata.model_name;
    const scenarioName = data.scenario_name;
    const scenarioMainData = data.input_main;
    const costsData = data.input_costs;
    const goalData = [{'Goal': data.input_goal}];
    const outputMediaData = data.output_media;
    const outputForecastData = data.output_forecast;

// Headers for workbook: 
    const costsHeadersData = modelMetadata.columns_order_costs;
    const outputMediaHeaders = modelMetadata.columns_order_output_media;
    const outputForecastHeaders = modelMetadata.columns_order_output_forecast;


// HEADERs OF INPUT_MAIN: array of 'display' headers:

    const allMainHeadersData = [];
    modelMetadata.columns_metadata.map(el => allMainHeadersData.push(el['nazwa wyświetlana']))
    allMainHeadersData.shift()

// HEADERs OF INPUT_MAIN: Object of ALL 'display' headers:
    const dHeadersObj = {};

    scenarioMainData.map((inputMainTab) => {
       modelMetadata.columns_metadata.map(metaColumn => {
            if (metaColumn['nazwa zmiennej w tabeli źródłowej'] !== '-') {
                 dHeadersObj[metaColumn['nazwa zmiennej w tabeli źródłowej']] = metaColumn['nazwa wyświetlana'];
            }
        })
    })

    
// Output media headers transformation:

    // adding media header to each row
    const transformedOutputMediaData = [...outputMediaData];

    transformedOutputMediaData.map((row, rowIndex) => {
        row[' '] = allMainHeadersData[rowIndex+1];
    })

    // 'adding A:A column'

    const transformedOutputMediaHeaders = [...outputMediaHeaders];
    transformedOutputMediaHeaders.unshift(' ');


    // EXCEL CREATION:

    const wb = XLSX.utils.book_new()

    scenarioMainData.map((tab, tabIndex) => {
        const headers = [...tab.header]
        const headersDisPerTab = {};
        headers.map(h => {
            return headersDisPerTab[h] = dHeadersObj[h]
        })
        const changedData = [...tab.data]
        if (changedData[0].date !== 'Date') {
            changedData.unshift(headersDisPerTab)
        }

        const data = XLSX.utils.json_to_sheet(changedData, {header: [...headers], skipHeader: true, origin: 0});
         
        XLSX.utils.book_append_sheet (wb, data, `Main-${scenarioMainData[tabIndex].name}`)
        
    })

    const wsCosts = XLSX.utils.json_to_sheet(costsData, {header: [...costsHeadersData]});
    const wsGoal = XLSX.utils.json_to_sheet(goalData);
    const wsOutputMedia = XLSX.utils.json_to_sheet(transformedOutputMediaData, {header: [...transformedOutputMediaHeaders]});
    const wsOutputForecast = XLSX.utils.json_to_sheet(outputForecastData, {header: [...outputForecastHeaders]});


    XLSX.utils.book_append_sheet (wb, wsCosts, 'Costs')
    XLSX.utils.book_append_sheet (wb, wsGoal, 'Goal')
    XLSX.utils.book_append_sheet (wb, wsOutputMedia, 'Output-Media')
    XLSX.utils.book_append_sheet (wb, wsOutputForecast, 'Output-Forecast')

    const t = new Date();
    const dateString = `${t.getFullYear()}-${t.getMonth() < 9 ? '0'+ (t.getMonth()+1) : (t.getMonth()+1)}-${t.getDate()}--${t.getHours() < 10 ? '0'+t.getHours() : t.getHours()}-${t.getMinutes() < 10 ? '0'+t.getMinutes() : t.getMinutes()}-${t.getSeconds() < 10 ? '0'+t.getSeconds() : t.getSeconds()}`;
   
    XLSX.writeFile(wb, modelName + '_' + fileNameSaved + ' ('+dateString+').xlsx', {bookType: 'xlsx'});
}