export const appColors = {
    mainAccentColor:'#13A3DC', //'#5CA2D9'
    // mainAccentColor:'orange', //'#5CA2D9'
    changedCellColor: '#B6D7A8', // #27AE6066
    errorCell: '#E06666', // '#ff00004d
    mainTypoColor: '#152831',
    bgGray: '#F4F4F4',
}

// ???:

// #5CA2D91A - STARY NIEBIESKI
// '#27AE6066' - STARY zmieniona komórka
// 