import React from "react";
import { useHistory } from "react-router-dom";
import { appColors } from "../utils/colors";

const HomePage = () => {
    const history = useHistory();
    return (
        <div className='app-main' style={{textAlign: 'center', marginTop: '80px', paddingLeft: '100px'}}>
            <h2 style={{marginLeft: '0'}}>One Budget Allocator</h2>
            <div>
                {/* <p style={{margin: '0 60px', lineHeight: '1.5rem' }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sollicitudin pulvinar nisl eu posuere. Quisque ut scelerisque nisi. Etiam feugiat nisl ligula, eu consectetur augue.s
                </p> */}
                <p>.....Click <span style={{color: appColors.mainAccentColor, textDecoration: 'underline', cursor: 'pointer'}} onClick={() => history.push('/projects')}>projects</span> link to begin.....</p>
            </div>
        </div>
    );
}

export default HomePage;