import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../UI/Button';

const NotAuthorizedPage = () => {
    const history = useHistory();
    return (
        <div className='app-main'>
            <div>You are not authorized to see the required resource.</div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button type="submit" styling='primary' clicked={() => history.push('/projects')} text='Choose project' />
            </div>
        </div>
    )
}

export default NotAuthorizedPage;